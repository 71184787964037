import { _Sidebar as Sidebar } from './_SubjectDetail/_Sidebar';
import { Subject } from '../../../features/user/models/User';

interface Props {
  subject?: Subject;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  onComplete: (subject: Subject) => void;
}

const SubjectDetailSidebar = ({
  subject,
  sidebarOpen,
  setSidebarOpen,
  onComplete,
}: Props) => {
  const _onComplete = (_subject: Subject) => {
    setSidebarOpen(false);
    onComplete(_subject);
  };

  return (
    <Sidebar
      subject={subject}
      _onComplete={_onComplete}
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
    />
  );
};

export { SubjectDetailSidebar };
