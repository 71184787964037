import { HoverCard } from '@mantine/core';
import { PlusIcon } from '@heroicons/react/24/outline';
import { NavigateFunction } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { Button } from '../../../ui/Button';

interface Props {
  navigate: NavigateFunction;
}

const QuickActionIcon = ({ navigate }: Props) => (
  <HoverCard
    shadow="md"
    position="right-start"
    openDelay={100}
    transitionProps={{ transition: 'scale-x', duration: 400 }}
  >
    <HoverCard.Target>
      <div className="group flex w-full cursor-pointer flex-row items-center justify-center gap-smd rounded-sm bg-primary-500 p-smd text-sm text-white hover:bg-primary-600">
        <PlusIcon
          height={22}
          width={22}
          className="text-white transition duration-500 ease-in-out group-hover:rotate-[180deg]"
        />
      </div>
    </HoverCard.Target>
    <HoverCard.Dropdown p={0}>
      <div className="absolute -left-1 top-3 h-3 w-3 rotate-[45deg] transform bg-white border-b border-l border-[#E8E8E8]" />
      <Button
        title="New order draft"
        theme="secondary"
        radius="sm"
        className="w-full text-center font-semibold hover:bg-light-gray-50"
        onClick={() => {
          navigate(ROUTES.PROCESS_NEW_ORDER_DRAFTS);
        }}
      />
      <Button
        title="Process order drafts"
        theme="secondary"
        radius="sm"
        className="w-full text-center font-semibold hover:bg-light-gray-50"
        onClick={() => {
          navigate(ROUTES.PROCESS_ORDER_DRAFTS);
        }}
      />
    </HoverCard.Dropdown>
  </HoverCard>
);

export default QuickActionIcon;
