import { Organization } from 'models/Business';
import { Subject } from 'features/user/models/User';
import { Message, MessageContextUtils } from 'features/message/models/Message';
import { RecordingMessage } from 'components/chat/Chat/ChatMessage/RecordingMessage';
import { useEffect, useRef, useState } from 'react';

import { useDocsUtils } from './hooks/useDocsUtils';
import Header from './Header';
import ImageWithAnnotations from './ImageWithAnnotations';
import FocusModeTab from './FocusModeTab';
import { isImageTabSelected, isTextAndRecordingTabSelected } from './utils/tab';
import FocusModeText from './FocusModeText';

interface Props {
  customer?: Organization;
  subject: Subject;
  message: Message;

  onManageSubjectButtonClick?: () => void;
}

const FocusMode = ({
  customer,
  subject,
  message,

  onManageSubjectButtonClick,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState<'text-and-recording' | string>('text-and-recording');
  const [hasImageTabBeenClicked, setHasImageTabBeenClicked] = useState(false);

  const firstRenderRef = useRef(true);

  const {
    docUrl,
    docs,
    docImages,
    imageLength,
    handleImageTabClick: handleImageTabClick_,
    selectedDocImgIndex,
    showImage,
    showRecording,
    handleAttachmentClick: handleAttachmentClick_,
  } = useDocsUtils({ message });

  const handleImageTabClick = (index: number) => {
    setSelectedTab(`image-${index}`);
    handleImageTabClick_(index);
    setHasImageTabBeenClicked(true);
  };

  const handleAttachmentClick = (index: number) => {
    handleAttachmentClick_(index);
    const imgIndex = MessageContextUtils.docAndPageIndexToImgIndex(docs, index, 0);
    setSelectedTab(`image-${imgIndex}`);
  };

  useEffect(() => {
    if (typeof selectedDocImgIndex === 'number' && !firstRenderRef.current) {
      setSelectedTab(`image-${selectedDocImgIndex}`);
      setHasImageTabBeenClicked(true);
    }
    firstRenderRef.current = false;
  }, [selectedDocImgIndex]);

  return (
    <div className="px-6 pt-6 pb-2 space-y-2 4xl:space-y-4 bg-white flex flex-col flex-1 overflow-y-auto relative">
      <Header customer={customer} subject={subject} onManageSubjectButtonClick={onManageSubjectButtonClick} />

      {
        showImage && (
          <FocusModeTab
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            hasImageTabBeenClicked={hasImageTabBeenClicked}
            handleImageTabClick={handleImageTabClick}
            imageLength={imageLength}
          />
        )
      }

      {
        (!showImage || (showImage && isTextAndRecordingTabSelected(selectedTab))) && (
          <FocusModeText
            message={message}
            docs={docs}
            imageLength={imageLength}
            handleAttachmentClick={handleAttachmentClick}
          />
        )
      }

      {showRecording && (
        <div className="pt-3">
          {message && <RecordingMessage message={message} />}
        </div>
      )}

      {
        showImage && isImageTabSelected(selectedTab) && (
          <ImageWithAnnotations
            message={message}
            imgUrl={docImages?.[selectedDocImgIndex]?.url}
            imgName={docImages?.[selectedDocImgIndex]?.name}
            docUrl={docUrl}
          />
        )
      }
    </div>
  );
};

export default FocusMode;
