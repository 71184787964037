import { useState, useCallback } from 'react';
import { httpGetV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';
import { BusinessSales } from 'models/Analytics';

const useFetchBusinessSales = () => {
  const [businessSales, setBusinessSales] = useState<BusinessSales[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadBusinessSales = useCallback(
    (
      period: 'daily' | 'weekly' | 'monthly',
      giveErrorFeedback: boolean = true,
    ): Promise<BusinessSales[]> => {
      if (!period) return Promise.reject(new Error('Period is required'));
      setIsLoading(true);

      return httpGetV1('/businesses/me/analytics/sales', {
        params: {
          period,
        },
        classType: BusinessSales,
      })
        .then((response) => {
          setBusinessSales(response.data);
          return response.data;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('An error has occured while fetching business sales')(
              error,
            );
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    businessSales,
    loadBusinessSales,
    isLoading,
  };
};

export { useFetchBusinessSales };
