import { uniqBy } from 'lodash';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { Button } from 'components/ui/Button';
import { Page } from 'components/layout/Page/Page';
import { ScrollAreaWrapper } from 'components/wrapper/ScrollAreaWrapper';
import { InviteTeammeberSidebarWrapper } from 'components/wrapper/InviteTeammeberSidebarWrapper';
import { TeamMemberIcon } from 'features/user/components/team-member';
import { User, UserRole } from 'features/user/models/User';
import { genericErrorFeedback } from 'utils/errors';
import { httpGetV1 } from 'utils/xhr';
import { globalAlertData } from 'state/globalAlertData';
import { Badge } from '@mantine/core';
import { isUserActivated } from '../utils/user';

interface Props {
  navigateToAddTeamMember: () => void;
  navigateToTeamMember: (userId: string) => void;
}

const TeamMemberListPage = ({
  navigateToAddTeamMember,
  navigateToTeamMember,
}: Props) => {
  const [users, setUsers] = useState<User[]>([]);
  console.log(users, navigateToAddTeamMember);
  const [isLoading, setIsLoading] = useState(false);
  const usersPaginationCursor = useRef();

  const inviteTeammeberSidebarWrapperRef = useRef(null);

  const loadUsers = useCallback(() => {
    setIsLoading(true);
    httpGetV1('/businesses/me/users', {
      params: { cursor: usersPaginationCursor.current },
    })
      .then((response) => {
        const entries: User[] = (response.data.result || []).filter(
          (u: User) => !u.roles.includes(UserRole.AiAssistant),
        );
        setUsers((us) => uniqBy([...us, ...entries], 'id'));
        usersPaginationCursor.current = response.data.cursor;
      })
      .catch(genericErrorFeedback('Error loading users'))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onAddMemberButtonPress = useCallback(() => {
    inviteTeammeberSidebarWrapperRef.current?.openSidebar();
  }, []);

  const onTeamMemberPreviewClick = useCallback(
    (id: string) => {
      navigateToTeamMember(id);
    },
    [navigateToTeamMember],
  );

  const onInvitationComplete = useCallback(() => {
    globalAlertData.create('User invitation sent successfully', '', '#00c853');
  }, []);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <Page isLoading={isLoading} contentWithPadding>
      <InviteTeammeberSidebarWrapper
        ref={inviteTeammeberSidebarWrapperRef}
        onComplete={onInvitationComplete}
      >
        <div className="flex flex-1 flex-col px-lg py-lg">
          <div className="flex flex-1 flex-col space-y-4">
            <div className="flex justify-between">
              <div className="flex flex-col justify-center gap-2">
                <h1 className="text-xl font-semibold leading-none text-gray-900">
                  Team members
                </h1>
                <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
                  Central Hub for personal customization
                </div>
              </div>
              <Button title="Add member" onClick={onAddMemberButtonPress} />
            </div>
            <ScrollAreaWrapper className="flex-1">
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:gap-7.5 xl:grid-cols-5 2xl:grid-cols-7">
                {users.map((user) => (
                  <div
                    className="flex flex-col items-center rounded-md border border-gray-200 bg-white p-5 shadow-[0px_3px_4px_0px_rgba(0,0,0,0.03)] lg:py-10 hover:scale-[1.02] hover:shadow-[0px_7px_8px_0px_rgba(0,0,0,0.05)]"
                    key={`member-${user?.id}`}
                    onClick={() => onTeamMemberPreviewClick(user?.id)}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    <div className="mb-3.5 relative overflow-visible w-full flex justify-center">
                      <TeamMemberIcon user={user} />
                      {!isUserActivated(user) && (
                        <Badge
                          variant="dot"
                          color="orange"
                          className="absolute -top-3 left-1/2 -translate-x-1/2 whitespace-nowrap z-10 min-w-max"
                        >
                          Not activated
                        </Badge>
                      )}
                    </div>
                    <div className="mb-2 flex items-center justify-center gap-1.5">
                      <div className="hover:text-primary-active text-base font-semibold leading-5 text-gray-900">
                        {user?.firstName && user?.lastName
                          ? `${user.firstName} ${user.lastName}`
                          : user?.email}
                      </div>
                    </div>
                    {isUserActivated(user) && (
                      <div className="hover:text-primary-active text-sm font-medium text-gray-600">
                        {user?.username || user?.email || user?.phone}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </ScrollAreaWrapper>
          </div>
        </div>
      </InviteTeammeberSidebarWrapper>
    </Page>
  );
};

export default TeamMemberListPage;
