import {
  useState, useCallback, useRef, useEffect,
} from 'react';
import { useDebouncedState } from '@mantine/hooks';

import { httpGetV1 } from 'utils/xhr';
import { Organization } from '../../models/Business';

type LoadOrganizationsProps = {
  excludeUnconfirmed?: boolean;
  reset?: boolean;
};

interface FetchOrderDraftsProps {
  preventInitialFetch?: boolean;
}

const useFetchOrganizations = ({
  preventInitialFetch = false,
}: FetchOrderDraftsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(!preventInitialFetch);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const orgsCursor = useRef<string | null>(null);
  const currentAbortController = useRef<AbortController | null>(null);

  const [searchQuery, setSearchQuery] = useDebouncedState('', 300);

  const endReachedRef = useRef<boolean>(false);
  const isInitialFetch = useRef<boolean>(true);

  const resetParams = () => {
    setOrganizations([]);
    orgsCursor.current = null;
    endReachedRef.current = false;
  };

  const loadOrganizations = useCallback(
    ({
      excludeUnconfirmed = true,

      reset = false,
    }: LoadOrganizationsProps) => {
      if (reset) {
        resetParams();
      } else if (endReachedRef.current) return;

      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }

      const controller = new AbortController();
      currentAbortController.current = controller;

      setIsLoading(true);
      httpGetV1('/businesses/me/customers', {
        params: {
          cursor: orgsCursor.current,
          exclude_unconfirmed: excludeUnconfirmed,
          search_query: searchQuery,
        },
        signal: controller.signal, // Pass the signal to the fetch request
      })
        .then((response) => {
          const fetchedOrgs = response.data.result || [];

          setOrganizations((orgs) => [...orgs, ...fetchedOrgs]);
          orgsCursor.current = response.data.cursor;

          if (!response.data.cursor || fetchedOrgs.length === 0) {
            endReachedRef.current = true;
          }
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            console.error('failed to fetch organizations:', error);
          }
        })
        .finally(() => {
          if (!controller.signal.aborted) {
            setIsLoading(false);
          }
          currentAbortController.current = null;
        });
    },
    [searchQuery],
  );

  // Manage initial fetch
  useEffect(() => {
    if (preventInitialFetch) {
      isInitialFetch.current = false;
      return () => {};
    }

    if (!loadOrganizations) return () => {};

    if (isInitialFetch.current) {
      isInitialFetch.current = false;
      loadOrganizations({ reset: true });
    }

    return () => {
      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }
    };
  }, [loadOrganizations, preventInitialFetch]);

  // Manage search query
  useEffect(() => {
    if (isInitialFetch.current) {
      return;
    }

    loadOrganizations?.({ reset: true });
  }, [searchQuery, loadOrganizations]);

  return {
    organizations,
    isLoading,
    loadOrganizations,
    setSearchQuery,
  };
};

export { useFetchOrganizations };
