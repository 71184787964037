import { ComponentType } from 'react';
import { twMerge } from 'tailwind-merge';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';

import { useKeywordContext } from 'features/order/contexts/useKeywordContext';
import { Keyword } from 'features/order/types/keyword';
import { isValidKeyword } from 'features/order/utils/keyword';

interface InjectedProps {
  fieldPath: string;
  fieldId?: string;
  wrapperClassName?: string;
  isProductField?: boolean;
}

const isSelectedKeyword = (hoveredKeyword: Keyword, fieldPath: string, fieldId: string) => (
  (fieldId || fieldPath)
  && (fieldId ? (hoveredKeyword?.fieldIds || []).includes(fieldId) : true)
  && (fieldPath ? hoveredKeyword?.fieldPath === fieldPath : true)
);

const FieldPathWrapper = <TProps extends object>(
  Component: ComponentType<TProps>,
): ComponentType<TProps & InjectedProps> => {
  function WrappedComponent({
    fieldPath,
    fieldId,
    wrapperClassName,
    isProductField,
    ...props
  }: InjectedProps & TProps) {
    const { zoomToId } = useProcessOrderContext();
    const {
      toZoomKeyword, leftHoveredKeyword, setToZoomKeyword, setRightHoveredKeyword,
    } = useKeywordContext();

    const isSelected = isSelectedKeyword(leftHoveredKeyword, fieldPath, fieldId);

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      // Make sure to trigger the effect only if the click is not comming from the programmatic click
      if (e.detail === 0) return;

      if (!fieldPath && !fieldId) return;

      zoomToId(fieldPath, fieldId);

      setToZoomKeyword({
        fieldIds: [fieldId],
        fieldPath,
      });

      setRightHoveredKeyword({
        fieldIds: [fieldId],
        fieldPath,
      });
    };

    return (
      <div
        data-field-path={fieldPath}
        onClick={handleClick}
        role="button"
        tabIndex={0}
        onKeyDown={null}
        className={twMerge(
          'cursor-pointer',
          wrapperClassName,
          isSelected && 'text-blue-400',
          (isValidKeyword(leftHoveredKeyword) && !isValidKeyword(toZoomKeyword) && !isSelected && !isProductField) && 'text-gray-400 [&>div]:opacity-20',
        )}
      >
        <Component {...props as TProps} />
      </div>
    );
  }

  return WrappedComponent;
};

export default FieldPathWrapper;
