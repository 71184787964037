import { useState, useCallback } from 'react';

import { httpPostV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';
import { globalAlertData } from 'state/globalAlertData';
import { globalOrderDrafts } from 'state/globalOrderDrafts';

const useDeclineOrderDraft = () => {
  const [isLoading, setIsLoading] = useState(false);

  const declineOrderDraft = useCallback(
    (id: string): Promise<void> => {
      if (isLoading) return Promise.resolve();
      setIsLoading(true);
      const timeToFinalize = localStorage.getItem(`${id}/timeToFinalize`);
      console.log('declineOrderDraft timeToFinalize', timeToFinalize);
      return httpPostV1(`/orders/${id}/reject`, { id, timeToFinalize })
        .then(() => {
          globalAlertData.create('Order declined successfully');
          globalOrderDrafts.markOrderAsProcessed();
        })
        .catch((error) => {
          genericErrorFeedback('Decline order failed')(error);
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [isLoading],
  );

  return { isLoading, declineOrderDraft };
};

export { useDeclineOrderDraft };
