import { Button } from '@mantine/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { getIconForAttachment } from 'utils/attachments';
import { Attachment } from '../../../../../features/message/models/Message';

export const AttachmentList = ({ attachments }: { attachments: Attachment[] }) => (
  <div>
    <div className="flex gap-2">
      {attachments.map((attachment) => (
        <Button
          key={attachment.url}
          variant="outline"
          className="group"
          leftSection={(
            <>
              <img
                src={getIconForAttachment(attachment.type)}
                className="w-5 h-5 group-hover:hidden"
                alt={attachment.type.toString()}
              />

              <FontAwesomeIcon icon={faDownload} className="w-5 h-5 group-hover:block hidden" />
            </>
          )}
          onClick={() => {
            window.open(attachment.url, '_blank');
          }}
        >
          {attachment.name}
        </Button>
      ))}
    </div>
  </div>
);
