import { useEffect, useRef, useState } from 'react';
import {
  CheckIcon,
  Combobox,
  Group,
  Input,
  LoadingOverlay,
  Pill,
  PillsInput,
  ScrollArea,
  useCombobox,
} from '@mantine/core';

import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';
import { Option } from './types';

interface Props {
  options: Option[];

  selectedValues: string[];

  isLoading?: boolean;

  label?: string;
  description?: string;
  placeHolder?: string;

  isSearchable?: boolean;

  onRemove?: (value: string) => void;
  onSelect?: (value: string) => void;
  onSearch?: (searchQuery: string) => void;
  onScrolledEnd?: (searchQuery: string) => void;
}

const MultiSelect = ({
  options,
  placeHolder,
  selectedValues,
  isLoading,
  label,
  description,
  isSearchable = false,
  onSelect,
  onRemove,
  onSearch,
  onScrolledEnd,
}: Props) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex('active');

      if (isSearchable) {
        combobox.focusSearchInput();
      }
    },
  });

  const [searchQuery, setSearchQuery] = useState('');

  const viewPortRef = useRef<HTMLDivElement>(null);

  const values = selectedValues.map((item) => {
    const option = options.find((_option) => _option.value === item);
    return (
      <Pill radius="sm" key={item} withRemoveButton onRemove={() => onRemove?.(item)}>
        {option?.shortName ? (
          <p>{option?.shortName}</p>
        ) : (
          <span>{option?.label}</span>
        )}
      </Pill>
    );
  });

  const _options = options.map((option) => (
    <Combobox.Option
      value={option.value}
      key={option.value}
      active={selectedValues.includes(option.value)}
    >
      <Group gap="sm">
        {selectedValues.includes(option.value) ? <CheckIcon size={12} /> : null}
        {option.objectId ? (
          <div className="max-w-22 flex-shrink-0 flex-grow">
            <OverflowEllipsisParagraph
              maxLines={1}
              isTooltipEnabled
              className="relative top-[1px] !font-mono"
            >
              {option.objectId}
            </OverflowEllipsisParagraph>
          </div>
        ) : null}
        <span>{option.label}</span>
      </Group>
    </Combobox.Option>
  ));

  const onSearchQueryChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const onOptionsScroll = (position: { x: number; y: number }) => {
    if (
      viewPortRef.current.scrollHeight
      <= Math.round(position.y) + viewPortRef.current.clientHeight
    ) onScrolledEnd?.(searchQuery);
  };

  useEffect(() => {
    if (onSearch) {
      onSearch(searchQuery);
    }
  }, [searchQuery, onSearch]);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(value) => {
        const option = options.find((_option) => _option.value === value);
        if (option) {
          onSelect?.(option.value);
        }
      }}
      withinPortal={false}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          label={label}
          description={description}
          pointer
          onClick={() => combobox.toggleDropdown()}
        >
          <Pill.Group>
            {values.length > 0 ? (
              values
            ) : (
              <Input.Placeholder>
                {placeHolder || 'Pick one or more values'}
              </Input.Placeholder>
            )}
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        {isSearchable ? (
          <Combobox.Search
            value={searchQuery}
            onChange={onSearchQueryChanged}
            placeholder="Search options..."
          />
        ) : null}
        <Combobox.Options>
          <ScrollArea.Autosize
            mah={200}
            type="scroll"
            offsetScrollbars
            onScrollPositionChange={onOptionsScroll}
            viewportRef={viewPortRef}
          >
            {_options.length > 0 ? (
              _options
            ) : (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            )}

            <div
              className={`relative h-20 ${isLoading ? 'visible' : 'hidden'}`}
            >
              <LoadingOverlay
                visible={isLoading}
                loaderProps={{ type: 'dots' }}
                overlayProps={{ blur: 2 }}
              />
            </div>
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default MultiSelect;
