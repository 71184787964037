import { BusinessSettings } from '../../../../../models/BusinessSettings';
import Input from '../../../ui/Input';
import Select from '../../../ui/Select/Select';

interface Props {
  businessSettings: BusinessSettings;
}

const SFTP = ({ businessSettings }: Props) => {
  const sftpSetting = businessSettings.erpConnectionSettings.sftp;

  return (
    <div className="grid gap-5 text-sm text-gray-700 lg:py-7.5">
      <Input label="IP address" value={sftpSetting?.host} readOnly />
      <Input label="Username" value={sftpSetting?.user} readOnly />
      <Select
        label="Authentication type"
        value={(sftpSetting?.auth?.type || '').toUpperCase()}
      />
    </div>
  );
};

export default SFTP;
