import { useState, useCallback, useEffect } from 'react';

import { httpGetV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';
import { Language } from 'models/Language';

interface Props {
  preventInitialFetch?: boolean;
}

const useFetchTranscriptionLanguages = ({
  preventInitialFetch = false,
}: Props) => {
  const [transcriptionLanguages, setTranscriptionLanguages] = useState<Map<string, Language>>();
  const [isLoading, setIsLoading] = useState(false);

  const loadTranscriptionLanguages = useCallback(
    (
      giveErrorFeedback: boolean = true,
    ): Promise<any> => {
      setIsLoading(true);
      return httpGetV1('/language/transcription_languages')
        .then((response) => {
        // Create a code to Language map
          const languages = new Map<string, Language>();
          response.data.forEach((l: Language) => {
            languages.set(l.code, l);
          });
          setTranscriptionLanguages(languages);

          return Promise.resolve();
        })
        .catch((err) => {
          if (giveErrorFeedback) {
            return genericErrorFeedback(err.error_description);
          }
          return Promise.reject(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  useEffect(() => {
    if (!preventInitialFetch) {
      loadTranscriptionLanguages();
    }
  }, [preventInitialFetch, loadTranscriptionLanguages]);

  return {
    transcriptionLanguages,
    isLoading,
  };
};

export { useFetchTranscriptionLanguages };
