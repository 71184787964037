import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from '@mantine/core';
import { BarChart } from 'components/charts';
import { useFetchTopTierCustomers } from 'hooks/fetch/useFetchTopTierCustomers';
import { TopTierCustomer } from 'models/Analytics';
import { useEffect, useState } from 'react';

const formatData = (data: TopTierCustomer[], metric: 'total_products_sold' | 'total_revenue') => {
  if (!data) return [];

  const formattedData = data.map((item) => ({
    x: item.customerName,
    y: metric === 'total_products_sold' ? item.totalQuantitySum : item.totalRevenueSum,
  }));

  return formattedData;
};

const TopTierCustomerBarChart = () => {
  const { topTierCustomers, loadTopTierCustomers } = useFetchTopTierCustomers();

  const [period, setPeriod] = useState<'daily' | 'weekly' | 'monthly'>('monthly');
  const [metric, setMetric] = useState<'total_products_sold' | 'total_revenue'>('total_products_sold');

  const [data, setData] = useState<{ x: string; y: number }[]>([]);

  const title = () => (
    <div className="flex items-center w-fit -mb-2">
      Top 10 Customers by
      <Select
        value={metric}
        data={[{ value: 'total_products_sold', label: 'Products Sold' }, { value: 'total_revenue', label: 'Revenue' }]}
        styles={{
          input: {
            outline: '0px',
            border: '0px',
            color: '#2563EB',
          },
        }}
        onChange={(value) => setMetric(value as 'total_revenue' | 'total_products_sold')}
        rightSection={<FontAwesomeIcon icon={faChevronDown} />}
        comboboxProps={{ offset: 0 }}
        w={150}
      />
    </div>
  );

  const tooltipFormatter = (value: any) => [value.toLocaleString(), metric === 'total_products_sold' ? 'Products Sold' : 'CHF'];

  useEffect(() => {
    loadTopTierCustomers(period, metric, 10);
  }, [period, metric, loadTopTierCustomers]);

  useEffect(() => {
    if (topTierCustomers) {
      setData(formatData(topTierCustomers, metric));
    }
  }, [topTierCustomers, metric]);

  return (
    <BarChart
      title={title()}
      subtitle={`Best performing customers by ${metric === 'total_products_sold' ? 'products sold' : 'revenue'}`}
      data={data}
      xDataKey="x"
      yDataKey="y"
      selectOptions={[
        { value: 'daily', label: 'Daily' },
        { value: 'weekly', label: 'Weekly' },
        { value: 'monthly', label: 'Monthly' },
      ]}
      selectedOptionValue={period}
      setOption={setPeriod}
      tooltipFormatter={tooltipFormatter}
    />
  );
};

export default TopTierCustomerBarChart;
