import { Business, Organization } from 'models/Business';
import { ContactMedium } from 'features/user/models/ContactUser';

enum UserRole {
  AiAssistant = 'AI_ASSISTANT',
  SupplierAdmin = 'SUPPLIER_ADMIN', // TODO(ntauth): DEPRECATE
  SupplierUser = 'SUPPLIER_USER', // TODO(ntauth): DEPRECATE
  BusinessSuperAdmin = 'BUSINESS_SUPER_ADMIN',
  BusinessAdmin = 'BUSINESS_ADMIN',
  BusinessUser = 'BUSINESS_USER',
}

class User {
  externalId?: string;

  subjectData: SubjectData;

  id: string;

  username: string;

  firstName: string;

  lastName: string;

  email: string;

  phone: string;

  roles: UserRole[];

  external: boolean;

  business: Business;

  contactMedium: ContactMedium;

  activatedAt: string;

  updatedAt: string;
}

class SubjectData {
  organizationId: string;

  organization: Organization;
}

type Subject = User;

export { User, UserRole, Subject };
