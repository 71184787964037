import { useState, useCallback, useEffect } from 'react';

import { httpGetV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';

interface Props {
  preventInitialFetch?: boolean;
}

const useFetchAssignableRoles = ({
  preventInitialFetch = false,
}: Props) => {
  const [assignableRoles, setAssignableRoles] = useState<Array<string>>();
  const [isLoading, setIsLoading] = useState(false);

  const loadAssignableRoles = useCallback(
    (
      giveErrorFeedback: boolean = true,
    ): Promise<any> => {
      setIsLoading(true);
      return httpGetV1('/users/me/assignable_roles')
        .then((response) => {
          const roles = new Array<string>();
          response.data.forEach((l: string) => {
            roles.push(l);
          });
          setAssignableRoles(roles);

          return Promise.resolve();
        })
        .catch((err) => {
          if (giveErrorFeedback) {
            return genericErrorFeedback(err.error_description);
          }
          return Promise.reject(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  useEffect(() => {
    if (!preventInitialFetch) {
      loadAssignableRoles();
    }
  }, [preventInitialFetch, loadAssignableRoles]);

  return {
    assignableRoles,
    isLoading,
  };
};

export { useFetchAssignableRoles };
