import { useEffect } from 'react';

import { Message } from 'features/message/models/Message';
import { isOrderRefType } from 'features/message/utils/message';
import { useFetchOrderByMessage } from 'features/order/hooks/useFetchOrderByMessage';
import LoadingOverlay from 'components/ui/LoadingOverlay';

import OrderPanel from './OrderPanel';

interface Props {
  message: Message;
}

const ProcessMessagePanel = ({ message }: Props) => {
  const { isLoading: isOrderLoading, loadOrder, order } = useFetchOrderByMessage({
    message,
    defaultIsLoading: false,
  });

  useEffect(() => {
    if (isOrderRefType(message?.refType)) {
      loadOrder();
    }
  }, [message, loadOrder]);

  if (isOrderLoading) {
    return <LoadingOverlay visible />;
  }

  if (order) {
    return <OrderPanel order={order} />;
  }

  return (
    <div className="text-lg text-gray-500">
      Adam will soon assist you in processing the message.
      <br />
      Stay tuned for updates!
    </div>
  );
};

export default ProcessMessagePanel;
