import {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import { twMerge } from 'tailwind-merge';
import { isZeroTime } from 'utils/dateTime';

import { useFetchOrderByMessage } from 'features/order/hooks/useFetchOrderByMessage';

import { isOrderRefType } from 'features/message/utils/message';
import {
  Message, MessageContextUtils,
} from '../../../../features/message/models/Message';
import { MessageDirection } from '../../../../features/message/types/message';
import { EllipsisButton } from './EllipsisButton';
import { RecordingMessage } from './RecordingMessage';
import { MessageDatetimeWrapper } from '../../../wrapper/MessageDatetimeWrapper';
import { useIntersectionObserver } from '../../../../hooks/utils/useIntersectionObserver';
import { PreviewHTMLModalWrapper } from '../../../wrapper/PreviewHTMLModalWrapper';
import { MessageBodyBase } from './MessageBody';
import { Subject } from './Subject';
import { TagBase } from './Tag';
import { ROUTES } from '../../../../config/routes';
import { ImageMessage } from './ImageMessage';
import { Business } from '../../../../models/Business';
import { Actions } from './Actions';
import { AttachmentList } from './AttachmentList/AttachmentList';
import { Order } from '../../../../features/order/models/Order';
import { useMarkMessageAsRead } from '../../../../hooks/useMarkMessageAsRead';
import RagMessageAccordion from './Rag/RagMessageAccordion';

const ChatMessageBaseWrapper = memo(({
  children,
  order,
  message,
  business,
  direction,
  isOrderLoading,
  isMessageExpanded,
  handleIntersect,
  onOrderDetailsTitle,
  onOrderDetailsButtonClick,
  onCreateNewOrderButtonClick,
}: {
  children: React.ReactNode;
  message: Message;
  order?: Order;
  business?: Business;
  direction: MessageDirection;
  onOrderDetailsTitle: string;
  isMessageExpanded: boolean;
  handleIntersect: () => void;
  onOrderDetailsButtonClick: () => void;
  onCreateNewOrderButtonClick: () => void;
  isOrderLoading: boolean;
}) => {
  const { ref: intersectionRef, isIntersecting } = useIntersectionObserver(handleIntersect);

  const isAdded = useMemo(() => message.isAdded, [message.isAdded]);

  const { markMessagesAsRead } = useMarkMessageAsRead();
  useEffect(() => {
    if (isIntersecting && isMessageExpanded && isZeroTime(message.readAt)) {
      markMessagesAsRead([message.id]);
    }
  }, [
    isIntersecting,
    isMessageExpanded,
    message.readAt,
    markMessagesAsRead,
    message.id,
  ]);

  const previewHTMLModalWrapperRef = useRef(null);
  const onPreviewHTMLClick = () => {
    if (previewHTMLModalWrapperRef.current) {
      previewHTMLModalWrapperRef.current.onShowHtmlPreviewButtonClick();
    }
  };

  return (
    <motion.div
      ref={intersectionRef}
      className={twMerge(
        'max-w-[min(70%,600px)] group',
        direction === MessageDirection.Sent && 'self-end',
      )}
      id={message.id}
      initial={isAdded ? { opacity: 0, y: 10 } : null}
      animate={isAdded ? { opacity: 1, y: 0 } : null}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <MessageDatetimeWrapper message={message} direction={direction}>
        <PreviewHTMLModalWrapper
          ref={previewHTMLModalWrapperRef}
          message={message}
        >
          <div className={twMerge(
            'w-full',
            direction === MessageDirection.Sent && 'flex justify-end',
          )}
          >
            <div className="flex space-x-2 w-fit">
              <div className="group relative flex-1">
                {children}

                {
                    direction === MessageDirection.Received && !message.isAdamThinking && (
                      <>
                        <EllipsisButton
                          onOrderDetailsTitle={order ? onOrderDetailsTitle : null}
                          onOrderDetailsButtonClick={
                            order ? onOrderDetailsButtonClick : null
                          }
                          onCreateNewOrderButtonClick={
                              !order ? onCreateNewOrderButtonClick : null
                          }
                          onShowHtmlPreviewButtonClick={
                            message.context?.html ? onPreviewHTMLClick : null
                          }
                        />

                        <TagBase
                          message={message}
                          order={order}
                          isIntersecting={isIntersecting}
                          isOrderLoading={isOrderLoading}
                        />
                      </>
                    )
                  }
              </div>

              {
                  direction === MessageDirection.Received && (
                  <Actions
                    message={message}
                    business={business}
                  />
                  )
                }
            </div>
          </div>
        </PreviewHTMLModalWrapper>
      </MessageDatetimeWrapper>
    </motion.div>
  );
});

interface Props {
  message: Message;
  business?: Business;
  direction: MessageDirection;

  isExpandable?: boolean; // In case user wants to force non-expandable messages
  isOrderProcessingMode?: boolean;
}

const ChatMessageBase = memo(
  ({
    message,
    business,
    direction,

    isExpandable = false,
    isOrderProcessingMode = false,
  }: Props) => {
    const navigate = useNavigate();

    const {
      order,
      isLoading: isOrderLoading,
      loadOrder,
    } = useFetchOrderByMessage({ message });

    const [isMessageExpanded, setIsMessageExpanded] = useState(false);

    const onOrderDetailsTitle = useMemo(() => {
      if (order?.isDraft) return 'Process order draft';

      return 'See order detail';
    }, [order]);

    // Load (if it was an order) the order on interaction
    const handleIntersect = useCallback(() => {
      // This is more for the tag and applies only for chat interface
      if (isOrderProcessingMode) return;

      // If it is not an order message or no orderId found
      if (
        !message.ref
      ) return;

      // If the order is already set
      if (order?.id === message.ref) return;

      loadOrder();
    }, [
      isOrderProcessingMode,
      loadOrder,
      message.ref,
      order?.id,
    ]);

    const isExpandableMessageType = useMemo(() => {
      // If it's an order
      if (isOrderRefType(message.refType)) return true;

      // If it contains audio
      if (MessageContextUtils.audioAttachments(message.context)?.length > 0) return true;

      // If it contains documents
      if (MessageContextUtils.documentAttachments(message.context)?.length > 0) return true;

      return false;
    }, [
      message?.refType,
      message?.context,
    ]);

    // Used for positioning correctly the blur
    const isLongMessage = useMemo(
      () => message.message?.length > 300,
      [message.message?.length],
    );

    const onCreateNewOrderClick = useCallback(() => {
      navigate(
        `${ROUTES.PROCESS_NEW_ORDER_DRAFTS}?customer_id=${message.businessSentBy}&message_id=${message.id}`,
      );
    }, [message?.businessSentBy, message?.id, navigate]);

    const onOrderDetailsButtonClick = useCallback(() => {
      if (order?.isDraft) {
        navigate(ROUTES.PROCESS_ORDER_DRAFT_BY_ID(order?.id));
      } else {
        navigate(`${ROUTES.ORDERS}/${order?.id}`);
      }
    }, [
      navigate,
      order,
    ]);

    if (isExpandable && !isMessageExpanded && isExpandableMessageType) {
      return (
        <ChatMessageBaseWrapper
          message={message}
          order={order}
          business={business}
          direction={direction}
          handleIntersect={handleIntersect}
          isMessageExpanded={isMessageExpanded}
          onOrderDetailsTitle={onOrderDetailsTitle}
          onOrderDetailsButtonClick={onOrderDetailsButtonClick}
          onCreateNewOrderButtonClick={onCreateNewOrderClick}
          isOrderLoading={isOrderLoading}
        >
          <div className="mb-sm rounded-lg border border-blue-gray-100 bg-white p-lg text-content-md">
            <Subject message={message} />
            <div className="relative cursor-pointer">
              <div
                className={twMerge(
                  'relative overflow-hidden h-fit',
                  isLongMessage ? 'max-h-[90px]' : 'max-h-[30px]',
                  direction === MessageDirection.Sent && 'bg-primary-500 text-white',
                )}
              >
                <MessageBodyBase message={message} direction={direction} />
                <div
                  className={`absolute bottom-0 left-0 ${isLongMessage ? 'h-[80%]' : 'h-[40px]'} z-50 w-full bg-gradient-to-b from-transparent to-white`}
                />
              </div>

              {/* Expand icon */}
              <div className="absolute -bottom-[5px] left-[50%] z-[100] hidden group-hover:block">
                <button
                  type="button"
                  onClick={() => setIsMessageExpanded(true)}
                  className="w-full rounded-full border bg-white p-1"
                >
                  <ChevronDownIcon className="aspect-square w-5" />
                </button>
              </div>
            </div>
          </div>
        </ChatMessageBaseWrapper>
      );
    }

    return (
      <ChatMessageBaseWrapper
        message={message}
        order={order}
        business={business}
        direction={direction}
        handleIntersect={handleIntersect}
        isMessageExpanded={isMessageExpanded}
        onOrderDetailsTitle={onOrderDetailsTitle}
        onOrderDetailsButtonClick={onOrderDetailsButtonClick}
        onCreateNewOrderButtonClick={onCreateNewOrderClick}
        isOrderLoading={isOrderLoading}
      >
        <div className={twMerge(
          'mb-sm rounded-lg border border-blue-gray-100 bg-white p-lg text-content-md',
          direction === MessageDirection.Sent && 'bg-primary-500 text-white',
        )}
        >
          <Subject message={message} />
          <MessageBodyBase message={message} direction={direction} />
          {MessageContextUtils.ragAttachment(message.context) && (
            <RagMessageAccordion ragItem={MessageContextUtils.ragAttachment(message.context)} />
          )}
        </div>
        <div className="hidden">{direction}</div>

        {MessageContextUtils.audioAttachments(message.context)?.length > 0 && (
          <RecordingMessage
            message={message}
            disableShortcut={!isOrderProcessingMode}
          />
        )}

        {MessageContextUtils.documentAttachments(message.context)?.length > 0 && (
          <ImageMessage message={message} />
        )}

        <AttachmentList attachments={message.context?.attachments || []} />
      </ChatMessageBaseWrapper>
    );
  },
);

export default ChatMessageBase;
