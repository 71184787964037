import { useMemo } from 'react';
import {
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
  differenceInMinutes,
  isSameDay,
  isYesterday,
} from 'date-fns';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

import { Organization } from 'models/Business';
import { Subject } from 'features/user/models/User';

import { formatDate } from 'utils/dateTime';
import { useMessagesContext } from 'contexts/useMessagesContext';
import { isZeroId } from 'utils/objectId';

interface Props {
  customer: Organization;
  subject: Subject;

  onManageSubjectButtonClick?: () => void;
}

const Header = ({ customer, subject, onManageSubjectButtonClick }: Props) => {
  const { messages, isLoading: isMessageLoading } = useMessagesContext();

  const message = useMemo(() => messages?.[0], [messages]);

  const messageDatetimeText: string = useMemo(() => {
    if (!message?.createdAt) return '';

    const messageCreatedAt = new Date(message?.createdAt);
    const now = new Date();

    const diffInSeconds = differenceInSeconds(now, messageCreatedAt);
    const diffInMinutes = differenceInMinutes(now, messageCreatedAt);
    const diffInHours = differenceInHours(now, messageCreatedAt);
    const diffInDays = differenceInDays(now, messageCreatedAt);

    // Generate relative time text if enabled
    let relativeText = '';
    if (diffInSeconds < 60) {
      relativeText = 'Just now';
    } else if (diffInMinutes < 60) {
      relativeText = `${diffInMinutes}m ago`;
    } else if (diffInHours < 24 && isSameDay(now, messageCreatedAt)) {
      relativeText = `${diffInHours}h ago`;
    } else if (isYesterday(messageCreatedAt)) {
      relativeText = 'Yesterday';
    } else if (diffInDays < 8) {
      relativeText = `${diffInDays}d ago`;
    }

    // Format the full date
    const formattedDate = formatDate(messageCreatedAt);

    // Combine date with relative time if available
    return relativeText ? `${formattedDate} (${relativeText})` : formattedDate;
  }, [message]);

  const customerOrSubjectName = useMemo(() => {
    if (customer && customer?.name) return customer?.name;

    if (subject) {
      const name = `${subject.firstName || ''} ${subject.lastName || ''}`;

      if (name.replace(/\s/g, '').length > 0) return name;
    }

    return '';
  }, [customer, subject]);

  const customerOrSubjectContactMedium = useMemo(() => {
    // We always prefer subject contact medium to customer contact medium
    const subjectContactMedium = subject?.email || subject?.phone;

    if (subjectContactMedium) return subjectContactMedium;

    const customerContactMedium = (
      customer?.email
      || (customer?.emails || [])[0]
      || (customer?.phones || [])[0]?.number);

    if (customerContactMedium) return customerContactMedium;

    return '';
  }, [customer, subject]);

  const subjectNeedsCustomerAssignment = useMemo(() => isZeroId(subject?.subjectData?.organizationId), [subject]);

  return (
    <>
      <div className="flex justify-between text-sm 4xl:text-base">
        <div className="grid grid-cols-[auto_1fr] items-start gap-3">
          <p className="text-gray-400">From</p>

          {customerOrSubjectName ? (
            <div className="flex items-center gap-1">
              <p className="font-semibold">{customerOrSubjectName}</p>
              <p className="text-xs 4xl:text-sm text-gray-400">
                {'<'}
                {customerOrSubjectContactMedium}
                {'>'}
              </p>
            </div>
          ) : (
            <div>
              <p className="font-semibold">
                {customerOrSubjectContactMedium}
              </p>
            </div>
          )}

          <p className="text-gray-400">Subject</p>
          <div className="space-y-1 font-semibold">
            {message?.context?.subject || 'No subject'}
          </div>
        </div>

        <div className="flex">
          {
          !isMessageLoading && (
            <p className="text-xs 4xl:text-sm text-neutral-200 underline">
              {messageDatetimeText}
            </p>
          )
        }
        </div>
      </div>

      {subjectNeedsCustomerAssignment && onManageSubjectButtonClick && (
      <div className="mt-lg flex h-10 w-full items-center justify-between border-l-2 border-warning-border bg-warning-bg px-lg text-warning-title">
        <div className="flex items-center gap-x-md">
          <ExclamationTriangleIcon className="aspect-square h-5 text-warning-button" />
          Unknown contact detected
        </div>
        <div>
          <button
            type="button"
            className="px-1 text-warning-description"
            onClick={onManageSubjectButtonClick}
          >
            Manage
          </button>
        </div>
      </div>
      )}
    </>
  );
};

export default Header;
