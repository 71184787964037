import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import { debounce, uniqBy } from 'lodash';
import { makePersistable } from 'mobx-persist-store';

import { httpGetV1 } from 'utils/xhr';
import { Chat } from '../../features/chat/models/Chat';
import { GetDocumentsQueryType } from '../../models/GetDocumentsQueryType';
import { globalSseSources } from '../globalSseSources';

class Chats {
  chats: Chat[] = [];

  count: number = 0;

  isLoading?: boolean = false;

  dataPaginationCursor?: string = null;

  dataFilter?: 'private' | 'adam' = 'private';

  constructor() {
    makeObservable(this, {
      chats: observable,
      count: observable,
      dataFilter: observable,
      isLoading: observable,
      loadCounts: action,
      loadChats: action,
      updateDataFilter: action,
      _onNewChatSseReceived: action,
    });
  }

  initPersistance = async () => {
    await makePersistable(this, {
      name: 'chats',
      properties: ['dataFilter'],
      storage: window.localStorage,
    });
  };

  loadCounts = async () => {
    runInAction(() => {
      this.isLoading = true;
    });

    const countResponse = await httpGetV1('/chats', {
      params: {
        query_type: GetDocumentsQueryType.count,
      },
    });
    this.count = countResponse.data.count;

    runInAction(() => {
      this.isLoading = false;
    });
  };

  loadChats = async (reset?: boolean) => {
    runInAction(() => {
      if (reset) this.dataPaginationCursor = null;
      this.isLoading = true;
    });

    const dataResponse = await httpGetV1('/chats', {
      params: {
        limit: 20,
        cursor: this.dataPaginationCursor,
        query_type: GetDocumentsQueryType.data,
        ai_chats_only: this.dataFilter === 'adam',
      },
    });
    this.dataPaginationCursor = dataResponse.data.cursor;

    runInAction(() => {
      if (reset) {
        this.chats = dataResponse.data.result || [];
      } else {
        this.chats = uniqBy(
          [...this.chats, ...(dataResponse.data.result || [])],
          'id',
        );
      }
      this.isLoading = false;
    });
  };

  _debouncedLoadChats = debounce(this.loadChats, 2000);

  updateDataFilter = (filter: 'private' | 'adam') => {
    // this.isLoading = true;
    this.dataFilter = filter;

    // this.loadChats(true).finally(() => {
    //   runInAction(() => {
    //     this.isLoading = false;
    //   });
    // });
  };

  addSseSourcesHandler = () => {
    globalSseSources.addSourcesHandler(
      'chats/new_chat',
      ['new_chat'],
      this._onNewChatSseReceived,
    );
  };

  // eslint-disable-next-line class-methods-use-this
  removeSseSourcesHandler = () => {
    globalSseSources.removeSourcesHandler('chats/new_chat');
  };

  _onNewChatSseReceived = () => {
    this._debouncedLoadChats();
  };
}

export { Chats };
