import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';
import { LRO, LRO_STATUSES } from 'state/classes/LROs';
import { globalLROs } from 'state/globalLROs';
import { twMerge } from 'tailwind-merge';

interface Props {
  lro: LRO;
  isLast: boolean;
}

const LroListItem = ({ lro, isLast }: Props) => {
  const observerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const interactionObserver = new IntersectionObserver(
      (entries) => {
        // If the last notification is visible, load more notifications
        if (entries[0].isIntersecting && entries[0].target.getAttribute('data-is-last') === 'true') {
          globalLROs.loadPendingLROs();
        }
      },
      { threshold: 0.5 },
    );

    const currentObserverRef = observerRef.current;
    if (currentObserverRef) {
      interactionObserver.observe(currentObserverRef);
    }

    return () => {
      if (currentObserverRef) {
        interactionObserver.unobserve(currentObserverRef);
      }
    };
  }, [isLast]);

  if (lro.status === LRO_STATUSES.ERROR) {
    return (
      <div
        key={lro.lroId}
        ref={observerRef}
        className={twMerge(
          'group relative flex cursor-pointer gap-4 rounded-sm py-3 bg-gray-50',
        )}
      >
        <div className="flex items-start">
          <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 w-5 h-5" />
        </div>
        <div className="flex-1 overflow-hidden">
          {/* <p className='text-neutral-900 font-semibold'>{getTitle(event)}</p> */}
          <p className="line-clamp-2 w-full max-w-full overflow-hidden text-ellipsis leading-tight text-red-500">
            {lro.title}
          </p>
          <p className="text-xs text-neutral-300">
            {lro.description}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      key={lro.lroId}
      data-is-last={isLast}
      ref={observerRef}
      className={twMerge(
        'group relative flex cursor-pointer gap-4 rounded-sm py-3 bg-gray-50',
      )}
    >
      <div className="flex items-start">
        <div className="w-6 h-6 border-2 border-blue-200 rounded-full animate-spin border-t-blue-500" />
      </div>
      <div className="flex-1 overflow-hidden">
        {/* <p className='text-neutral-900 font-semibold'>{getTitle(event)}</p> */}
        <p className="line-clamp-2 w-full max-w-full overflow-hidden text-ellipsis leading-tight text-neutral-800">
          {lro.title}
        </p>
        <p className="text-xs text-neutral-300">
          {lro.description}
        </p>
      </div>
    </div>
  );
};

export default LroListItem;
