import { useState, useCallback } from 'react';

import { httpGetV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';
import { User } from 'features/user/models/User';

const useFetchUserById = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUserById = useCallback(
    async (userId: string, giveErrorFeedback: boolean = true): Promise<User> => {
      setIsLoading(true);
      return httpGetV1(`/businesses/me/users/${userId}`)
        .then((response) => {
          setUser(response.data);
          return response.data;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Error loading team member')(error);
          }
          return Promise.reject(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  return { isLoading, user, fetchUserById };
};

export { useFetchUserById };
