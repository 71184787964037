import { Expose } from 'class-transformer';

export class BusinessSales {
  @Expose({ name: 'sale_date' })
  public saleDate: string;

  @Expose({ name: 'business_id' })
  public businessId: string;

  @Expose({ name: 'total_quantity_sum' })
  public totalQuantitySum: number;

  @Expose({ name: 'total_revenue_sum' })
  public totalRevenueSum: number;
}

export class TopSellingProduct {
  @Expose({ name: 'sale_date' })
  public saleDate: string;

  @Expose({ name: 'business_id' })
  public businessId: string;

  @Expose({ name: 'product_id' })
  public productId: string;

  @Expose({ name: 'product_name' })
  public productName: string;

  @Expose({ name: 'total_quantity_sum' })
  public totalQuantitySum: number;

  @Expose({ name: 'total_revenue_sum' })
  public totalRevenueSum: number;
}

export class TopTierCustomer {
  @Expose({ name: 'sale_date' })
  public saleDate: string;

  @Expose({ name: 'business_id' })
  public businessId: string;

  @Expose({ name: 'customer_id' })
  public customerId: string;

  @Expose({ name: 'customer_name' })
  public customerName: string;

  @Expose({ name: 'total_quantity_sum' })
  public totalQuantitySum: number;

  @Expose({ name: 'total_revenue_sum' })
  public totalRevenueSum: number;
}
