import { HoverCard } from '@mantine/core';

import { NavigateFunction } from 'react-router-dom';
import { Button } from '../../../ui/Button';
import { SidebarItem } from './types';

interface Props {
  sidebarItem: SidebarItem;
  navigate: NavigateFunction;
}

const IconWithDropdown = ({ sidebarItem, navigate }: Props) => (
  <HoverCard
    key={`sidebar-dropdown-${sidebarItem.hoverTitle}`}
    shadow="md"
    position="right-start"
    openDelay={100}
    transitionProps={{ transition: 'scale-x', duration: 400 }}
  >
    <HoverCard.Target>
      <div className="group flex w-full cursor-pointer flex-row items-center justify-center gap-smd rounded-sm p-smd text-sm bg-white">
        {sidebarItem.icon && (
          <sidebarItem.icon
            height={22}
            width={22}
            className="transition duration-500 ease-in-out text-[rgb(87,83,78)]"
          />
        )}
      </div>
    </HoverCard.Target>
    <HoverCard.Dropdown p={0} className="relative">
      <div className="absolute -left-1 top-3 h-3 w-3 rotate-[45deg] transform bg-white border-b border-l border-[#E8E8E8]" />
      {sidebarItem.dropdownItems.map((dropdownItem) => (
        <Button
          title={dropdownItem.hoverTitle}
          theme="secondary"
          radius="sm"
          className="w-full text-center font-semibold hover:bg-light-gray-50"
          icon={dropdownItem.icon && <dropdownItem.icon height={18} width={18} className="gap-smd text-[rgb(87,83,78)]" />}
          onClick={() => {
            navigate(dropdownItem.path);
          }}
          key={`sidebar-dropdown-${dropdownItem.path}-${dropdownItem.hoverTitle}`}
        />
      ))}
    </HoverCard.Dropdown>
  </HoverCard>
);

export default IconWithDropdown;
