import {
  forwardRef, useCallback, useImperativeHandle, useState,
} from 'react';

import { Subject } from 'features/user/models/User';
import { ManageSubjectSidebar } from '../../sidebars/ManageSubject/ManageSubjectSidebar';

interface Props {
  children: React.ReactNode;

  subject: Subject;
  onComplete: (subject: Subject) => void;
}

const ManageSubjectSidebarWrapper = forwardRef(
  ({ children, subject, onComplete }: Props, ref) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const onManageSubjectButtonClick = useCallback(() => {
      setIsSidebarOpen(true);
    }, []);

    const onSidebarClose = useCallback(() => {
      setIsSidebarOpen(false);
    }, []);

    useImperativeHandle(ref, () => ({
      onManageSubjectButtonClick,
      onSidebarClose,
    }));
    return (
      <>
        {children}

        <ManageSubjectSidebar
          subject={subject}
          sidebarOpen={isSidebarOpen}
          setSidebarOpen={setIsSidebarOpen}
          onComplete={onComplete}
        />
      </>
    );
  },
);

export default ManageSubjectSidebarWrapper;
