import { useState, useCallback } from 'react';

import { httpGetV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';
import { Thread } from 'models/Thread';
import { Message } from '../../features/message/models/Message';

const useFetchThreadById = () => {
  const [thread, setThread] = useState<Thread | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadThread = useCallback(
    (
      threadId: string,
      giveErrorFeedback: boolean = true,
    ): Promise<Message> => {
      if (!threadId) return Promise.reject();
      setIsLoading(true);
      return httpGetV1(`/threads/${threadId}`)
        .then((response) => {
          setThread(response.data);
          return response.data;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('An error has occured while fetching thread')(
              error,
            );
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    thread,
    loadThread,
    isLoading,
  };
};

export { useFetchThreadById };
