import {
  useEffect,
} from 'react';

import { Page } from 'components/layout/Page/Page';
import { useMessagesContext } from 'contexts/useMessagesContext';
import { useFetchCustomerById } from 'hooks/fetch/useFetchCustomerById';
import { isZeroTime } from 'utils/dateTime';
import { useMarkMessageAsRead } from 'hooks/useMarkMessageAsRead';
import { isZeroId } from 'utils/objectId';
import { useFetchUserById } from 'features/user/hooks/useFetchUserById';
import { ProcessMessage } from '../components/process-message';

const MessageByIdPage = () => {
  const { messages, isLoading: isMessagesLoading } = useMessagesContext();
  const {
    customer,
    loadCustomer,
    isLoading: isCustomerLoading,
  } = useFetchCustomerById();

  const {
    user,
    fetchUserById,
  } = useFetchUserById();

  const { markMessagesAsRead } = useMarkMessageAsRead();

  useEffect(() => {
    if (messages.length > 0) {
      if (!isZeroId(messages[0].businessSentBy)) {
        loadCustomer(messages[0].businessSentBy);
      }

      fetchUserById(messages[0].userSentBy);

      if (isZeroTime(messages[0].readAt)) {
        markMessagesAsRead([messages[0].id]);
      }
    }
  }, [messages, loadCustomer, markMessagesAsRead, fetchUserById]);

  return (
    <Page contentWithBorder>
      <ProcessMessage
        messages={messages}
        customer={customer}
        userSentBy={user}
        isMessagesLoading={isMessagesLoading}
        isCustomerLoading={isCustomerLoading}
      />
    </Page>
  );
};

export default MessageByIdPage;
