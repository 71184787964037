import { Accordion, Highlight, Text } from '@mantine/core';
import { v4 as uuidv4 } from 'uuid';

import { RagItem } from '../../../../../features/message/models/Message';

interface Props {
  ragItem: RagItem;
}

const RagMessageAccordion = ({ ragItem }: Props) => {
  const uniqueId = uuidv4();

  return (
    <Accordion className="pt-3">
      <Accordion.Item
        value={uniqueId}
        className="relative flex flex-col rounded-lg border border-gray-400/50 bg-white shadow-sm"
      >
        <Accordion.Control
          className="flex min-h-[56px] items-center justify-between"
          style={{
            backgroundColor: 'transparent',
          }}
        >
          <Text size="sm">Original message</Text>
        </Accordion.Control>

        <Accordion.Panel>
          <Highlight
            highlight={ragItem.partOfItem}
            size="sm"
            className="whitespace-pre-wrap"
          >
            {ragItem.content}
          </Highlight>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default RagMessageAccordion;
