/*
  This component is used to render a rectangle on the canvas.
  It also renders a text on top of the rectangle if the hovered keyword matches the annotation.
*/

import {
  Group, Rect, Text,
} from 'react-konva';

import { isValidKeyword } from 'features/order/utils/keyword';
import { Keyword } from 'features/order/types/keyword';
import { Annotation } from './type';
import {
  getFillColor, getStrokeColor, getTextBackgroundColor, isKeywordMatchAnnotation,
} from './utils';

interface Props {
  annotation: Annotation;
  isLeftHoveredKeywordValid: boolean;
  hoveredKeyword: Keyword;
  onPopupClicked: (annotation: Annotation) => void;
  onMouseEnter: (annotation: Annotation) => void;
  onMouseLeave: (annotation: Annotation) => void;
}

const calculateTextWidth = (text: string, fontSize: number, fontFamily: string = 'Plus Jakarta Sans') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = `${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  }
  return 0;
};

const RectGroup = ({
  annotation, isLeftHoveredKeywordValid, hoveredKeyword, onPopupClicked, onMouseEnter, onMouseLeave,
}: Props) => (
  <Group>
    <Group>
      <Rect
        x={annotation.x}
        y={annotation.y}
        width={annotation.width}
        height={annotation.height}
        fill={getFillColor(annotation, hoveredKeyword, isLeftHoveredKeywordValid)}
        stroke={getStrokeColor(annotation, hoveredKeyword, isLeftHoveredKeywordValid)}
        strokeWidth={isValidKeyword(hoveredKeyword) ? 0 : 1}
        onClick={() => onPopupClicked(annotation)}
        onMouseEnter={() => onMouseEnter(annotation)}
        onMouseLeave={() => onMouseLeave(annotation)}
      />
    </Group>
    {hoveredKeyword && isKeywordMatchAnnotation(hoveredKeyword, annotation, isLeftHoveredKeywordValid) && (
    <>
      <Rect
        x={annotation.x}
        y={annotation.y - 9}
        stroke={getTextBackgroundColor(annotation)}
        strokeWidth={1}
        width={calculateTextWidth(annotation.label, 8) + 2}
        height={9}
        fill={getTextBackgroundColor(annotation)}
      />
      <Text
        x={annotation.x + 2}
        y={annotation.y - 8}
        text={annotation.label}
        fontSize={8}
        fill="white"
      />
    </>
    )}
  </Group>
);

export default RectGroup;
