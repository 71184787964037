import { useState, useCallback } from 'react';

import { httpPatchV1, httpPutV1 } from 'utils/xhr';
import { Order, ProductWithQuantity } from 'features/order/models/Order';
import { genericErrorFeedback } from 'utils/errors';
import { globalAlertData } from 'state/globalAlertData';

type UpdateOrderDraftParams = {
  customerId?: string;

  products?: ProductWithQuantity[];

  requestedDeliveryTime?: string;

  comment?: string;
};

type AssignTeamMeberProps = {
  order: Order;
  teamMemberId: string;
  teamMemberName: string;
  assignedComment?: string;
  createAlert?: boolean;
  giveErrorFeedback?: boolean;
};

const useAssignDraft = () => {
  const [isLoading, setIsLoading] = useState(false);

  const assignTeamMember = useCallback(
    async ({
      order,
      teamMemberId,
      teamMemberName,
      assignedComment = '',
      createAlert = true,
      giveErrorFeedback = true,
    }: AssignTeamMeberProps): Promise<Order> => {
      if (!order || !order.id) {
        return Promise.reject(new Error('orderId is required'));
      }
      if (!teamMemberId) {
        return Promise.reject(new Error('teamMemberId is required'));
      }
      if (isLoading) {
        return Promise.reject(
          new Error('Order assignment is already in progress'),
        );
      }

      setIsLoading(true);

      if (assignedComment.trim() !== '') {
        try {
          const params: UpdateOrderDraftParams = {
            comment: assignedComment,
          };
          await httpPatchV1(`/orders/drafts/${order.id}`, params);
        } catch (error) {
          setIsLoading(false);
          if (giveErrorFeedback) {
            genericErrorFeedback('An error has occured while saving comment')(error);
          }
          return Promise.reject(error);
        }
      }

      try {
        await httpPutV1(`/orders/${order.id}/assignee`, {
          assignee_id: teamMemberId,
        });
        if (createAlert) {
          globalAlertData.create(
            `Order draft assigned to ${teamMemberName} successfully`,
          );
        }

        return await Promise.resolve({
          ...order,
          assigneeId: teamMemberId,
          draft: {
            ...order.draft,
            comment: assignedComment,
          },
        });
      } catch (error) {
        if (giveErrorFeedback) {
          genericErrorFeedback('Error assigning order draft')(error);
        }
        return await Promise.reject(error);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading],
  );

  return { isLoading, assignTeamMember };
};

export { useAssignDraft };
