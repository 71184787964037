import { useState, useCallback, useRef } from 'react';
import { httpGetV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';
import { TopSellingProduct } from 'models/Analytics';

const useFetchTopSellingProducts = () => {
  const [topSellingProducts, setTopSellingProducts] = useState<TopSellingProduct[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const cursorRef = useRef<string | null>(null);

  const loadTopSellingProducts = useCallback(
    (
      period: 'daily' | 'weekly' | 'monthly',
      metric: 'total_products_sold' | 'total_revenue',
      limit: number = 10,
      giveErrorFeedback: boolean = true,
    ): Promise<TopSellingProduct[]> => {
      if (!period) return Promise.reject(new Error('Period is required'));
      setIsLoading(true);

      return httpGetV1('/products/analytics/sales', {
        params: {
          period,
          metric,
          limit,
        },
        classType: TopSellingProduct,
      })
        .then((response) => {
          cursorRef.current = response?.data?.cursor;
          setTopSellingProducts(response?.data?.result || []);
          return response?.data?.result;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('An error has occured while fetching products statistics')(
              error,
            );
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    topSellingProducts,
    loadTopSellingProducts,
    isLoading,
  };
};

export { useFetchTopSellingProducts };
