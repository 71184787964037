import { useState, useCallback } from 'react';
import { Annotation } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/KonvaStage/type';
import { isPointInSquare } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/KonvaStage/utils';

type useAnnotationsReturnType = {
  annotationsRecord: Record<string, Annotation[]>;
  resetAnnotationsRecord: () => void;
  setAnnotations: (key: string, annotations: Annotation[]) => void;
  setAnnotationIsLoading: (key: string, annotationKey: string, isLoading: boolean) => void;
  removeAnnotationByPoint: (orderId: string, key: string, x: number, y: number) => void;
  addAnnotationProductUiIds: (key: string, annotationKey: string, productUiIds: string[]) => void;
  addAnnotationPopupContent: (key: string, annotationKey: string, popupContent: string) => void;
  addAnnotationPath: (key: string, annotationKey: string, path: string) => void;
};

interface useAnnotationsProps {
  removeProductByUiIdAndPositionId: (orderId: string, uiId: string, positionId: string) => void;
}

export const useAnnotations = ({ removeProductByUiIdAndPositionId }: useAnnotationsProps): useAnnotationsReturnType => {
  const [annotationsRecord, setAnnotationsRecord] = useState<Record<string, Annotation[]>>({});

  const resetAnnotationsRecord = useCallback(() => {
    setAnnotationsRecord({});
  }, [setAnnotationsRecord]);

  const removeAnnotationByPoint = useCallback((orderId: string, key: string, x: number, y: number) => {
    setAnnotationsRecord((prevRecord) => {
      const newRecord = { ...prevRecord };

      let didRemove = false;

      newRecord[key] = newRecord[key].filter((annotation) => {
        const toErase = isPointInSquare(x, y, annotation);

        if (toErase) {
          (annotation.productUiIds || []).forEach((productUiId) => {
            removeProductByUiIdAndPositionId(orderId, productUiId, productUiId);
          });
          didRemove = true;
        }

        return !toErase;
      });

      if (!didRemove) {
        return prevRecord;
      }

      return newRecord;
    });
  }, [removeProductByUiIdAndPositionId]);

  const setAnnotations = useCallback((key: string, annotations: Annotation[]) => {
    setAnnotationsRecord((prev) => ({ ...prev, [key]: annotations }));
  }, [setAnnotationsRecord]);

  const setAnnotationIsLoading = useCallback((key: string, annotationKey: string, isLoading: boolean) => {
    setAnnotationsRecord((prevRecord) => {
      const newRecord = { ...prevRecord };
      newRecord[key] = newRecord[key].map((annotation) => (annotation.key === annotationKey
        ? { ...annotation, isLoading } : annotation));
      return newRecord;
    });
  }, [setAnnotationsRecord]);

  const addAnnotationProductUiIds = useCallback((key: string, annotationKey: string, productUiIds: string[]) => {
    setAnnotationsRecord((prevRecord) => {
      const newRecord = { ...prevRecord };
      newRecord[key] = newRecord[key].map((annotation) => (annotation.key === annotationKey
        ? { ...annotation, productUiIds } : annotation));
      return newRecord;
    });
  }, []);

  const addAnnotationPath = useCallback((key: string, annotationKey: string, path: string) => {
    setAnnotationsRecord((prevRecord) => {
      const newRecord = { ...prevRecord };
      newRecord[key] = newRecord[key].map((annotation) => (annotation.key === annotationKey
        ? { ...annotation, path } : annotation));
      return newRecord;
    });
  }, []);

  const addAnnotationPopupContent = useCallback((key: string, annotationKey: string, popupContent: string) => {
    setAnnotationsRecord((prevRecord) => {
      const newRecord = { ...prevRecord };
      newRecord[key] = newRecord[key].map((annotation) => (annotation.key === annotationKey
        ? { ...annotation, popupContent } : annotation));
      return newRecord;
    });
  }, [setAnnotationsRecord]);

  return {
    annotationsRecord,
    resetAnnotationsRecord,

    setAnnotations,
    setAnnotationIsLoading,
    removeAnnotationByPoint,
    addAnnotationProductUiIds,
    addAnnotationPath,
    addAnnotationPopupContent,
  };
};
