import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { uniqBy } from 'lodash';

import { httpGetV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';
import { Message, MessageIntent, MessageChannel } from '../../features/message/models/Message';

interface FetchMessagesProps {
  chatId: string;
  defaultFilter?: MessagesByChatIdFilter;
  preventInitialFetch?: boolean;
}

type MessagesByChatIdFilter = {
  intent?: MessageIntent;
  sources?: MessageChannel[];
  unreadOnly?: boolean;
};

const useFetchMessagesByChatId = ({
  chatId,
  defaultFilter,
  preventInitialFetch = false,
}: FetchMessagesProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const initialFetchRef = useRef(true);
  const endReachedRef = useRef(false);
  const messagesPaginationCursor = useRef<string | null>(null);
  const currentAbortController = useRef<AbortController | null>(null);

  const [messagesFilter, setMessagesFilter] = useState<MessagesByChatIdFilter>(defaultFilter || {});

  const resetParams = () => {
    setMessages([]);
    setMessagesFilter({});
    messagesPaginationCursor.current = null;
    endReachedRef.current = false;
  };

  const loadMessages = useCallback(
    async (giveErrorFeedback: boolean = true, reset: boolean = false) => {
      if (reset) {
        messagesPaginationCursor.current = null;
        endReachedRef.current = false;
      }

      if (!chatId || endReachedRef.current) return;

      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }

      const controller = new AbortController();
      currentAbortController.current = controller;

      setIsLoading(true);

      try {
        const response = await httpGetV1(`/chat/${chatId}/messages`, {
          params: {
            cursor: messagesPaginationCursor.current,
            limit: 12,
            ...messagesFilter,
          },
          signal: controller.signal,
        });

        const entries = (response?.data?.result || []).reverse();
        if (reset) {
          setMessages(entries);
        } else {
          setMessages((msgs) => uniqBy([...entries, ...msgs], 'id'));
        }
        messagesPaginationCursor.current = response.data.cursor;

        if (!response.data.cursor || entries.length === 0) {
          endReachedRef.current = true;
        }
      } catch (error) {
        console.error('Failed to fetch messages:', error.name);
        if (giveErrorFeedback && error.name !== 'AbortError' && error.name !== 'CanceledError') {
          genericErrorFeedback('An error has occured while fetching chat messages')(error);
        }
      } finally {
        if (!controller.signal.aborted) {
          setIsLoading(false);
        }
        currentAbortController.current = null;
      }
    },
    [chatId, messagesFilter],
  );

  useEffect(() => {
    if (preventInitialFetch) {
      initialFetchRef.current = false;
      return () => {};
    }

    if (initialFetchRef.current) {
      initialFetchRef.current = false;
      loadMessages();
    }

    return () => {
      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }
    };
  }, [chatId, loadMessages, preventInitialFetch]);

  useEffect(() => {
    resetParams();
  }, [chatId]);

  useEffect(() => {
    if (initialFetchRef.current) {
      initialFetchRef.current = false;
      return;
    }

    endReachedRef.current = false;

    loadMessages(true, true);
  }, [messagesFilter, loadMessages]);

  return {
    messages,
    setMessages,
    messagesFilter,
    setMessagesFilter,
    isLoading,
    loadMessages,
  };
};

export { useFetchMessagesByChatId, MessagesByChatIdFilter };
