import Axios, {
  AxiosError,
  AxiosInterceptorOptions,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import AxiosRetry from 'axios-retry';

import { doesExist } from './comparison';
import {
  convertKeysToCamelCase, convertKeysToSnakeCase, instanceToPlain, plainToInstance,
} from './mapping';

enum APIVersion {
  V1 = 'v1',
  V2 = 'v2',
}

interface AxiosRequestConfigEx extends AxiosRequestConfig {
  classType?: new (...args: any[]) => any;
}

const axiosInstanceV1 = Axios.create({
  baseURL: `${HOSHII_API_URL}/${APIVersion.V1}`,
  timeout: 60 * 1000,
  withCredentials: true,
  transformRequest: [
    function (this: AxiosRequestConfigEx, data) {
      if (!data) return '';
      if (this?.classType) {
        return JSON.stringify(instanceToPlain(data));
      }
      // TODO(ntauth): Remove this once all models are decorated with @Expose
      return JSON.stringify(convertKeysToSnakeCase(data));
    },
  ],
  transformResponse: [
    function (this: AxiosRequestConfigEx, data) {
      if (!data) return '';
      if (this?.classType) {
        const decodedData = JSON.parse(data);
        // TODO(chihirokuya): The control of paginated here should be improved
        // If the response is paginated, we need to decode the result field
        if (doesExist(decodedData?.cursor) && doesExist(decodedData?.result)) {
          const result = plainToInstance(this.classType, decodedData.result);
          return {
            ...decodedData,
            result,
          };
        }
        return plainToInstance(this.classType, decodedData);
      }
      // TODO(ntauth): Remove this once all models are decorated with @Expose
      return convertKeysToCamelCase(JSON.parse(data));
    },
  ],
});

const axiosInstanceV2 = Axios.create({
  baseURL: `${HOSHII_API_URL}/${APIVersion.V2}`,
  timeout: 60 * 1000,
  withCredentials: true,
  transformRequest: [
    (data) => (data ? JSON.stringify(convertKeysToSnakeCase(data)) : ''),
  ],
  transformResponse: [
    (data) => (data ? convertKeysToCamelCase(JSON.parse(data)) : ''),
  ],
});

const axiosBlobInstanceV1 = Axios.create({
  baseURL: `${HOSHII_API_URL}/${APIVersion.V1}`,
  timeout: 60 * 1000,
  withCredentials: true,
  responseType: 'blob',
});

const axiosBlobInstanceV2 = Axios.create({
  baseURL: `${HOSHII_API_URL}/${APIVersion.V2}`,
  timeout: 60 * 1000,
  withCredentials: true,
  responseType: 'blob',
});

axiosInstanceV1.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstanceV1.defaults.headers.patch['Content-Type'] = 'application/json';
axiosInstanceV1.defaults.headers.put['Content-Type'] = 'application/json';

axiosInstanceV2.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstanceV2.defaults.headers.patch['Content-Type'] = 'application/json';
axiosInstanceV2.defaults.headers.put['Content-Type'] = 'application/json';

const retryConfig = {
  retries: 2,
  retryDelay: AxiosRetry.exponentialDelay,
  shouldResetTimeout: true,
  retryCondition: () => true,
};

AxiosRetry(axiosInstanceV1, retryConfig);
AxiosRetry(axiosBlobInstanceV1, retryConfig);

AxiosRetry(axiosInstanceV2, retryConfig);
AxiosRetry(axiosBlobInstanceV2, retryConfig);

const httpDelV1 = async (url: string, config?: AxiosRequestConfigEx) => axiosInstanceV1.delete(url, config);
const httpDelV2 = async (url: string, config?: AxiosRequestConfigEx) => axiosInstanceV2.delete(url, config);

const httpGetV1 = async (url: string, config?: AxiosRequestConfigEx) => axiosInstanceV1.get(url, config);
const httpGetV2 = async (url: string, config?: AxiosRequestConfigEx) => axiosInstanceV2.get(url, config);

const httpPostV1 = async (
  url: string,
  data?: object,
  config?: AxiosRequestConfigEx,
) => axiosInstanceV1.post(url, data, config);
const httpPostV2 = async (
  url: string,
  data?: object,
  config?: AxiosRequestConfigEx,
) => axiosInstanceV2.post(url, data, config);

const httpPatchV1 = async (
  url: string,
  data: object,
  config?: AxiosRequestConfigEx,
) => axiosInstanceV1.patch(url, data, config);
const httpPatchV2 = async (
  url: string,
  data: object,
  config?: AxiosRequestConfigEx,
) => axiosInstanceV2.patch(url, data, config);

const httpPutV1 = async (
  url: string,
  data: object,
  config?: AxiosRequestConfigEx,
) => axiosInstanceV1.put(url, data, config);
const httpPutV2 = async (
  url: string,
  data: object,
  config?: AxiosRequestConfigEx,
) => axiosInstanceV2.put(url, data, config);

const httpRequestV1 = async (config: AxiosRequestConfigEx) => axiosInstanceV1.request(config);
const httpRequestV2 = async (config: AxiosRequestConfigEx) => axiosInstanceV2.request(config);

const httpGetBlobV1 = async (url: string, config?: AxiosRequestConfigEx) => axiosBlobInstanceV1.get(url, config);
const httpGetBlobV2 = async (url: string, config?: AxiosRequestConfigEx) => axiosBlobInstanceV2.get(url, config);
const activeAxiosInterceptors: { [key: string]: number | null } = {};

const removeHttpRequestConfigInterceptor = (key: string) => {
  const id = activeAxiosInterceptors[key];
  if (doesExist(id)) {
    axiosInstanceV1.interceptors.request.eject(activeAxiosInterceptors[key]!);
    axiosInstanceV2.interceptors.request.eject(activeAxiosInterceptors[key]!);
    activeAxiosInterceptors[key] = null;
    console.log(
      `[XHR] Removed ${key} (id ${id}) global http request config interceptor`,
    );
  }
};

const removeHttpResponseInterceptor = (key: string) => {
  const id = activeAxiosInterceptors[key];
  if (doesExist(id)) {
    axiosInstanceV1.interceptors.response.eject(activeAxiosInterceptors[key]!);
    axiosInstanceV2.interceptors.response.eject(activeAxiosInterceptors[key]!);
    activeAxiosInterceptors[key] = null;
    console.log(
      `[XHR] Removed ${key} (id ${id}) global http response interceptor`,
    );
  }
};

const addHttpResponseInterceptor = (
  key: string,
  onFulfilled?: (arg0: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
  onRejected?: (arg0: AxiosError) => any | void,
  options?: AxiosInterceptorOptions,
) => {
  if (doesExist(activeAxiosInterceptors[key])) {
    removeHttpResponseInterceptor(key);
  }

  const id = axiosInstanceV1.interceptors.response.use(
    onFulfilled,
    onRejected,
    options,
  );
  axiosInstanceV2.interceptors.response.use(
    onFulfilled,
    onRejected,
    options,
  );
  activeAxiosInterceptors[key] = id;
  console.log(`[XHR] Added ${key} (id ${id}) global http response interceptor`);
};

const addHttpRequestConfigInterceptor = (
  key: string,
  onFulfilled?: (
    arg0: InternalAxiosRequestConfig,
  ) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>,
  onRejected?: (arg0: AxiosError) => any | void,
  options?: AxiosInterceptorOptions,
) => {
  if (doesExist(activeAxiosInterceptors[key])) {
    removeHttpRequestConfigInterceptor(key);
  }

  const id = axiosInstanceV1.interceptors.request.use(
    onFulfilled,
    onRejected,
    options,
  );
  axiosInstanceV2.interceptors.request.use(
    onFulfilled,
    onRejected,
    options,
  );
  activeAxiosInterceptors[key] = id;
  console.log(
    `[XHR] Added ${key} (id ${id}) global http request config interceptor`,
  );
};

const logXhrError = (error: AxiosError) => {
  if (error.response) {
    console.error(
      `[XHR] Server error ${JSON.stringify(error.response.status)}\n`,
      `Data: ${JSON.stringify(error.response.data)}\n`,
      `Headers: ${JSON.stringify(error.response.headers)}`,
    );
    // @ts-ignore
    return error.response.data?.error;
  }
  if (error.request) {
    console.error(
      '[XHR] Network error\n',
      `Request: ${JSON.stringify(error.request)}`,
    );
    return 'Network error';
  }
  console.error('[XHR] Error', error.message);
  console.info(error.config);
  return 'Application error';
};

export {
  httpDelV1,
  httpDelV2,
  httpGetV1,
  httpGetV2,
  httpPatchV1,
  httpPatchV2,
  httpPostV1,
  httpPostV2,
  httpPutV1,
  httpPutV2,
  httpRequestV1,
  httpRequestV2,
  httpGetBlobV1,
  httpGetBlobV2,
  addHttpRequestConfigInterceptor,
  removeHttpRequestConfigInterceptor,
  addHttpResponseInterceptor,
  removeHttpResponseInterceptor,
  logXhrError,
};
