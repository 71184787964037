import {
  forwardRef, useCallback, useImperativeHandle, useState,
} from 'react';

import { PreviewHTMLModal } from '../../modal/PreviewHTMLModal';
import { Message } from '../../../features/message/models/Message';

interface Props {
  children: React.ReactNode;

  message: Message;
}

const PreviewHTMLModalWrapper = forwardRef(
  ({ children, message }: Props, ref) => {
    const [isHtmlPreviewModalVisible, setIsHtmlPreviewModalVisible] = useState(false);

    const onShowHtmlPreviewButtonClick = useCallback(() => {
      setIsHtmlPreviewModalVisible(true);
    }, []);

    const onHtmlPreviewModalClose = useCallback(() => {
      setIsHtmlPreviewModalVisible(false);
    }, []);

    useImperativeHandle(ref, () => ({
      onShowHtmlPreviewButtonClick,
      setIsHtmlPreviewModalVisible,
    }));

    return (
      <>
        {children}

        {message.context?.html && isHtmlPreviewModalVisible && (
          <PreviewHTMLModal
            html={message.context.html}
            isHtmlPreviewModalVisible={isHtmlPreviewModalVisible}
            onHtmlPreviewModalClose={onHtmlPreviewModalClose}
          />
        )}
      </>
    );
  },
);

export default PreviewHTMLModalWrapper;
