import { Annotation } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/KonvaStage/type';

export const isValidAnnotation = (annotation: Annotation) => (
  annotation.x !== undefined
    && annotation.y !== undefined
    && annotation.width !== undefined
    && annotation.height !== undefined
    && !Number.isNaN(annotation.x)
    && !Number.isNaN(annotation.y)
    && !Number.isNaN(annotation.width)
    && !Number.isNaN(annotation.height)
);
