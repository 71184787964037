import { NavigateFunction } from 'react-router-dom';
import {
  HomeIcon,
  UserGroupIcon,
  UsersIcon,
  BuildingStorefrontIcon,
  BeakerIcon,
  TagIcon,
  ShoppingCartIcon,
  InboxStackIcon,
  CommandLineIcon,
} from '@heroicons/react/24/outline';

import { globalOrderDrafts } from 'state/globalOrderDrafts';
import { observer } from 'mobx-react-lite';
import { globalThreads } from 'state/globalThreads';
import { Fragment } from 'react';
import { ROUTES } from '../../../../config/routes';
import { SidebarItem } from './types';
import IconWithDropdown from './IconWithDropdown';
import IconWithoutDropdown from './IconWithoutDropdown';
import QuickActionIcon from './QuickActionIcon';

interface Props {
  navigate: NavigateFunction;
  pathname: string;
}

const Sidebar = observer(({ navigate, pathname }: Props) => {
  const orderDraftsCount = globalOrderDrafts.count > 99 ? '99+' : globalOrderDrafts.count?.toString();
  const unreadThreadsCount = globalThreads.unreadCount > 99 ? '99+' : globalThreads.unreadCount?.toString();

  const sidebarItems: SidebarItem[] = [
    {
      hoverTitle: 'Home',
      path: ROUTES.HOME,
      icon: HomeIcon,
    },
    // DEV-702: TODO(ntauth): Re-enable once v2 chat endpoints are implemented
    // {
    //   hoverTitle: 'Chats',
    //   path: ROUTES.CHATS,
    //   icon: ChatBubbleLeftRightIcon,
    // },
    {
      hoverTitle: 'Inbox',
      path: ROUTES.INBOX({}),
      basePath: '/inbox',
      icon: InboxStackIcon,
      itemsBadgeCount: unreadThreadsCount,
    },
    {
      hoverTitle: 'Orders',
      path: ROUTES.ORDERS,
      icon: ShoppingCartIcon,
      itemsBadgeCount: orderDraftsCount,
    },
    {
      hoverTitle: 'Products',
      path: ROUTES.PRODUCTS,
      icon: TagIcon,
    },
    {
      hoverTitle: 'Customers',
      path: ROUTES.CUSTOMERS,
      icon: BuildingStorefrontIcon,
    },
    {
      hoverTitle: 'Contacts',
      path: ROUTES.SUBJECTS,
      icon: UsersIcon,
    },
    {
      hoverTitle: 'Team members',
      path: ROUTES.TEAM_MEMBERS,
      icon: UserGroupIcon,
    },
    {
      hoverTitle: 'Instructions',
      path: ROUTES.INSTRUCTIONS,
      icon: BeakerIcon,
    },
    {
      hoverTitle: 'Audit logs',
      icon: CommandLineIcon,
      dropdownItems: [
        {
          hoverTitle: 'ERP synchronization logs',
          path: ROUTES.AUDIT_ERP_SYNC_LOGS,
        },
      ],
    },
  ];

  return (
    <div
      style={{ minWidth: 60, maxWidth: 60 }}
      className="hidden-scrollbar relative flex h-full flex-col overflow-y-auto border-r-[1px] border-solid p-smd shadow-md"
    >
      <nav className="flex flex-1 flex-col items-center gap-smd pt-1">
        <QuickActionIcon navigate={navigate} />

        {
          sidebarItems.map((sidebarItem) => (
            <Fragment key={sidebarItem.hoverTitle}>
              {sidebarItem.dropdownItems?.length > 0 ? (
                <IconWithDropdown
                  sidebarItem={sidebarItem}
                  navigate={navigate}
                />
              ) : (
                <IconWithoutDropdown sidebarItem={sidebarItem} pathname={pathname} />
              )}
            </Fragment>
          ))
        }
      </nav>
    </div>
  );
});

export default Sidebar;
