import { createContext, useContext, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { Group } from 'features/instruction/constants/prompt';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import ErrorsTooltip from './ErrorsTooltip';
import WarningsTooltip from './WarningsTooltip';

const ExpandableContext = createContext<{
  isExpanded: boolean;
  setIsExpanded:(value: boolean) => void;
}>({ isExpanded: false, setIsExpanded: () => {} });

interface Props {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  title: string;
  children: React.ReactNode;
  group: Group;

  className?: string;
  isExpandable?: boolean;
}

const ExpandableCard = ({
  isExpanded, setIsExpanded, group, title, children, className, isExpandable = true,
}: Props) => {
  const { getErrors, getWarnings, order } = useProcessOrderContext();
  const contextValue = useMemo(() => ({ isExpanded, setIsExpanded }), [isExpanded, setIsExpanded]);

  const contextErrors = getErrors(order?.id);
  const contextWarnings = getWarnings(order?.id);

  const errors = Object.values(contextErrors[group] || {}).filter((error) => error !== '');
  const warnings = Object.values(contextWarnings[group] || {}).filter((warning) => warning !== '');
  return (
    <ExpandableContext.Provider value={contextValue}>
      <div className={twMerge(
        'border rounded-lg bg-gray-100 relative block',
        errors.length > 0 && 'border-red-500',
      )}
      >
        <div className="p-4">
          <div className="w-full flex items-center justify-between mb-3">
            <h3 className="font-semibold">{title}</h3>
            <div className="flex gap-2">
              {errors.length > 0 && (
                <ErrorsTooltip errors={errors}>
                  <div className="flex items-center justify-center cursor-pointer rounded-full text-red-500 font-bold text-sm">
                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
                    {errors.length}
                  </div>
                </ErrorsTooltip>
              )}
              {warnings.length > 0 && (
                <WarningsTooltip warnings={warnings}>
                  <div className="flex items-center justify-center cursor-pointer rounded-full text-warning-500 font-bold text-sm">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
                    {warnings.length}
                  </div>
                </WarningsTooltip>
              )}
            </div>
          </div>

          <div className={twMerge('space-y-3 text-sm', className)}>
            {children}
          </div>
        </div>
        {isExpandable && (
          <button
            type="button"
            onClick={() => setIsExpanded(!isExpanded)}
            className="absolute h-5 w-10 bg-gray-100 rounded-b-full border-b left-1/2 -ml-5 -bottom-3.5 flex justify-center transition-transform duration-300"
          >
            <span className={`transform transition-transform duration-300 ${isExpanded ? 'rotate-180' : ''}`}>
              <FontAwesomeIcon icon={faChevronDown} />
            </span>
          </button>
        )}
      </div>
    </ExpandableContext.Provider>
  );
};

const DefaultContent = ({ children }: { children: React.ReactNode }) => (
  <div className="contents">{children}</div>
);

const ExpandedContent = ({ children }: { children: React.ReactNode }) => {
  const { isExpanded } = useContext(ExpandableContext);
  return (
    <div
      className={twMerge(isExpanded ? 'contents' : 'hidden')}
    >
      {children}
    </div>
  );
};

ExpandableCard.DefaultContent = DefaultContent;
ExpandableCard.ExpandedContent = ExpandedContent;

export default ExpandableCard;
