import { BusinessSettings } from '../../../../../models/BusinessSettings';
import Input from '../../../ui/Input';

interface Props {
  businessSettings: BusinessSettings;
}

const Abacus = ({ businessSettings }: Props) => {
  const abacus = businessSettings.erpConnectionSettings.abacus;

  return (
    <div className="grid gap-5 text-sm text-gray-700 lg:py-7.5">
      <Input label="Base URL" value={abacus?.baseUrl} readOnly />
      <Input label="OAuth base URL" value={abacus?.oAuthBaseUrl} readOnly />
      <Input label="Client ID" value={abacus?.clientId} readOnly />
      <Input label="Grant type" value={abacus?.grantType} readOnly />
    </div>
  );
};

export default Abacus;
