import { Business } from '../../../models/Business';

const getAdditionalInfo = (customer: Business): {
  label: string;
  value: string;
}[] => {
  const additionalInfo: {
    label: string;
    value: string;
  }[] = [];

  if (!customer) return additionalInfo;

  if (customer.nameSupplement) {
    additionalInfo.push({
      label: 'name supplement',
      value: customer.nameSupplement,
    });
  }

  if (customer.externalId) {
    additionalInfo.push({
      label: 'id',
      value: customer.externalId,
    });
  }

  if (customer.shortName) {
    additionalInfo.push({
      label: 'short name',
      value: customer.shortName,
    });
  }

  if (customer.address) {
    additionalInfo.push({
      label: 'address',
      value: customer.address,
    });
  }

  if (customer.emails) {
    customer.emails.forEach((email) => {
      additionalInfo.push({
        label: 'email',
        value: email,
      });
    });
  }

  if (customer.phones) {
    customer.phones.forEach((phone) => {
      additionalInfo.push({
        label: 'phone',
        value: phone.number,
      });
    });
  }

  if (customer.customer?.notes) {
    customer.customer.notes.forEach((note) => {
      additionalInfo.push({
        label: 'note',
        value: note.note,
      });
    });
  }

  return additionalInfo;
};

export { getAdditionalInfo };
