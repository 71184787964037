import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { debounce } from 'lodash';

import { httpGetV1 } from 'utils/xhr';
import { GetDocumentsQueryType } from '../../models/GetDocumentsQueryType';
import { globalSseSources } from '../globalSseSources';

export class Threads {
  unreadCount: number = 0;

  isLoading?: boolean = false;

  constructor() {
    makeObservable(this, {
      unreadCount: observable,
      isLoading: observable,
      load: action,
      _onNewThreadSseReceived: action,
    });

    this.load();
  }

  initPersistence = async () => {
    await makePersistable(this, {
      name: 'unread_count',
      properties: [],
      storage: window.localStorage,
    });
  };

  refresh = () => {
    this.load();
  };

  load = async () => {
    runInAction(() => {
      this.isLoading = true;
    });

    const unreadCount = await httpGetV1('/threads', {
      params: {
        unread_only: true,
        query_type: GetDocumentsQueryType.count,
      },
    }).then((response) => response.data.count);

    runInAction(() => {
      this.unreadCount = unreadCount;
      this.isLoading = false;
    });
  };

  markOneAsRead = () => {
    runInAction(() => {
      this.unreadCount = Math.max(this.unreadCount - 1, 0);
    });
  };

  _debouncedLoad = debounce(this.load, 2000);

  addSseSourcesHandler = () => {
    globalSseSources.addSourcesHandler(
      'threads',
      ['new_thread', 'new_message'],
      this._onNewThreadSseReceived,
    );
  };

  // eslint-disable-next-line class-methods-use-this
  removeSseSourcesHandler = () => {
    globalSseSources.removeSourcesHandler('threads');
  };

  _onNewThreadSseReceived = () => {
    this._debouncedLoad();
  };
}
