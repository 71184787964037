import { observer } from 'mobx-react-lite';
import { twMerge } from 'tailwind-merge';
import { Menu } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisH, faInbox, faPencil,
} from '@fortawesome/free-solid-svg-icons';

import { Inbox } from 'models/Inbox';
import { globalInboxes } from 'state/globalInboxes';
import { doesUserHaveInboxEditAccess } from 'utils/accessControll';
import { globalUser } from 'state/globalUser';
import { MessageIntent } from 'features/message/models/Message';
import { toTitleLocaleCase } from 'utils/strings';
import { useThreadsContext } from 'contexts/useThreadsContext';
import { isIntentSelected } from 'features/thread/utils/intent';

interface Props {
  inbox: Inbox;
  setEditingInbox: (inbox: Inbox) => void;
  open: () => void;
}

const intentOptions = [
  { value: 'all', label: 'All' },
  { value: MessageIntent.ORDER, label: toTitleLocaleCase(MessageIntent.ORDER) },
  { value: MessageIntent.OTHER, label: toTitleLocaleCase(MessageIntent.OTHER) },
];

const InboxListItem = observer(({ inbox, setEditingInbox, open }: Props) => {
  const {
    threadsFilter,
    setThreadsFilter,
  } = useThreadsContext();
  const doesUserHaveEditAccess = doesUserHaveInboxEditAccess(inbox, globalUser);

  return (
    <div className="bg-gray-50 rounded-sm">
      <div
        className={twMerge(
          'flex items-center justify-between cursor-pointer px-2 py-1 4xl:px-3 4xl:py-2 rounded-sm group hover:bg-gray-100 transition-colors duration-200',
          globalInboxes.selectedInboxId === inbox.id && 'bg-gray-100',
        )}
        onClick={() => {
          globalInboxes.setSelectedInboxId(inbox.id);
          setThreadsFilter({ ...threadsFilter, intents: [] });
        }}
        onKeyDown={null}
        role="button"
        tabIndex={0}
      >
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faInbox} className="w-4 h-4" />
          <div>{inbox.name}</div>
        </div>

        <Menu shadow="md">
          <Menu.Target>
            <button
              type="button"
              className={twMerge(
                'items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200',
                !doesUserHaveEditAccess && 'hidden',
              )}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon icon={faEllipsisH} className="w-4 h-4" />
            </button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              leftSection={<FontAwesomeIcon icon={faPencil} />}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (!doesUserHaveEditAccess) return;
                setEditingInbox(inbox);
                open();
              }}
            >
              Edit
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>

      <div
        className={twMerge(
          'transition-all duration-300',
          globalInboxes.selectedInboxId !== inbox.id ? 'max-h-0 overflow-hidden' : 'max-h-fit px-2 py-2 space-y-1 4xl:space-y-1.5 4xl:px-3 4xl:py-3',
        )}
      >
        {
          intentOptions.map((option) => (
            <button
              type="button"
              className={twMerge(
                'flex items-center gap-2 rounded-s-full rounded-e-full px-4 py-1 w-full text-sm',
                isIntentSelected(threadsFilter.intents, option.value) && 'bg-primary-600 text-white font-semibold',
              )}
              onClick={() => {
                if (option.value === 'all') {
                  setThreadsFilter({ ...threadsFilter, intents: [] });
                } else {
                  setThreadsFilter({ ...threadsFilter, intents: [option.value] as MessageIntent[] });
                }
              }}
            >
              <span
                className={twMerge(
                  'bg-black w-1.5 h-1.5 rounded-full',
                  isIntentSelected(threadsFilter.intents, option.value) && 'bg-white',
                )}
              />
              <div className="flex justify-between w-full">
                <p>
                  {option.label}
                </p>
              </div>
            </button>
          ))
        }
      </div>
    </div>
  );
});

export default InboxListItem;
