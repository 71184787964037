import { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { httpGetV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';
import { Prompt } from '../../features/instruction/models/Prompt';
import { Pipeline } from '../../features/instruction/types/instruction';

const useFetchPromptByCustomerId = () => {
  const [prompt, setPrompt] = useState<Prompt | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadPrompt = useCallback(
    (
      customerId: string,
      pipeline: Pipeline,
      giveErrorFeedback: boolean = true,
    ): Promise<Prompt> => {
      if (!customerId) return Promise.reject();
      setIsLoading(true);
      return httpGetV1(`/llm/pipelines/${pipeline}/prompts`, {
        params: {
          customer_id: customerId,
        },
      })
        .then((response) => {
          const _prompt = response.data as Prompt;
          const _promptWithUiId: Prompt = {
            ..._prompt,
            boundTypeSpecs: _prompt.boundTypeSpecs.map((typeSpec) => ({
              ...typeSpec,
              fields: typeSpec.fields.map((field) => ({
                ...field,
                uiId: uuidv4(),
              })),
            })),
          };
          setPrompt(_promptWithUiId);
          return _promptWithUiId;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('An error has occured while fetching prompt instructions')(
              error,
            );
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    prompt,
    loadPrompt,
    isLoading,
  };
};

export { useFetchPromptByCustomerId };
