import { useEffect } from 'react';

import { useFetchBusinessSettings } from 'hooks/fetch/useFetchBusinessSettings';
import { useFetchSchemaByTypeRef } from 'hooks/fetch/useFetchSchemaByTypeRef';

import { useBusinessSettingsContext } from 'contexts/useBusinessSettingsContext';
import { useSchemasContext } from 'contexts/useSchemasContext';
import { DragDropProvider } from 'contexts/useDragDropContext';

import LoadingOverlay from 'components/ui/LoadingOverlay';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';

interface Props {
  children: React.ReactNode;
}

const BodyWrapper = ({ children }: Props) => {
  const { schemas, setSchemas } = useSchemasContext();
  const { loadSchema: loadOrderSchema, isLoading: isOrderSchemaLoading } = useFetchSchemaByTypeRef();

  const { setBusinessSettings } = useBusinessSettingsContext();
  const { businessSettings, isLoading: isBusinessSettingsLoading } = useFetchBusinessSettings({});

  const { order, isPromptLoading } = useProcessOrderContext();

  useEffect(() => {
    setBusinessSettings(businessSettings);
  }, [businessSettings, setBusinessSettings]);

  useEffect(() => {
    if (order) {
      order.typeSpecs?.forEach((typeSpec) => {
        if (schemas[typeSpec.typeRef]) {
          return;
        }

        loadOrderSchema(typeSpec.typeRef)
          .then((schema) => {
            setSchemas((_schemas) => ({ ..._schemas, [typeSpec.typeRef]: schema }));
          });
      });
    }
  }, [order, loadOrderSchema, setSchemas, schemas]);

  if (isBusinessSettingsLoading) {
    return (
      <LoadingOverlay visible displayText="Loading business settings" />
    );
  }

  if (isOrderSchemaLoading) {
    return (
      <LoadingOverlay visible displayText="Loading order schema" />
    );
  }

  if (isPromptLoading) {
    return (
      <LoadingOverlay visible displayText="Loading prompt" />
    );
  }

  return (
    <DragDropProvider>
      {children}
    </DragDropProvider>
  );
};

export default BodyWrapper;
