import {
  useCallback, useEffect, useMemo, useRef,
  useState,
} from 'react';
import { Popover } from '@mantine/core';
import { BellIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';
import { reaction } from 'mobx';

import { genericErrorFeedback } from 'utils/errors';
import { twMerge } from 'tailwind-merge';
import { globalLROs } from 'state/globalLROs';
import { globalNotifications } from 'state/globalNotifications';
import { NotificationList } from './NotificationList';
import { LroList } from './LroList';

interface Props {
  showBadge?: boolean;
}

const NotificationBell = observer(({ showBadge = true }: Props) => {
  const [unreadCount, setUnreadCount] = useState(globalNotifications.unreadCount);
  const displayCount = useMemo(() => (unreadCount > 9 ? '9+' : unreadCount?.toString()), [unreadCount]);

  const prevCount = useRef(globalNotifications.unreadCount);
  const badgeRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (unreadCount !== prevCount.current && badgeRef.current) {
      badgeRef.current.classList.add('scale-125');

      setTimeout(() => {
        badgeRef.current?.classList.remove('scale-125');
      }, 200);

      prevCount.current = unreadCount;
    }
  }, [unreadCount]);

  reaction(
    () => globalNotifications.unreadCount,
    () => {
      setUnreadCount(globalNotifications.unreadCount);
    },
  );

  const handleMarkAllAsRead = useCallback(() => {
    try {
      globalNotifications.markAsRead([]);
    } catch (error) {
      genericErrorFeedback('Failed to mark all notifications as read')(error);
    }
  }, []);

  return (
    <Popover
      withArrow
    >
      <Popover.Target>
        {
          globalLROs.pendingCount > 0 ? (
            <div className="relative flex items-center justify-center w-10 h-10 cursor-pointer">
              {/* Outer spinning circle */}
              <div className="absolute w-10 h-10 border-2 border-blue-200 rounded-full animate-spin border-t-blue-500" />

              {/* Inner static bell icon */}
              <div className="absolute">
                <BellIcon
                  width={24}
                  height={24}
                  className="text-[rgba(107,114,128,1)]"
                />
              </div>
            </div>
          ) : (
            <div className="flex cursor-pointer flex-row items-center gap-smd rounded-md p-smd text-sm leading-6 text-gray-600 hover:bg-gray-50">
              <div className="relative">
                <BellIcon
                  width={24}
                  height={24}
                  className="text-[rgba(107,114,128,1)]"
                />
                {showBadge && (
                <span
                  ref={badgeRef}
                  className={twMerge(
                    'absolute -right-2 -top-2 min-w-[18px] flex items-center justify-center',
                    'rounded-full bg-danger-500 px-1 text-xs text-white transition-transform duration-200',
                  )}
                >
                  { displayCount }
                </span>
                )}
              </div>
            </div>
          )
        }
      </Popover.Target>

      <Popover.Dropdown>
        <div className="w-[300px] 4xl:w-[500px] flex-auto overflow-hidden text-sm leading-6 space-y-2">
          <div className="flex items-center justify-between py-1">
            <h3 className="font-semibold text-gray-900">Notifications</h3>
            <button
              type="button"
              onClick={handleMarkAllAsRead}
              className="text-primary-600 hover:text-primary-700"
            >
              Mark all as read
            </button>
          </div>

          <div className="max-h-[50vh] overflow-y-auto divide-y divide-gray-200 border-t border-t-gray-300 px-2 -mx-2">
            <LroList />
            <NotificationList />
          </div>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
});

export default NotificationBell;
