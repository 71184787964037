import { useEffect } from 'react';
import { Subject } from 'features/user/models/User';
import { Phone, PhoneType } from 'models/Phone';
import { useFormContext } from '../../../../contexts/useFormContext';
import { FormInput } from '../../../FormInput';
import { FormInputPhones } from '../../../FormInputPhones';

interface Props {
  subject: Subject;
}

const _Body = ({ subject }: Props) => {
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormContext<{
    externalId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }>();

  useEffect(() => {
    if (subject) {
      setValues({
        externalId: subject.externalId,
        firstName: subject.firstName,
        lastName: subject.lastName,
        email: subject.email,
        phone: subject.phone,
      });
    }
  }, [subject, setValues]);

  return (
    <div className="flex flex-col gap-5 px-1 py-3">
      <FormInput
        disabled
        field="externalId"
        label="External ID"
        value={values.externalId}
        error={errors.externalId}
        touched={touched.externalId}
        onChange={handleChange('externalId')}
        onBlur={handleBlur('externalId')}
      />

      <FormInput
        required
        field="firstName"
        label="First Name"
        value={values.firstName}
        error={errors.firstName}
        touched={touched.firstName}
        onChange={handleChange('firstName')}
        onBlur={handleBlur('firstName')}
      />

      <FormInput
        required
        field="lastName"
        label="Last Name"
        value={values.lastName}
        error={errors.lastName}
        touched={touched.lastName}
        onChange={handleChange('lastName')}
        onBlur={handleBlur('lastName')}
      />

      <FormInput
        field="email"
        label="Email"
        value={values.email}
        error={errors.email}
        touched={touched.email}
        onChange={handleChange('email')}
        onBlur={handleBlur('email')}
      />

      <FormInputPhones
        field="phone"
        label="Phone"
        values={[{ number: values.phone, type: PhoneType.Unknown, isDefault: true } as Phone]}
        errors={[errors.phone]}
        touched={[{ number: touched.phone, type: touched.phone, isDefault: touched.phone }]}
        setFieldValue={setFieldValue}
        onBlur={handleBlur('phone')}
      />
    </div>
  );
};

export { _Body };
