import { useState, useCallback, useRef } from 'react';
import { httpGetV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';
import { TopTierCustomer } from 'models/Analytics';

const useFetchTopTierCustomers = () => {
  const [topTierCustomers, setTopTierCustomers] = useState<TopTierCustomer[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const cursorRef = useRef<string | null>(null);

  const loadTopTierCustomers = useCallback(
    (
      period: 'daily' | 'weekly' | 'monthly',
      metric: 'total_products_sold' | 'total_revenue',
      limit: number = 10,
      giveErrorFeedback: boolean = true,
    ): Promise<TopTierCustomer[]> => {
      if (!period) return Promise.reject(new Error('Period is required'));
      setIsLoading(true);

      return httpGetV1('/businesses/me/customers/analytics/sales', {
        params: {
          period,
          metric,
          limit,
        },
        classType: TopTierCustomer,
      })
        .then((response) => {
          cursorRef.current = response?.data?.cursor;
          setTopTierCustomers(response?.data?.result || []);
          return response?.data?.result;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('An error has occured while fetching customers statistics')(
              error,
            );
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    topTierCustomers,
    loadTopTierCustomers,
    isLoading,
  };
};

export { useFetchTopTierCustomers };
