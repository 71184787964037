import { ClassConstructor, Expose } from 'class-transformer';
import { plainToInstance } from 'utils/mapping';
import { Buffer } from 'buffer';

class Event {
  @Expose({ name: 'id' })
    id: string;

  @Expose({ name: 'sub_id' })
    subId: string;

  @Expose({ name: 'data' })
    data: string;

  @Expose({ name: 'type' })
    type: string;

  @Expose({ name: 'sub_type' })
    subType: string;

  @Expose({ name: 'topic' })
    topic: string;

  @Expose({ name: 'business_id' })
    businessId: string;

  @Expose({ name: 'user_id' })
    userId: string;

  @Expose({ name: 'triggered_by_user_id' })
    triggeredByUserId: string;

  @Expose({ name: 'is_business_wide' })
    isBusinessWide: boolean;

  @Expose({ name: 'created_at' })
    createdAt: string;

  @Expose({ name: 'consumed_at' })
    consumedAt: string;

  getDataInstanceOf<T>(cls: ClassConstructor<T>) {
    const decodedData = Buffer.from(this.data, 'base64').toString('utf-8');
    return plainToInstance(cls, JSON.parse(decodedData));
  }
}

const newEventFromMessageEvent = (msgEvent: MessageEvent) => {
  const decodedData = Buffer.from(msgEvent.data).toString('utf-8');
  const unmarshaledData = JSON.parse(decodedData);
  return plainToInstance(Event, unmarshaledData);
};

export { Event, newEventFromMessageEvent };
