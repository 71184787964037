import { useNavigate } from 'react-router-dom';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { observer } from 'mobx-react-lite';

import { Page } from 'components/layout/Page/Page';
import { OrderProvider } from 'features/order/contexts/useOrderContext';
import { PlayRecordingProvider } from 'contexts/usePlayRecordingIndex';
import { TeamMembersProvider } from 'contexts/useTeamMembersContext';
import {
  MessageProviderType,
  MessagesProvider,
} from 'contexts/useMessagesContext';
import { BusinessSettingsProvider } from 'contexts/useBusinessSettingsContext';
import { SchemasProvider } from 'contexts/useSchemasContext';
import { ProcessOrderDrafts } from 'features/order/components/process-order-draft';
import { ROUTES } from 'config/routes';
import { ProcessOrderProvider } from 'features/order/contexts/useProcessOrderContext';
import { useFetchOrder } from 'features/order/api/useFetchOrder';
import { isZeroId } from 'utils/objectId';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mantine/core';
import { Order, OrderStatus } from '../models/Order';
import { useFetchOrdersByGroupId } from '../api/useFetchOrdersByGroupId';

interface Props {
  orderId: string;
}

const getMessageReferenceId = (order: Order | null) => {
  if (order?.groupId && !isZeroId(order.groupId)) {
    return order.groupId;
  }

  return order?.id;
};

const ProcessOrderDraftByIdPage = observer(({ orderId }: Props) => {
  const navigate = useNavigate();

  const [order, setOrder] = useState<Order | null>(null);
  const [messageReferenceId, setMessageReferenceId] = useState<string | null>(null);

  // TODO(chihirokuya): This is just a temporary workaround. Refine this
  const isFirstFetchRef = useRef(true);

  const { isLoading: isOrderLoading, loadOrder } = useFetchOrder({
    orderId,
    preventFetch: false,
  });

  const {
    isLoading: isOrdersByGroupIdLoading,
    loadOrdersByGroupId,
  } = useFetchOrdersByGroupId();

  const onOrderDraftProcessed = useCallback(() => {
    navigate(ROUTES.ORDER_BY_ID(orderId));
  }, [navigate, orderId]);

  const onOrderDraftRetried = useCallback(async (retriedOrderId: string) => {
    navigate(ROUTES.PROCESS_ORDER_DRAFT_BY_ID(retriedOrderId));
  }, [navigate]);

  useEffect(() => {
    if (!isFirstFetchRef.current) {
      return;
    }

    isFirstFetchRef.current = false;

    loadOrder()
      .then((o) => {
        setOrder(o);
        setMessageReferenceId(getMessageReferenceId(o));
      })
      .catch(() => {
        loadOrdersByGroupId(orderId)
          .then((orders) => {
            setOrder(orders[0]);
          });
      })
      .finally(() => {
      });
  }, [loadOrdersByGroupId, orderId, loadOrder]);

  useEffect(() => {
    isFirstFetchRef.current = true;
  }, [orderId]);

  if (!isOrderLoading && !isOrdersByGroupIdLoading && !order) {
    return (
      <Page isLoading={false}>
        <div className="flex-1 flex justify-center items-center h-full">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-lg p-6">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                  Order not found
                </h1>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    This order might be already processed or deleted.
                    {' '}
                    Try to access order details page from the button below.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
              <Button
                onClick={() => {
                  navigate(ROUTES.ORDER_BY_ID(orderId));
                }}
                rightSection={<FontAwesomeIcon icon={faArrowRight} />}
              >
                Go to order details
              </Button>
            </div>
          </div>
        </div>
      </Page>
    );
  }

  if (order && order.status !== OrderStatus.New) {
    return (
      <Page isLoading={false}>
        <div className="flex-1 flex justify-center items-center h-full">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-lg p-6">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-green-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                  Already processed
                </h1>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    This order has already been processed.
                    {' '}
                    You can access the order details page from the button below.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
              <Button
                onClick={() => {
                  navigate(ROUTES.ORDER_BY_ID(orderId));
                }}
                rightSection={<FontAwesomeIcon icon={faArrowRight} />}
              >
                Go to order details
              </Button>
            </div>
          </div>
        </div>
      </Page>
    );
  }

  return (
    <Page isLoading={false} contentWithBorder>
      <PlayRecordingProvider>
        <MessagesProvider
          providerType={MessageProviderType.BY_REFERENCE_ID}
          referenceId={messageReferenceId}
        >
          <BusinessSettingsProvider>
            <OrderProvider>
              <SchemasProvider>
                <ProcessOrderProvider onOrderDraftProcessed={onOrderDraftProcessed}>
                  <TeamMembersProvider>
                    <ProcessOrderDrafts
                      order={order}
                      isOrderLoading={isOrderLoading}
                      onOrderRetried={onOrderDraftRetried}
                    />
                  </TeamMembersProvider>
                </ProcessOrderProvider>
              </SchemasProvider>
            </OrderProvider>
          </BusinessSettingsProvider>
        </MessagesProvider>
      </PlayRecordingProvider>
    </Page>
  );
});

export default ProcessOrderDraftByIdPage;
