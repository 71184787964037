import { useEffect, useMemo } from 'react';

import { Message } from 'features/message/models/Message';
import { groupKeywordsByPath } from 'features/instruction/utils/prompt';
import { AudioKeyword, TextKeyword } from 'models/Workflow';
import TextHighlighter from 'features/message/components/utils/TextHighlighter';
import { useKeywordContext } from 'features/order/contexts/useKeywordContext';
import { isValidKeyword } from 'features/order/utils/keyword';

interface Props {
  message: Message;
}

const getKeywords = (message: Message) => {
  let keywords: (AudioKeyword | TextKeyword)[] = [];
  if (message.context?.workflowOrder?.audioKeywords?.length > 0) {
    keywords = message.context?.workflowOrder?.audioKeywords;
  } else if (message.context?.workflowOrder?.keywords?.length > 0) {
    keywords = message.context?.workflowOrder?.keywords;
  }
  return keywords;
};

const OrderBody = ({ message }: Props) => {
  const { toZoomKeyword, setToZoomKeyword } = useKeywordContext();

  const messageText = useMemo(() => {
    if (message.context?.workflowOrder?.audioTranscript?.length > 0) {
      return message.context?.workflowOrder?.audioTranscript;
    }
    return message.message;
  }, [message.context, message.message]);

  const keywords = useMemo(() => {
    let keywords_ = getKeywords(message)
      .filter((kw) => !['order_drafts.*.products.*.quantity', 'order_drafts.*.products.*.unit'].includes(kw.path));
    const groupedKeywords = groupKeywordsByPath(keywords_);
    keywords_ = groupedKeywords.Product || [];
    keywords_.sort((a, b) => a.offset - b.offset);

    return keywords_;
  }, [message]);

  useEffect(() => {
    if (isValidKeyword(toZoomKeyword)) {
      const kw = `${toZoomKeyword.fieldPath}${toZoomKeyword.fieldIds?.[0] && `-${toZoomKeyword.fieldIds?.[0]}`}`;
      if (kw) {
        const toScrollElm = document.querySelector(`[data-keyword-id="${kw}"]`);
        if (toScrollElm) {
          toScrollElm.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }

      setToZoomKeyword(null);
    }
  }, [toZoomKeyword, setToZoomKeyword]);

  if (!messageText) return null;

  return (
    <TextHighlighter
      fullText={messageText}
      keywords={keywords}
      messageId={message?.id}
    />
  );
};

export default OrderBody;
