import { CustomerSelect } from 'features/customer/components/CustomerSelect';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { Group, ORDER_DRAFT_CREATED_BY_PATH } from 'features/instruction/constants/prompt';

import { useCallback } from 'react';
import { ExpandableCard, DynamicField, FieldRenderer } from './components';
import { FieldPathWrapper } from '../../utils';

interface Props {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

const WrappedCustomerSelect = FieldPathWrapper(CustomerSelect);
const WrappedAddressField = FieldPathWrapper(DynamicField);

const CustomerDetails = ({ isExpanded, setIsExpanded }: Props) => {
  const {
    order,
    schema,
    customerFields: defaultCustomerFieldSpecs,
    addError,
  } = useProcessOrderContext();

  const customerFieldSpecs = defaultCustomerFieldSpecs.filter((field) => !field.path.includes('address'));

  const defaultFieldSpecs = customerFieldSpecs.slice(0, 3);
  const expandedFieldSpecs = customerFieldSpecs.slice(3);

  const setCustomerSelectError = useCallback((error: string) => (
    addError(order?.id, Group.Customer, ORDER_DRAFT_CREATED_BY_PATH, error)
  ), [addError, order?.id]);

  return (
    <ExpandableCard
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      title="Customer Details"
      group={Group.Customer}
      className="grid grid-cols-[auto_1fr] gap-2 items-center"
      isExpandable={expandedFieldSpecs.length > 0}
    >
      <ExpandableCard.DefaultContent>
        <>
          <div className="col-span-2">
            <WrappedCustomerSelect
              fieldPath=""
              setError={setCustomerSelectError}
              showLabel={false}
              width="max(600px, 100%)"
              position="bottom-end"
            />
          </div>
          {
            defaultFieldSpecs?.map((fieldSpec) => (
              <FieldRenderer
                key={fieldSpec.path}
                group={Group.Customer}
                fieldPath={fieldSpec.path}
                wrapperClassName="contents"
                schema={schema}
                fieldSpec={fieldSpec}
                checkMatchStatus
              />
            ))
          }
          {
            order?.customer?.address && (
              <WrappedAddressField
                fieldPath=""
                type="text"
                label="Delivery Address"
                wrapperClassName="contents"
                value={order?.customer?.address}
                readOnly
              />
            )
          }
        </>
      </ExpandableCard.DefaultContent>
      <ExpandableCard.ExpandedContent>
        {
          expandedFieldSpecs?.map((fieldSpec) => (
            <FieldRenderer
              key={fieldSpec.path}
              group={Group.Customer}
              fieldPath={fieldSpec.path}
              wrapperClassName="contents"
              schema={schema}
              fieldSpec={fieldSpec}
              checkMatchStatus
            />
          ))
        }
      </ExpandableCard.ExpandedContent>
    </ExpandableCard>
  );
};

export default CustomerDetails;
