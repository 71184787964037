import { useEffect, useState } from 'react';

import { Prompt } from 'features/instruction/models/Prompt';
import { Order } from 'features/order/models/Order';
import { useFetchPromptByCustomerId } from 'hooks/fetch/useFetchPromptByCustomerId';
import { Pipeline } from 'features/instruction/types/instruction';

type UsePromptReturnType = {
  isLoading: boolean;
};

interface UsePromptProps {
  order: Order;
  updateOrderByFn: (updateFn: (currentOrder: Order) => Order) => void;
}

export const usePrompt = ({ order, updateOrderByFn }: UsePromptProps): UsePromptReturnType => {
  const { isLoading, loadPrompt } = useFetchPromptByCustomerId();

  const [promptRecord, setPromptRecord] = useState<Record<string, Prompt>>({}); // customerId -> prompt

  useEffect(() => {
    if (!order?.customer?.id) return;

    const prompt = promptRecord[order.customer.id];
    if (prompt) {
      updateOrderByFn((currentOrder) => ({
        ...currentOrder,
        typeSpecs: prompt.boundTypeSpecs,
        promptId: prompt.id,
      }));
      return;
    }

    loadPrompt(order.customer.id, Pipeline.ORDER)
      .then((_prompt) => {
        setPromptRecord((prevPromptRecord) => ({
          ...prevPromptRecord,
          [order.customer.id]: _prompt,
        }));

        updateOrderByFn((currentOrder) => ({
          ...currentOrder,
          typeSpecs: _prompt.boundTypeSpecs,
          promptId: _prompt.id,
        }));
      });
  }, [loadPrompt, order?.customer?.id, updateOrderByFn, promptRecord]);

  return { isLoading };
};
