import {
  Button, Combobox, ComboboxProps, Loader, useCombobox,
} from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { useFetchUsers } from 'features/user/apis/useFetchUsers';
import { User } from 'features/user/models/User';
import { getUserName } from 'features/user/utils/user';
import { useState } from 'react';

interface Props extends ComboboxProps {
  onSelect: (user: User) => void;
  toExcludeIds?: string[];
}

const TeammemberSelectButton = ({ onSelect, toExcludeIds, ...props }: Props) => {
  const { users, isLoading, loadUsers } = useFetchUsers();

  const [search, setSearch] = useState('');

  const performSearch = useDebouncedCallback((val: string) => {
    loadUsers({ searchValue: val });
  }, 200);

  const options = users
    .filter((user) => !toExcludeIds?.includes(user.id))
    .map((user) => (
      <Combobox.Option value={user.id}>
        {getUserName(user)}
      </Combobox.Option>
    ));

  const combobox = useCombobox({
    onDropdownOpen: () => users.length === 0 && loadUsers({}),
  });

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        onSelect(users.find((user) => user.id === val));
        combobox.closeDropdown();
      }}
      shadow="md"
      position="bottom"
      {...props}
    >
      <Combobox.Target>
        <div className="flex justify-end mb-2">
          <Button size="xs" onClick={() => combobox.toggleDropdown()}>
            Add Participant
          </Button>
        </div>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Search
          value={search}
          onChange={(event) => {
            setSearch(event.currentTarget.value);
            performSearch(event.currentTarget.value);
          }}
          placeholder="Search groceries"
          rightSection={isLoading ? <Loader size="xs" /> : null}
        />
        <Combobox.Options>
          {options.length > 0 ? options : <Combobox.Empty>Nothing found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default TeammemberSelectButton;
