import { NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from '@mantine/core';
import { SidebarItem } from './types';

interface Props {
  sidebarItem: SidebarItem;
  pathname: string;
}

const isCurrentPath = (sidebarItem: SidebarItem, pathname: string) => (sidebarItem.path === '/' && pathname === '/')
|| (sidebarItem.path !== '/'
  && (pathname.startsWith(sidebarItem.path) || pathname.startsWith(sidebarItem.basePath)));

const IconWithoutDropdown = ({ sidebarItem, pathname }: Props) => (
  <Tooltip
    label={sidebarItem.hoverTitle}
    transitionProps={{ transition: 'scale-x', duration: 200 }}
    bg="white"
    styles={{
      tooltip: {
        color: 'black',
        border: '1.5px solid #E8E8E8',
        boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
      },
    }}
    position="right"
    key={`sidebar-${sidebarItem.path}-${sidebarItem.hoverTitle}`}
  >
    <NavLink
      to={sidebarItem.path}
            // className={drawerButtonStyle(sidebarItem.path)}
      className={twMerge(
        'flex w-full flex-row items-center justify-center gap-smd rounded-sm p-smd text-sm text-gray-600',
        isCurrentPath(sidebarItem, pathname)
          ? 'bg-[#f0f0f0]'
          : 'hover:bg-[#f0f0f0]',
      )}
    >
      <div className="relative">
        <sidebarItem.icon
          height={22}
          width={22}
          className="text-[rgb(87,83,78)]"
        />
        {sidebarItem.itemsBadgeCount && (
          <span className="absolute -right-2/3 -top-2 flex min-w-[18px] items-center justify-center rounded-full bg-danger-500 px-1 text-xs text-white transition-transform duration-200">
            {sidebarItem.itemsBadgeCount}
          </span>
        )}
      </div>
    </NavLink>
  </Tooltip>
);

export default IconWithoutDropdown;
