import { useMemo } from 'react';

import LoadingOverlay from 'components/ui/LoadingOverlay';
import { OrderMessages } from 'features/order/components/process-order-draft/OrderMessages';
import { Message } from 'features/message/models/Message';
import { Business } from 'models/Business';
import { User } from 'features/user/models/User';
import { ProcessMessagePanel } from './process-message-panel';

interface Props {
  messages: Message[];
  customer?: Business;
  userSentBy?: User;
  isMessagesLoading: boolean;
  isCustomerLoading: boolean;
  isMessageRetrying: boolean;
  onOpenRetryWorkflowSidebarButtonClick?: () => void;
}

const Content = ({
  messages,
  customer,
  userSentBy,
  isMessagesLoading,
  isCustomerLoading,
  isMessageRetrying,
  onOpenRetryWorkflowSidebarButtonClick,
}: Props) => {
  const isLoading = isMessagesLoading || isCustomerLoading || isMessageRetrying;

  const message = useMemo(() => messages[0], [messages]);

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <div className="relative flex h-full flex-1 overflow-hidden">
      <div className="max-[50%] flex h-full min-w-[50%] flex-col overflow-hidden border-r border-blue-gray-50">
        {onOpenRetryWorkflowSidebarButtonClick && (
        <div className="relative flex w-full border-b bg-white px-lg py-1 text-sm">
          <p className="px-lg text-blue-gray-200">
            Is this message not processed properly?
            <button
              type="button"
              className="pl-sm text-primary-500"
              onClick={onOpenRetryWorkflowSidebarButtonClick}
            >
              Retry
            </button>
          </p>
        </div>
        )}
        <OrderMessages messages={messages} customer={customer} subject={userSentBy} />
      </div>
      <div className="max-[50%] relative flex h-full min-w-[50%] items-center justify-center text-center">
        <ProcessMessagePanel message={message} />
      </div>
    </div>
  );
};

export default Content;
