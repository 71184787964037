import { NumberInput, Popover, Button } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { FieldSpec } from 'features/instruction/models/Schema';
import { ProductWithQuantity } from 'features/order/models/Order';
import { QuantityConversionPopupContent } from 'features/product/types/product';
import { FieldPathWrapper } from '../utils';

const WrappedNumberInput = FieldPathWrapper(NumberInput);

interface Props {
  productQuantityField: FieldSpec;
  product: ProductWithQuantity;
  onProductQuantityChange: (value: number) => void;

  quantityPopupContent: QuantityConversionPopupContent | null;
  onQuantityPopupCanceled: () => void;
  onQuantityPopupConfirmed: () => void;

  isInCollapsedContent?: boolean;
  label?: string;
}

const ProductQuantityInputWithPopup = ({
  productQuantityField, product,
  quantityPopupContent, onQuantityPopupCanceled, onQuantityPopupConfirmed, onProductQuantityChange,
  isInCollapsedContent, label,
}: Props) => (
  <Popover
    opened={!!quantityPopupContent}
    position="top"
    width={350}
    shadow="md"
    withArrow
  >
    <Popover.Target>
      {/* Here, span is required to make forwardRef work */}
      <span>
        <WrappedNumberInput
          fieldPath={productQuantityField?.path}
          fieldId={product.positionId}
          isProductField
          value={product.quantity}
          error={product.quantity <= 0 ? 'Invalid quantity' : ''}
          min={0}
          label={label}
          miw={isInCollapsedContent && 50}
          maw={isInCollapsedContent && 80}
          hideControls
          className="relative"
          styles={{
            error: {
              position: 'absolute',
            },
            input: {
              borderRadius: isInCollapsedContent ? 'var(--mantine-radius-md) 0 0 var(--mantine-radius-md)' : '',
            },
          }}
          onChange={onProductQuantityChange}
        />
      </span>
    </Popover.Target>
    <Popover.Dropdown onClick={(e) => e.stopPropagation()}>
      {/* Title */}
      <div className="flex items-center gap-1">
        <FontAwesomeIcon icon={faArrowRightArrowLeft} className="text-primary-500" />
        <h1 className="text-lg font-bold">
          Confirm Quantity Conversion
        </h1>
      </div>
      <div className="flex justify-center items-center gap-1 py-1">
        {quantityPopupContent?.prevQuantity}
        <FontAwesomeIcon icon={faArrowRight} />
        {quantityPopupContent?.newQuantity}
      </div>
      <p className="text-sm text-gray-700">
        Would you like to convert this quantity based on the unit change?
      </p>
      <div className="flex justify-end pt-1">
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={onQuantityPopupCanceled}
          >
            Cancel
          </Button>
          <Button
            onClick={onQuantityPopupConfirmed}
            size="sm"
          >
            Confirm
          </Button>
        </div>
      </div>
    </Popover.Dropdown>
  </Popover>
);

export default ProductQuantityInputWithPopup;
