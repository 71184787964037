import { Checkbox } from '@mantine/core';
import { Label, Modal } from 'flowbite-react';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { doesExist } from 'utils/comparison';
import { genericErrorFeedback } from 'utils/errors';
import { httpGetV1, httpPostV1 } from 'utils/xhr';
import { Button } from '../ui/Button';
import { FlashCard } from '../ui/FlashCard';
import { LoadingIndicator } from '../LoadingIndicator';
import { User } from '../../features/user/models/User';
import { globalAlertData } from '../../state/globalAlertData';
import { Select, SelectOption } from '../ui/Select';

interface Props {
  messageId?: string;
  isOpen?: boolean;
  onClose: () => void;
  onMessageMoved: (byCopy: boolean) => void;
}

const ChatMoveMessageModal = ({
  messageId,
  isOpen,
  onClose,
  onMessageMoved: onReconciliationDone,
}: Props) => {
  const [moveByCopy, setMoveByCopy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<SelectOption>(() => null);
  const [users, setUsers] = useState<User[]>([]);
  const userPaginationCursor = useRef();

  const getUsers = useCallback(async (searchValue?: string) => {
    setIsLoading(true);
    if (doesExist(searchValue)) {
      userPaginationCursor.current = null;
    }
    const response = await httpGetV1('/businesses/me/users', {
      params: {
        limit: 10,
        search_query: doesExist(searchValue, '') ? searchValue : null,
        cursor: userPaginationCursor.current,
      },
    });
    if (response.status === 200) {
      setUsers(response.data.result || []);
      userPaginationCursor.current = response.data.cursor;
    }
    setIsLoading(false);
  }, []);

  const userOptions = useMemo(
    () => users.map((u) => ({
      label: `${u.firstName} ${u.lastName}`,
      value: u.id,
    })),
    [users],
  );

  const onUserSelectChange = useCallback(
    (selected: { value: string; label: string }) => {
      setSelectedUser(selected);
    },
    [setSelectedUser],
  );

  const onMoveByCopyCheckboxChange = useCallback(() => {
    setMoveByCopy((mc) => !mc);
  }, []);

  const onUsersSelectOptionsScrolledEnd = useCallback(() => {
    getUsers();
  }, [getUsers]);

  const onConfirmMoveMessageClick = useCallback(() => {
    if (messageId && selectedUser) {
      setIsLoading(true);
      httpPostV1('/chat/messages/move', {
        ids: [messageId],
        toUserId: selectedUser.value,
        byCopy: moveByCopy,
      })
        .then(() => {
          onReconciliationDone(moveByCopy);
          globalAlertData.create('Messages moved successfully');
        })
        .catch(genericErrorFeedback('Error moving messages'))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [messageId, moveByCopy, onReconciliationDone, selectedUser]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <Modal
      show={isOpen}
      onClose={onClose}
      size="xl"
      position="top-center"
      dismissible
    >
      <Modal.Header>
        <div className="flex items-center gap-smd">
          Move selected message
          <LoadingIndicator isLoading={isLoading} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="relative flex flex-col gap-xl">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="user" value="Select a user of your business" />
            </div>
            <Select
              selectedOption={selectedUser}
              options={userOptions}
              isLoading={isLoading}
              onSelectionChange={onUserSelectChange}
              onScrolledEnd={onUsersSelectOptionsScrolledEnd}
            />
          </div>
          <div>
            <Checkbox
              label="Move message by copy"
              checked={moveByCopy}
              onChange={onMoveByCopyCheckboxChange}
              description="Moving a message by copy means a new instance of the same message will be created for the selected user and you still have access to the original message in the original chat."
            />
          </div>
          {!moveByCopy ? (
            <FlashCard
              theme="warning"
              title="Message will be moved permanently"
              description="The selected message and related order will be moved to the selected user permanently. Please select carefully."
            />
          ) : null}
          <div className="flex w-full justify-end border-t border-solid pt-4">
            <Button
              title="Confirm"
              onClick={onConfirmMoveMessageClick}
              disabled={isLoading || !selectedUser}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { ChatMoveMessageModal };
