import { useState, useEffect, useRef } from 'react';
import TypeWriter from 'typewriter-effect';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import { Tag } from 'components/common/Tag';
import { TagVariant } from '../../../../../types/tag';

import { AdamMessageWrapper } from '../../../../wrapper/AdamMessageWrapper';
import { useInstructionContext } from '../../../../../contexts/useInstructionContext';
import { Workflow } from '../../../../../features/instruction/types/instruction';

import cartLight from '../../../../../assets/utils/cart-light.svg';
import cartDark from '../../../../../assets/utils/cart-dark.svg';
import docLight from '../../../../../assets/utils/doc-light.svg';
import docDark from '../../../../../assets/utils/doc-dark.svg';

interface Props {
  onComplete: (workflow: Workflow) => void;
}

const landingText = `Welcome back to <strong>Instruction</strong> page!<br/><br/>
Please choose a workflow to create or edit an instruction.
`;

const buttonContainerVariants = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const buttonVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  show: { opacity: 1, scale: 1 },
};

const workflows = [
  {
    category: Workflow.ORDER,
    icon: cartLight,
    iconDark: cartDark,
    text: 'Order',
    soon: false,
  },
  {
    category: Workflow.SUPPLIER_NOTE,
    icon: docLight,
    iconDark: docDark,
    text: 'Supplier note',
    soon: true,
  },
];

const ChooseWorkflow = ({ onComplete }: Props) => {
  const { selectedWorkflow } = useInstructionContext();
  const [landingTextFinished, setLandingTextFinished] = useState(false);
  const endOfTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (landingTextFinished && endOfTextRef.current) {
        endOfTextRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }, [landingTextFinished]);

  return (
    <AdamMessageWrapper>
      <TypeWriter
        onInit={(typewriter) => {
          typewriter
            .changeDelay(15)
            .typeString(landingText)
            .callFunction(() => {
              document
                .querySelector('.Typewriter__cursor')
                ?.classList.add('hidden');
              setLandingTextFinished(true);
            })
            .start();
        }}
      />
      {landingTextFinished && (
        <motion.div
          className="flex space-x-3 pt-3"
          variants={buttonContainerVariants}
          initial="hidden"
          animate="show"
        >
          {workflows.map((workflow) => (
            <motion.div
              key={workflow.category}
              className="flex flex-col items-center space-y-2"
              variants={buttonVariants}
            >
              <button
                type="button"
                className={twMerge(
                  'group relative flex flex-col items-center justify-center rounded-md border border-gray-200 bg-white px-2 py-3 shadow-sm hover:border-primary-200 hover:shadow-md',
                  selectedWorkflow === workflow.category && 'border-primary-200',
                )}
                onClick={() => {
                  if (workflow.soon) {
                    return;
                  }

                  onComplete(workflow.category);
                }}
              >
                {workflow.soon && (
                  <Tag
                    className="right-2 top-2"
                    isTagLoading={false}
                    tagTitle="soon"
                    tagVariant={TagVariant.GRAY}
                    hideCircle
                  />
                )}
                <img
                  src={workflow.icon}
                  alt={workflow.text}
                  className={twMerge(
                    'h-[150px] w-[150px] group-hover:hidden',
                    selectedWorkflow === workflow.category && 'hidden',
                  )}
                />
                <img
                  src={workflow.iconDark}
                  alt={workflow.text}
                  className={twMerge(
                    'hidden h-[150px] w-[150px] group-hover:block',
                    selectedWorkflow === workflow.category && 'block',
                  )}
                />
                <p
                  className={twMerge(
                    'text-sm font-semibold text-gray-300',
                    selectedWorkflow === workflow.category
                      ? 'text-primary-500'
                      : 'group-hover:text-primary-500',
                  )}
                >
                  {workflow.text}
                </p>
              </button>
            </motion.div>
          ))}
        </motion.div>
      )}
      <div ref={endOfTextRef} />
    </AdamMessageWrapper>
  );
};

export default ChooseWorkflow;
