import { useCallback } from 'react';

import { FieldSpec, Schema, StandardFieldType } from 'features/instruction/models/Schema';
import { Product, Unit } from 'features/product/models/Product';
import { ProductWithQuantity } from 'features/order/models/Order';

import { PRODUCTS_MODEL_PATH } from 'features/instruction/constants/prompt';
import { getFieldByPath, getValueByPath, inputTypeToUniversalFieldType } from 'features/instruction/utils/schema';

import { ProductSelect } from 'features/order/components/process-order-draft/form-elemts/ProductSelect';

import { FieldPathWrapper } from 'features/order/components/process-order-draft/utils';
import { ProductQuantityInputWithPopup } from 'features/order/components/process-order-draft/form-elemts';
import { QuantityConversionPopupContent } from 'features/product/types/product';
import DynamicField from '../DynamicField';
import ProductStandardField from '../ProductStandardField';
import CommentField from '../../CommentField';

interface Props {
  customerId: string;
  product: ProductWithQuantity;
  fieldSpecs: FieldSpec[];
  schema: Schema;
  productNameField: FieldSpec;
  onProductSelectionChange: (p: Product) => void;
  onProductUnitChange: (unit: Unit) => void;
  updateProduct: (val: any) => void;
  updateProductFieldValueByPath: (path: string, val: any) => void;
  setError: (error: string, path: string) => void;
  quantityPopupContent: QuantityConversionPopupContent;
  onQuantityPopupCanceled: () => void;
  onQuantityPopupConfirmed: () => void;
}

const getProductFieldValueByPath = (product: ProductWithQuantity, modelPath: string) => {
  const path = modelPath.split(`${PRODUCTS_MODEL_PATH}.*.`)[1];

  if (!path) return null;

  return getValueByPath(product, path);
};

const _Field = ({
  product,
  fieldSpec,
  schema,
  addError: addError_,
  removeError: removeError_,
  updateProductFieldValueByPath,
  onUnitChange,
  quantityPopupContent,
  onQuantityPopupCanceled,
  onQuantityPopupConfirmed,
}: {
  product: ProductWithQuantity,
  fieldSpec: FieldSpec,
  schema: Schema,
  addError: (error: string, path: string) => void,
  removeError: (path: string) => void,
  updateProductFieldValueByPath: (path: string, val: any) => void,
  onUnitChange: (unit: Unit) => void,
  quantityPopupContent: QuantityConversionPopupContent,
  onQuantityPopupCanceled: () => void,
  onQuantityPopupConfirmed: () => void,
}) => {
  const field = getFieldByPath(schema?.fields, fieldSpec.path);
  const type = inputTypeToUniversalFieldType(field?.inputType, field?.type);
  const placeHolder = (fieldSpec.examples || []).join(', ');

  const itemStyle = {
    minWidth: `${fieldSpec.name?.length}ch`,
    maxWidth: '300px',
    flexGrow: 1,
    error: {
      position: 'absolute',
    },
  };

  const onValueChange = useCallback((value: string | number) => {
    updateProductFieldValueByPath(fieldSpec.path, value);
  }, [updateProductFieldValueByPath, fieldSpec.path]);

  const addError = useCallback((error: string) => {
    addError_(error, fieldSpec.path);
  }, [addError_, fieldSpec.path]);

  const removeError = useCallback(() => {
    removeError_(fieldSpec.path);
  }, [removeError_, fieldSpec.path]);

  const setError = useCallback((error: string) => {
    if (error) {
      addError(error);
    } else {
      removeError();
    }
  }, [addError, removeError]);

  // TODO(chihirokuya): move this to ProductStandardField
  if (field?.isStandard && field.standardFieldType === StandardFieldType.StandardFieldTypeProductQuantity) {
    return (
      <ProductQuantityInputWithPopup
        productQuantityField={fieldSpec}
        product={product}
        label={fieldSpec.name}
        onProductQuantityChange={onValueChange}
        quantityPopupContent={quantityPopupContent}
        onQuantityPopupCanceled={onQuantityPopupCanceled}
        onQuantityPopupConfirmed={onQuantityPopupConfirmed}
      />
    );
  }

  if (field?.isStandard) {
    return (
      <ProductStandardField
        standardFieldType={field.standardFieldType as StandardFieldType}
        type={type}
        productWithQuantity={product}
        label={fieldSpec.name}
        value={getProductFieldValueByPath(product, fieldSpec.path)}
        className="relative"
        disabled={false}
        readOnly={false}
        itemStyle={itemStyle as Record<string, React.CSSProperties>}
        placeHolder={placeHolder}
        onUnitChange={onUnitChange}
        onValueChange={onValueChange}
        setError={setError}
      />
    );
  }

  return (
    <DynamicField
      type={type}
      label={fieldSpec.name}
      value={getProductFieldValueByPath(product, fieldSpec.path)}
      validation={fieldSpec.validation}
      onValueChange={(val) => updateProductFieldValueByPath(fieldSpec.path, val)}
      addError={addError}
      removeError={removeError}
      placeHolder={placeHolder}
      itemStyle={itemStyle}
    />
  );
};

const WrappedProductSelect = FieldPathWrapper(ProductSelect);
const Field = FieldPathWrapper(_Field);

const ExpandedContent = ({
  customerId,
  product,
  fieldSpecs,
  schema,
  productNameField,
  onProductSelectionChange,
  onProductUnitChange,
  updateProductFieldValueByPath,
  updateProduct,
  setError,
  quantityPopupContent,
  onQuantityPopupCanceled,
  onQuantityPopupConfirmed,
}: Props) => {
  const onTextAreaChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => updateProduct({
    comment: event.target.value,
  }), [updateProduct]);

  const addError = useCallback((error: string, path: string) => {
    setError(error, path);
  }, [setError]);

  const removeError = useCallback((path: string) => {
    setError('', path);
  }, [setError]);

  const setError_ = useCallback((error: string) => {
    setError(error, 'product-name');
  }, [setError]);

  return (
    <div>
      <div>
        <div key={`preview-${product.uiId}`} className="flex flex-wrap gap-y-sm w-full">
          <WrappedProductSelect
            isProductField
            fieldPath={productNameField?.path}
            fieldId={product.positionId}
            label="Product"
            wrapperClassName="contents"
            businessId={customerId}
            productWithQuantity={product}
            onProductChange={onProductSelectionChange}
            setError={setError_}
          />
          {fieldSpecs.filter((fieldSpec) => fieldSpec.modelPath !== 'products.*.product.name').map((fieldSpec) => (
            <Field
              key={`preview-${fieldSpec.uiId}`}
              product={product}
              fieldSpec={fieldSpec}
              isProductField
              schema={schema}
              addError={addError}
              removeError={removeError}
              updateProductFieldValueByPath={updateProductFieldValueByPath}
              onUnitChange={onProductUnitChange}
              wrapperClassName="flex py-2 px-1 items-end flex-1"
              fieldPath={fieldSpec.path}
              fieldId={product.positionId}
              quantityPopupContent={quantityPopupContent}
              onQuantityPopupCanceled={onQuantityPopupCanceled}
              onQuantityPopupConfirmed={onQuantityPopupConfirmed}
            />
          ))}
        </div>
      </div>
      <CommentField
        commentId={`${product.uiId}-comment`}
        defaultComment={product.comment}
        onTextAreaChange={onTextAreaChange}
      />
    </div>
  );
};

export default ExpandedContent;
