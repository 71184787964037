import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useDisclosure } from '@mantine/hooks';

import { globalInboxes } from 'state/globalInboxes';
import LoadingOverlay from 'components/ui/LoadingOverlay';
import { Inbox } from 'models/Inbox';

import EditInboxModal from './EditInboxModal';
import InboxListItem from './InboxListItem';

const InboxList = observer(() => {
  const [opened, { open, close }] = useDisclosure(false);

  const [editingInbox, setEditingInbox] = useState<Inbox | null>(null);

  return (
    <>
      <LoadingOverlay visible={globalInboxes.isLoading} />

      <p className="text-lg font-semibold text-gray-400 py-2">Inbox</p>

      <span className="space-y-2">
        {
          !globalInboxes.isLoading && globalInboxes.inboxes.length === 0 && (
            <div className="flex h-full w-full pt-2 justify-center">
              <div className="text-sm text-gray-500">
                No inboxes found
                <span className="block mt-1">
                  Contact your administrator to give you access to an inbox
                </span>
              </div>
            </div>
          )
        }

        {globalInboxes.inboxes.map((inbox) => (
          <InboxListItem
            key={inbox.id}
            inbox={inbox}
            setEditingInbox={setEditingInbox}
            open={open}
          />
        ))}
      </span>

      <EditInboxModal
        opened={opened}
        close={close}
        editingInbox={editingInbox}
        setEditingInbox={setEditingInbox}
      />
    </>
  );
});

export default InboxList;
