import { Image as MantineImage } from '@mantine/core';
import { useEffect, useRef } from 'react';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { Buttons } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/Buttons';
import { KonvaStage } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/KonvaStage';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { Message } from 'features/message/models/Message';
import { KonvaStageRef } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/KonvaStage/type';
import { useImgUtils } from './hooks/useImgUtils';
import { useTransformerUtils } from './hooks/useTransformerUtils';
import { useKonvaUtils } from './hooks/useKonvaUtils';

interface Props {
  message: Message;

  imgUrl: string;
  imgName: string;
  docUrl: string;
}

const ImageWithAnnotations = ({
  message, imgUrl, imgName, docUrl,
}: Props) => {
  const transformWrapperRef = useRef<ReactZoomPanPinchRef>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const konvaStageRef = useRef<KonvaStageRef>(null);

  const {
    selectedDocIndex,
    selectedDocPageIndex,
  } = useProcessOrderContext();

  const {
    imageDimensions,
    naturalImageDimensions,
    scaleX,
    scaleY,
    handleImageLoad,
    imgLoadingStatus,
    setImgLoadingStatus,
  } = useImgUtils();

  const {
    wheelSmoothStep,
    onWheelStart,
  } = useTransformerUtils();

  const {
    sendScreenshot,
    selectedTool,
    setSelectedTool,
    onTransformed,
  } = useKonvaUtils({
    message,
    selectedDocIndex,
    selectedDocPageIndex,
    imageDimensions,
    naturalImageDimensions,
    scaleX,
    scaleY,
    transformWrapperRef,
    imageRef,
    konvaStageRef,
  });

  useEffect(() => console.log('rerendered'), []);

  useEffect(() => console.log('[scale]', scaleX, scaleY), [scaleX, scaleY]);

  useEffect(() => console.log('[naturalImageDimensions]', naturalImageDimensions), [naturalImageDimensions]);

  if (!imgUrl || imgLoadingStatus === 'error') {
    return (
      <div className="flex justify-center items-center w-full h-full">
        The attached image could not be loaded.
      </div>
    );
  }

  return (
    <TransformWrapper
      ref={transformWrapperRef}
      key={
        `${selectedDocIndex}-${selectedDocPageIndex}-${naturalImageDimensions.width}-${naturalImageDimensions.height}`
      } // To force rerender when page changes
      onWheelStart={onWheelStart}
      wheel={{
        smoothStep: wheelSmoothStep,
      }}
      minScale={0.5}
      panning={{ disabled: selectedTool !== 'Hand' }}
      onTransformed={onTransformed}
      centerOnInit
    >
      {({ zoomIn, zoomOut }) => {
        console.log('[zoomIn] re-render');
        return (
          <>
            <div
              className="flex-1 overflow-hidden flex flex-col relative"
            >
              <TransformComponent
                contentClass="flex-1 flex flex-col w-full"
                wrapperClass="flex-1 flex flex-col w-full"
                wrapperStyle={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <MantineImage
                  ref={imageRef}
                  onLoadStart={() => setImgLoadingStatus('loading')}
                  onLoad={handleImageLoad}
                  onError={() => setImgLoadingStatus('error')}
                  alt=""
                  src={imgUrl}
                  className="cursor-move object-contain"
                  style={{
                    maxHeight: '100%',
                    maxWidth: '100%',
                  }}
                  crossOrigin="use-credentials"
                />

                {imgLoadingStatus === 'loaded' && (
                  <KonvaStage
                    ref={konvaStageRef}
                    annotationsKey={`${selectedDocIndex}-${selectedDocPageIndex}`}
                    imageDimensions={imageDimensions}
                    scaleX={scaleX}
                    scaleY={scaleY}
                    selectedTool={selectedTool}
                    sendScreenshot={sendScreenshot}
                  />
                )}
              </TransformComponent>
            </div>
            <Buttons
              defaultProps={{
                imageTitle: `${imgName} - ${selectedDocPageIndex + 1}`,
                docUrl,
                zoomIn,
                zoomOut,
              }}
              magicPenProps={{
                selectedTool,
                setSelectedTool,
              }}
            />
          </>
        );
      }}
    </TransformWrapper>
  );
};

export default ImageWithAnnotations;
