import { getDescriptionByGroup, getTitleByGroup } from 'features/lro/utils/operation';
import { httpGetV1 } from 'utils/xhr';
import { chunk, debounce } from 'lodash';
import {
  makeObservable, observable, action, runInAction,
  reaction,
} from 'mobx';
import { GetDocumentsQueryType } from 'models/GetDocumentsQueryType';
import { Operation, OperationGroup } from 'models/Lro';
import { globalSseSources } from 'state/globalSseSources';

enum LRO_STATUSES {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

type LRO = {
  lroId: string;
  title: string;
  description?: string;
  status: LRO_STATUSES;
  errorMessage?: string;
  buttonLabel?: string;
  onButtonClick?: () => void;

  group: OperationGroup;
  data?: any; // Any additional data
};

class LROs {
  lros: LRO[] = [];

  // Fetch related fields
  isDataLoading: boolean = false;

  cursor: string | null;

  didEndReach: boolean = false;

  pendingCount: number;

  // Store the interval ID so that we can avoid creating duplicate intervals.
  pollingIntervalId: ReturnType<typeof setInterval> | null = null;

  constructor() {
    makeObservable(this, {
      lros: observable,
      pendingCount: observable,
      isDataLoading: observable,
      cursor: observable,
      create: action,
      updateStatus: action,
      dismiss: action,
    });

    this.loadPendingLROsCount();
    this.loadPendingLROs();

    reaction(
      () => this.pendingCount,
      (pendingCount) => {
        if (pendingCount > 0) {
          this.startPolling();
        }
      },
    );
  }

  refresh = () => {
    runInAction(() => {
      this.pendingCount = 0;

      if (this.pollingIntervalId) {
        clearInterval(this.pollingIntervalId as ReturnType<typeof setInterval>);
        this.pollingIntervalId = null;
      }

      this.lros = [];
    });

    this.loadPendingLROsCount();
    this.loadPendingLROs();
  };

  create = (
    lroId: string,
    group: OperationGroup,
    title: string,
    description?: string,
    status?: LRO_STATUSES,
    data?: any,
  ) => {
    const newLRO: LRO = {
      lroId,
      title,
      description,
      status,
      group,
      data,
    };
    this.lros.push(newLRO);
  };

  updateStatus = (lroId: string, status: LRO_STATUSES) => {
    this.lros = this.lros.map((lro) => (lro.lroId === lroId ? { ...lro, status } : lro));

    if (status === LRO_STATUSES.SUCCESS || status === LRO_STATUSES.ERROR) {
      this.pendingCount -= 1;
    }
  };

  addButton = (lroId: string, buttonLabel: string, onButtonClick: () => void) => {
    this.lros = this.lros.map((lro) => (lro.lroId === lroId ? { ...lro, buttonLabel, onButtonClick } : lro));
  };

  addErrorMessage = (lroId: string, errorMessage: string) => {
    this.lros = this.lros.map((lro) => (lro.lroId === lroId ? { ...lro, errorMessage } : lro));
  };

  dismiss = (lroId: string) => {
    this.lros = this.lros.filter((lro) => lro.lroId !== lroId);
    this.pendingCount -= 1;
  };

  addPendingLRO = (
    lroId: string,
    group: OperationGroup,
    title: string,
    description: string,
    data?: any,
  ) => {
    this.create(lroId, group, title, description, LRO_STATUSES.PENDING, data);
    runInAction(() => {
      this.pendingCount += 1;
    });
  };

  // Returns whether there are lros not yet done
  _pollPendingLROsBatch = async (lroIds: string[]) => {
    // Loop until returned cursor is empty string
    let cursor = '';
    let anyStillPending = false;
    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await httpGetV1('lros', {
        params: {
          ids: lroIds,
          cursor,
        },
        classType: Operation,
      });

      cursor = response.data.cursor;

      const isAllDone = response.data.result?.every((operation: Operation) => {
        if (operation.isDone && !operation.error) {
          this.updateStatus(operation.id, LRO_STATUSES.SUCCESS);
        } else if (operation.error) {
          this.updateStatus(operation.id, LRO_STATUSES.ERROR);
        }
        return operation.isDone;
      });

      if (!isAllDone) {
        anyStillPending = true;
      }
    } while (cursor !== '' && cursor !== null);

    return anyStillPending;
  };

  _pollPendingLROs = async () => {
    const lros = this.lros
      .filter((lro) => lro.status === LRO_STATUSES.PENDING);

    if (lros.length === 0) return false;

    const filteredLroIds = lros.map((lro) => lro.lroId);

    // Split into batches of each 20 ids
    const batches = chunk(filteredLroIds, 20);

    const anyStillPendings = await Promise.all(batches.map(this._pollPendingLROsBatch));

    return anyStillPendings.some((pending) => pending);
  };

  startPolling = () => {
    if (this.pollingIntervalId !== null) {
      // Already polling, so do nothing.
      return;
    }

    runInAction(() => {
      this.pollingIntervalId = setInterval(async () => {
        const isAnyStillPending = await this._pollPendingLROs();

        if (!isAnyStillPending) {
          // If nothing is pending anymore, clear the interval.
          clearInterval(this.pollingIntervalId as ReturnType<typeof setInterval>);
          this.pollingIntervalId = null;
        }
      }, 15000);
    });
  };

  loadPendingLROsCount = async () => {
    const countResponse = await httpGetV1('/lros', {
      params: {
        status: 'pending',
        query_type: GetDocumentsQueryType.count,
      },
    });

    runInAction(() => {
      this.pendingCount = countResponse.data.count;
    });
  };

  loadPendingLROs = async (reset?: boolean) => {
    runInAction(() => {
      if (reset) {
        this.cursor = null;
        this.lros = [];
        this.didEndReach = false;
      }
      this.isDataLoading = true;
    });

    if (!reset && this.didEndReach) return;

    const dataResponse = await httpGetV1('/lros', {
      params: {
        query_type: GetDocumentsQueryType.data,
        status: 'pending',
        cursor: this.cursor,
        limit: 20,
      },
      classType: Operation,
    });

    dataResponse.data.result?.forEach((operation: Operation) => {
      this.create(
        operation.id,
        operation.group,
        getTitleByGroup(operation.group, operation.type, operation.subGroup),
        getDescriptionByGroup(operation.group, operation.type, operation.subGroup),
        LRO_STATUSES.PENDING,
        operation,
      );
    });

    runInAction(() => {
      this.isDataLoading = false;
      this.cursor = dataResponse.data.cursor;
      if (dataResponse.data.result?.length === 0 || !dataResponse.data.cursor) {
        this.didEndReach = true;
      }
    });
  };

  _debouncedLoadPendingLROs = debounce(this.loadPendingLROs, 1000);

  _debouncedLoadPendingLROsCount = debounce(this.loadPendingLROsCount, 1000);

  addSseSourcesHandler = () => {
    globalSseSources.addSourcesHandler(
      'lros',
      ['lro_done', 'lro_started'],
      this._onNewSseReceived,
    );
  };

  // eslint-disable-next-line class-methods-use-this
  removeSseSourcesHandler = () => {
    globalSseSources.removeSourcesHandler('lros');
  };

  _onNewSseReceived = () => {
    this._debouncedLoadPendingLROs(true);
    this._debouncedLoadPendingLROsCount();
  };
}

export { LROs, LRO, LRO_STATUSES };
