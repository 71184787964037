import { AnimatePresence, Reorder } from 'framer-motion';

import { useMemo } from 'react';

import { GroupedOption } from 'features/settings/components/SettingCard/ui/GroupedCombobox/types';
import { UniversalField } from 'components/common/UniversalField';
import { FieldSpec } from 'features/instruction/models/Schema';
import { inputTypeToUniversalFieldType } from 'features/instruction/utils/schema';
import BodyBase from './_BodyBase';

interface Props {
  parentPath: string;
  fieldSpecs: FieldSpec[];
  setFieldSpecs: React.Dispatch<React.SetStateAction<FieldSpec[]>>;
  groupedOptions: GroupedOption[];
}

const CommonBody = React.memo(({
  parentPath, fieldSpecs, setFieldSpecs, groupedOptions,
}: Props) => {
  const previewNode = useMemo(
    () => (
      <div className="w-full overflow-hidden">
        <div className="w-full overflow-hidden rounded-tl-xl bg-white border-l border-l-gray-200">
          <div className="flex bg-primary-600/80 ring-1 ring-white/5">
            <div className="-mb-px flex text-sm font-medium leading-6">
              <div className="px-4 py-2 text-white">
                Preview
              </div>
            </div>
          </div>
          <div className="px-6 pb-14 pt-6">
            <Reorder.Group
              axis="y"
              values={fieldSpecs}
              onReorder={(newOrder) => setFieldSpecs((_fieldSpecs) => {
                const droppedFieldSpecs = _fieldSpecs.filter(
                  (fieldSpec) => fieldSpec.lastCardableParentPath !== parentPath,
                );

                return [...droppedFieldSpecs, ...newOrder];
              })}
              initial="hidden"
              animate="visible"
              variants={{
                visible: {
                  transition: {
                    staggerChildren: 0.5,
                  },
                },
              }}
              className="space-y-2"
            >
              <AnimatePresence>
                {fieldSpecs.map((fieldSpec) => (fieldSpec.name ? (
                  <Reorder.Item
                    key={`preview-${fieldSpec.uiId}`}
                    id={`content-${fieldSpec.uiId}`}
                    className="flex w-full px-1 py-2"
                    value={fieldSpec}
                    exit="exit"
                  >
                    <UniversalField
                      className="w-full"
                      type={inputTypeToUniversalFieldType(fieldSpec.inputType, fieldSpec.type)}
                      label={fieldSpec.name}
                      placeHolder={fieldSpec.examples?.length ? fieldSpec.examples.join(', ') : fieldSpec.path}
                      shouldScroll={!!fieldSpec.added}
                      validation={fieldSpec.validation}
                      readOnly
                    />
                  </Reorder.Item>
                ) : null),
                )}
              </AnimatePresence>
            </Reorder.Group>
          </div>
        </div>
      </div>
    ),
    [fieldSpecs, parentPath, setFieldSpecs],
  );

  return (
    <BodyBase
      parentPath={parentPath}
      fieldSpecs={fieldSpecs}
      setFieldSpecs={setFieldSpecs}
      groupedOptions={groupedOptions}
      previewNode={previewNode}
    />
  );
});

export default CommonBody;
