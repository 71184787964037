import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { LoadingOverlay } from '@mantine/core';
import { capitalize } from 'lodash';
import { genericErrorFeedback } from 'utils/errors';
import { SidebarBase } from '../SidebarBase';

import { Button } from '../../ui/Button';
import { Select, SelectOption } from '../../ui/Select';
import { useFetchWorkflowRun } from '../../../hooks/fetch/useFetchWorkflowRun';
import { useFetchWorkflows } from '../../../hooks/fetch/useFetchWorkflows';
import { WorkflowRun } from '../../../models/Workflow';
import TranscriptionLanguageSelect from './TranscriptionLanguageSelect';

interface Props {
  workflowRunId: string;

  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  isVoiceAttachment?: boolean;
  transcriptionLanguage?: string;
  onComplete: (
    selectedWorkflow: string, workflowRun: WorkflowRun, transcriptionLanguage?: string,
  ) => void;
}

const RetryWorkflowSidebar = ({
  workflowRunId,

  sidebarOpen,
  setSidebarOpen,
  isVoiceAttachment,
  transcriptionLanguage,
  onComplete,
}: Props) => {
  const { workflowRun, isLoading: isWorkflowRunLoading, loadWorkflowRun } = useFetchWorkflowRun({
    id: workflowRunId,
  });

  const transcriptionLanguageSelectRef = useRef(null);

  const isLoading = useMemo(() => (
    isWorkflowRunLoading || transcriptionLanguageSelectRef.current?.isLoading
  ), [isWorkflowRunLoading, transcriptionLanguageSelectRef]);

  const { workflows, loadWorkflows } = useFetchWorkflows({});

  const [selectedWorkflow, setSelectedWorkflow] = useState<SelectOption>(null);

  const workflowOptions = useMemo(() => workflows?.map((workflow) => ({
    label: capitalize(workflow),
    value: workflow,
  })) || [],
  [workflows],
  );

  const onSelectedWorkflowSelectChange = useCallback(
    (selected: { value: string; label: string }) => {
      setSelectedWorkflow(selected);
    },
    [],
  );

  useEffect(() => {
    if (sidebarOpen) {
      loadWorkflows();
      loadWorkflowRun();
    }
  }, [sidebarOpen, loadWorkflows, loadWorkflowRun]);

  useEffect(() => {
    if (!isWorkflowRunLoading && workflowRun) {
      setSelectedWorkflow(
        workflowOptions.find((o) => o.value === workflowRun.state?.workflow) || null,
      );
    }
  }, [workflowRun, isWorkflowRunLoading, workflowOptions]);

  const _onComplete = () => {
    if (!selectedWorkflow) {
      genericErrorFeedback('Retry workflow failed.')('Please select a workflow');
      return;
    }

    setSidebarOpen(false);

    if (transcriptionLanguageSelectRef.current) {
      onComplete(selectedWorkflow.value, workflowRun, transcriptionLanguageSelectRef.current?.selectedLanguage?.value);
    } else {
      onComplete(selectedWorkflow.value, workflowRun);
    }
  };

  const header = (
    <div className="flex space-x-2 px-sm">Retry workflow run</div>
  );

  const body = (
    <div className="space-y-4 px-sm">
      <p className="text-sm text-gray-600">
        Select the workflow you want to process this message with.
      </p>
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ type: 'dots' }}
        overlayProps={{ blur: 2 }}
      />
      <div className="w-full">
        <Select
          options={workflowOptions}
          selectedOption={selectedWorkflow}
          onSelectionChange={onSelectedWorkflowSelectChange}
        />
      </div>
      {
        isVoiceAttachment && (
          <div className="w-full">
            <TranscriptionLanguageSelect
              ref={transcriptionLanguageSelectRef}
              transcriptionLanguage={transcriptionLanguage}
            />
          </div>
        )
      }
      <Button
        title="Retry"
        icon={<ArrowPathIcon className="aspect-square w-4 text-white" />}
        onClick={_onComplete}
        disabled={!selectedWorkflow}
      />
    </div>
  );

  return (
    <SidebarBase
      header={header}
      body={body}
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
      sidebarWidth="max-w-[30vw] 2xl:max-w-[20vw]"
    />
  );
};

export default RetryWorkflowSidebar;
