import { useState, useCallback } from 'react';
import { httpGetV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';
import { Inbox } from 'models/Inbox';

const useFetchInboxById = () => {
  const [inbox, setInbox] = useState<Inbox | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadInbox = useCallback(
    (
      inboxId: string,
      giveErrorFeedback: boolean = true,
    ): Promise<Inbox> => {
      if (!inboxId) return Promise.reject();
      setIsLoading(true);
      return httpGetV1(`/inboxes/${inboxId}`, { classType: Inbox })
        .then((response) => {
          setInbox(response.data);
          return response.data;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('An error has occured while fetching inbox')(
              error,
            );
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    inbox,
    loadInbox,
    isLoading,
  };
};

export { useFetchInboxById };
