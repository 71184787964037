import {
  useCallback, useMemo, useRef,
} from 'react';
import { observer } from 'mobx-react-lite';

import { WorkflowRetrySidebarWrapper } from 'components/wrapper/WorkflowRetrySidebarWrapper';
import { Workflow, WorkflowRun } from 'models/Workflow';
import { useRetryWorkflow } from 'features/lro/hooks/useRetryWorkflow';
import { Message } from 'features/message/models/Message';
import { Business } from 'models/Business';
import { User } from 'features/user/models/User';
import { globalLROs } from 'state/globalLROs';
import { LRO_STATUSES } from 'state/classes/LROs';
import Content from './Content';

interface Props {
  messages: Message[];
  customer?: Business;
  userSentBy: User;
  isMessagesLoading: boolean;
  isCustomerLoading: boolean;
}

const ProcessMessage = observer(({
  messages,
  customer,
  userSentBy,
  isMessagesLoading,
  isCustomerLoading,
}: Props) => {
  const { retryWorkflow } = useRetryWorkflow();
  const workflowRetrySidebarWrapperRef = useRef<any>(null);

  const pendingId = messages[0]?.id;
  const isMessageRetrying = globalLROs.lros.find((lro) => lro.data === pendingId)?.status === LRO_STATUSES.PENDING;

  const onOpenRetryWorkflowSidebarButtonClick = useCallback(() => {
    if (workflowRetrySidebarWrapperRef.current) {
      workflowRetrySidebarWrapperRef.current.onRetryWorkflowButtonClick();
    }
  }, []);

  const onRetryButtonClick = (
    selectedWorkflow: string, workflowRun: WorkflowRun, transcriptionLanguage?: string,
  ) => {
    retryWorkflow(selectedWorkflow, workflowRun, pendingId, 'Retry Workflow', 'Workflow is being retried', transcriptionLanguage);
  };

  const workflowRunId = useMemo(() => {
    if (!messages[0] || !messages[0].workflowRuns) return null;

    return messages[0].workflowRuns[Workflow.Other];
  }, [messages]);

  if (!workflowRunId) {
    return (
      <Content
        messages={messages}
        customer={customer}
        userSentBy={userSentBy}
        isMessagesLoading={isMessagesLoading}
        isCustomerLoading={isCustomerLoading}
        isMessageRetrying={isMessageRetrying}
      />
    );
  }

  return (
    <WorkflowRetrySidebarWrapper
      ref={workflowRetrySidebarWrapperRef}
      workflowRunId={workflowRunId}
      onRetryWorkflow={onRetryButtonClick}
    >
      <Content
        messages={messages}
        customer={customer}
        userSentBy={userSentBy}
        isMessagesLoading={isMessagesLoading}
        isCustomerLoading={isCustomerLoading}
        isMessageRetrying={isMessageRetrying}
        onOpenRetryWorkflowSidebarButtonClick={
            onOpenRetryWorkflowSidebarButtonClick
          }
      />
    </WorkflowRetrySidebarWrapper>
  );
});

export default ProcessMessage;
