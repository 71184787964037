import { useEffect, useState } from 'react';

import { httpGetV1 } from 'utils/xhr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowTrendDown } from '@fortawesome/free-solid-svg-icons';

import {
  Card, CardHeader, CardTitle, CardDescription, CardContent,
} from 'components/charts/ChartCard';

const CustomerChurnRate = () => {
  const [data, setData] = useState<{ x: string; y: number }[]>([]);

  // TODO(chihirokuya): This logic should be moved to the backend
  useEffect(() => {
    httpGetV1('/businesses/me/customers/analytics/churn_rate', {
      params: {
        metric: 'total_products_sold',
      },
    }).then((response) => {
      setData((response.data || [])
        .filter((item: any) => item.customerName && item.quantityRate && item.quantityRate < 1)
        .map((item: any) => ({ x: item.customerName, y: (item.quantityRate - 1) * 100 })));
    });
  }, []);

  return (
    <Card className="w-full">
      <CardHeader>
        <div className="flex items-center justify-between">
          <div className="flex flex-col space-y-1">
            <CardTitle className="flex items-center gap-1">
              <FontAwesomeIcon icon={faArrowTrendDown} className="text-red-500" />
              Top 10 Customers by Churn Rate
            </CardTitle>
            <CardDescription>
              Customers with the highest churn rate
            </CardDescription>
          </div>
        </div>
      </CardHeader>
      <CardContent className="h-full w-full">
        <table className="w-full">
          <thead>
            <tr className="border-b">
              <th className="py-1.5 px-2 text-left">Customer</th>
              <th className="py-1.5 px-2 text-right">Churn Rate</th>
            </tr>
          </thead>
          <tbody>
            {data.map((customer) => (
              <tr
                key={customer.x}
                className="border-b hover:bg-gray-50"
              >
                <td className="py-1.5 px-2 font-medium">{customer.x}</td>
                <td className="py-1.5 px-2 text-right">
                  <div className="flex items-center justify-end gap-1 text-red-500">
                    <FontAwesomeIcon icon={faArrowDown} />
                    {Math.abs(customer.y)}
                    %
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {
          data.length === 0 && (
            <div className="pt-10 w-full flex justify-center">
              <div className="text-sm text-gray-500">No data available</div>
            </div>
          )
        }
      </CardContent>
    </Card>
  );
};

export default CustomerChurnRate;
