import { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { LineChart } from 'components/charts';
import { BusinessSales } from 'models/Analytics';
import { capitalizeFirstLetter } from 'utils/strings';
import { useFetchBusinessSales } from 'hooks/fetch/useFetchBusinessSales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Select } from '@mantine/core';

const formatData = (
  data: BusinessSales[],
  period: 'daily' | 'weekly' | 'monthly',
  metric: 'totalRevenueSum' | 'totalQuantitySum',
) => {
  if (!data) return [];

  if (period === 'monthly') {
    // Format to month name
    return data.map((item) => ({
      x: format(item.saleDate, 'MMM'),
      y: item[metric],
    }));
  }

  // Format to Month-Day
  return data.map((item) => ({
    x: format(item.saleDate, 'MMM-dd'),
    y: item[metric],
  }));
};

const RevenueLineChart = () => {
  const { businessSales, loadBusinessSales } = useFetchBusinessSales();

  const [period, setPeriod] = useState<'daily' | 'weekly' | 'monthly'>('monthly');
  const [data, setData] = useState([]);

  const [metric, setMetric] = useState<'totalRevenueSum' | 'totalQuantitySum'>('totalQuantitySum');

  const title = () => (
    <div className="flex items-center w-fit -mb-2">
      {capitalizeFirstLetter(period)}
      <Select
        value={metric}
        data={[{ value: 'totalQuantitySum', label: 'Products Sold' }, { value: 'totalRevenueSum', label: 'Revenue' }]}
        styles={{
          wrapper: {
            width: 'fit-content',
          },
          input: {
            outline: '0px',
            border: '0px',
            width: 'fit-content',
            color: '#2563EB',
          },
        }}
        onChange={(value) => setMetric(value as 'totalRevenueSum' | 'totalQuantitySum')}
        rightSection={<FontAwesomeIcon icon={faChevronDown} />}
        comboboxProps={{ offset: 0 }}
      />
    </div>
  );

  const subtitle = () => (
    <span className="flex items-center w-fit">
      {capitalizeFirstLetter(period)}
      {' '}
      {capitalizeFirstLetter(metric === 'totalQuantitySum' ? 'products sold' : 'revenue')}
      {' '}
      trends
    </span>
  );

  const tooltipFormatter = (value: any) => {
    if (metric === 'totalQuantitySum') {
      return [`${value.toLocaleString()} products`];
    }

    return [`${value.toLocaleString()} CHF`];
  };

  useEffect(() => {
    loadBusinessSales(period);
  }, [period, loadBusinessSales]);

  useEffect(() => {
    if (businessSales) {
      setData(formatData(businessSales, period, metric));
    }
  }, [businessSales, period, metric]);

  return (
    <LineChart
      data={data}
      xDataKey="x"
      yDataKey="y"
      title={title()}
      subtitle={subtitle()}
      chartDivClassName="min-h-60"
      selectOptions={[
        { value: 'daily', label: 'Daily' },
        { value: 'weekly', label: 'Weekly' },
        { value: 'monthly', label: 'Monthly' },
      ]}
      selectedOptionValue={period}
      setOption={setPeriod}
      toolTipFormatter={tooltipFormatter}
    />
  );
};

export default RevenueLineChart;
