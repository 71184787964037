import { v4 as uuidv4 } from 'uuid';

import { Business } from 'models/Business';
import {
  MessageChannel, MessageRefAction, MessageRefType, MessageType,
} from 'features/message/models/Message';
import { User } from 'features/user/models/User';

class Chat {
  id: string;

  business: Business;

  title?: string;

  user?: User;

  lastMessage: string;

  lastMessageCreatedAt: string;

  lastMessageRefAction: MessageRefAction;

  lastMessageRefType: MessageRefType;

  lastMessageType: MessageType;

  lastMessageSource: MessageChannel;

  // TODO: proper constructor
  constructor({
    id,
    business,
    user,
  }: {
    id?: string;
    business: Business;
    user?: User;
  }) {
    this.id = id || uuidv4();
    this.business = business;
    this.user = user;
  }
}

export { Chat };
