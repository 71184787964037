import RevenueLineChart from './RevenueLineChart';
import TopSellingProductBarChart from './TopSellingProductBarChart';
import TopTierCustomerBarChart from './TopTierCustomerBarChart';
import CustomerChurnRate from './CustomerChurnRate';

const Analytics = () => (
  <>
    <div className="mb-3 mt-6 flex w-full items-center space-x-2">
      <p className="text-sm font-semibold text-gray-800">Analytics</p>
      {/* <div className="flex-1 h-0.5 bg-gray-300" /> */}
    </div>

    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-8 row-start-1">
        <RevenueLineChart />
      </div>

      <div className="col-span-4 row-start-1">
        <CustomerChurnRate />
      </div>

      <div className="col-span-6 row-start-2">
        <TopSellingProductBarChart />
      </div>

      <div className="col-span-6 row-start-2">
        <TopTierCustomerBarChart />
      </div>
    </div>
  </>
);

export default Analytics;
