import { useState, useCallback, useEffect } from 'react';

import { httpGetV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';
import { Workflow } from '../../models/Workflow';

interface Props {
  preventFetch?: boolean;
}

const useFetchWorkflows = ({
  preventFetch = true,
}: Props) => {
  const [workflows, setWorkflows] = useState<Workflow[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const resetParams = () => {
    setWorkflows(null);
  };

  const loadWorkflows = useCallback(
    async (giveErrorFeedback: boolean = false) => {
      setIsLoading(true);
      httpGetV1('/workflows')
        .then((response) => {
          const responseData: Workflow[] = response.data;
          setWorkflows(responseData);
        })
        .catch((err) => {
          if (giveErrorFeedback) genericErrorFeedback('Error getting workflows')(err);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  useEffect(() => {
    if (preventFetch) return () => {};

    resetParams();

    const controller = new AbortController();

    loadWorkflows();

    return () => {
      controller.abort(); // Abort the fetch when orderId changes or component unmounts
    };
  }, [loadWorkflows, preventFetch]);

  return { workflows, isLoading, loadWorkflows };
};

export { useFetchWorkflows };
