import { ContactUser } from '../features/user/models/ContactUser';

import { OAuthBinding } from '../types/oauth';
import { Phone } from './Phone';

enum BusinessType {
  Restaurant = 'RESTAURANT',
  Supplier = 'SUPPLIER',
}

class Business {
  id: string;

  externalId: string;

  name: string;

  nameSupplement?: string;

  shortName?: string;

  phones: Phone[];

  email: string;

  emails: string[];

  address: string;

  type: BusinessType;

  contactUsers: ContactUser[];

  createdAt: string;

  createdBy: string;

  external: boolean;

  updatedAt: string;

  customer?: OrganizationData;

  disabled?: boolean;

  oauthBindings: Map<string, OAuthBinding>;

  // TODO: proper constructor
  constructor({
    name,
  }: {
    name: string;
  }) {
    this.name = name;
  }
}

type OrganizationNotes = {
  note: string;
};

class OrganizationData {
  isAutoCreated: boolean;

  notes: OrganizationNotes[];
}

type Organization = Business;

export { Business, Organization, BusinessType };
