import Dayjs from 'dayjs';

import { DateTimePicker } from '@mantine/dates';
import { Select } from '@mantine/core';
import { useCallback } from 'react';

import { Button } from 'components/ui/Button';

import { doesExist } from 'utils/comparison';

import { GetSyncLogsParams } from 'features/erp/api/useFetchSyncLogs';

import { SyncModel, SyncRunStatus } from 'models/Erp';

interface Props {
  params: GetSyncLogsParams;
  setParams: React.Dispatch<React.SetStateAction<GetSyncLogsParams>>;
  onResetFilters: () => void;
}

const Filters = ({ params, setParams, onResetFilters }: Props) => {
  const onFromDatetimeSelectChange = useCallback(
    (dt: Date) => {
      setParams((_params) => ({
        ..._params,
        from_time: dt ? Dayjs(dt).format() : null,
      }));
    },
    [setParams],
  );

  const onToDatetimeSelectChange = useCallback(
    (dt: Date) => {
      setParams((_params) => ({
        ..._params,
        to_time: dt ? Dayjs(dt).format() : null,
      }));
    },
    [setParams],
  );

  const onStatusSelectChange = useCallback(
    (status: SyncRunStatus) => {
      setParams((_params) => ({
        ..._params,
        status: doesExist(status, '') ? status : null,
      }));
    },
    [setParams],
  );

  const onModelSelectChange = useCallback(
    (model: SyncModel) => {
      setParams((_params) => ({
        ..._params,
        model: doesExist(model, '') ? model : null,
      }));
    },
    [setParams],
  );

  return (
    <div className="items-bottom flex gap-smd">
      <div className="max-w-sm">
        <Select
          value={params.status || ''}
          data={[
            { value: '', label: 'All statuses' },
            { value: SyncRunStatus.Ok, label: 'Success' },
            { value: SyncRunStatus.Failed, label: 'Failed' },
          ]}
          onChange={onStatusSelectChange}
          clearable
        />
      </div>

      <div className="flex gap-smd">
        <Select
          value={params.model || ''}
          data={[
            { value: '', label: 'All models' },
            { value: SyncModel.Customer, label: 'Customer' },
            { value: SyncModel.Order, label: 'Order' },
            { value: SyncModel.Product, label: 'Product' },
          ]}
          onChange={onModelSelectChange}
          clearable
        />
      </div>

      <div className="max-w-sm">
        <DateTimePicker
          value={params.from_time ? Dayjs(params.from_time).toDate() : Dayjs('0001-01-01T00:00:00Z').toDate()}
          placeholder="From time"
          onChange={onFromDatetimeSelectChange}
          clearable
        />
      </div>
      <div className="max-w-sm">
        <DateTimePicker
          value={params.to_time ? Dayjs(params.to_time).toDate() : new Date()}
          placeholder="To time"
          onChange={onToDatetimeSelectChange}
          clearable
        />
      </div>
      <Button
        theme="secondary"
        title="Reset filters"
        onClick={onResetFilters}
      />
    </div>
  );
};

export default Filters;
