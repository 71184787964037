/* eslint-disable max-len */
import { memo } from 'react';
import { LoadingOverlay } from '@mantine/core';

import { Message } from '../../../../../features/message/models/Message';
import { OrderBody } from './OrderBody';
import { useChatMessagesContext } from '../../../../../contexts/useChatMessagesContext';
import AdamText from '../../../../layout/Page/ChatWithAdam/AdamText';
import { MessageDirection } from '../../../../../features/message/types/message';
import { decodeEntities, isOrderRefType } from '../../../../../features/message/utils/message';

interface Props {
  message: Message;
  direction: MessageDirection;
}

const MessageBodyBase = memo(({ message, direction }: Props) => {
  const { isAiChat } = useChatMessagesContext();

  if (message.isAdamThinking) {
    return (
      <div className="relative h-[1ch] w-20">
        <LoadingOverlay
          visible
          loaderProps={{ type: 'dots' }}
          overlayProps={{ blur: 2 }}
        />
      </div>
    );
  }

  if (isOrderRefType(message.refType)) {
    return <OrderBody message={message} />;
  }

  // For now, for audio messages we show the transcript instead of the original message body.
  // Ideally, we would show both, transcript and original message body.
  const messageText = message.context?.workflowOrder?.audioTranscript?.length > 0
    ? message.context?.workflowOrder?.audioTranscript
    : message.message;

  if (isAiChat && direction === MessageDirection.Received) {
    return (
      <AdamText
        text={messageText}
        useWrapper={false}
        useTypeWriter={!!message.isAdded}
      />
    );
  }

  return (
    <div className="whitespace-pre-wrap">
      {decodeEntities(messageText)}
    </div>
  );
});

export default MessageBodyBase;
