import { THRESHOLD } from 'features/order/components/process-order-draft/form-elemts/ProductSelect/constants';
import { ContentTheme } from 'components/ui/Select';
import { LlmScore, ProductWithQuantity } from 'features/order/models/Order';
import { Product, Unit } from '../models/Product';

const getUnits = (product: Product): string[] => {
  if (!product) return [];

  // First check if sales units are available (new version), otherwise return units
  if (product.salesUnits && product.salesUnits.length > 0) {
    return product.salesUnits.map((unit) => unit.symbol);
  }
  return product.units || [];
};

const hasValidUnit = (product: ProductWithQuantity) => (
  getUnits(product?.product).includes(product?.unit?.symbol)
);

const convertQuantity = (
  prevQuantity: number,
  prevUnit: Unit,
  newUnit: Unit,
): number => {
  if (!prevQuantity || !prevUnit || !newUnit) return prevQuantity;

  if (!prevUnit.baseConversionFactor || !newUnit.baseConversionFactor) return prevQuantity;

  const prevUnitFactor = prevUnit.baseConversionFactor;
  const newUnitFactor = newUnit.baseConversionFactor;

  return Math.round(prevQuantity * (prevUnitFactor / newUnitFactor) * 100) / 100;
};

const getContentThemeAndTooltip = (
  matchedByID?: boolean,
  score?: number,
  llmScore?: string,
): { theme: ContentTheme; tooltip: string } => {
  let contentTheme = ContentTheme.STANDARD;
  let customLabelTooltip = '';

  // if there is llmScore, use it instead of score
  if (llmScore === LlmScore.BEST) {
    contentTheme = ContentTheme.AIInfo;
    customLabelTooltip = 'Adam has matched this product';
  } else if (llmScore === LlmScore.ALMOST_SURE) {
    contentTheme = ContentTheme.AIInfo;
    customLabelTooltip = 'Adam is almost sure about this match';
  } else if (llmScore === LlmScore.CONFIDENT) {
    contentTheme = ContentTheme.WARNING;
    customLabelTooltip = 'Adam is confident about this match';
  } else if (llmScore === LlmScore.NOT_CONFIDENT) {
    contentTheme = ContentTheme.WARNING;
    customLabelTooltip = 'Adam is not confident about this match';
  } else if (llmScore === LlmScore.NOT_SURE) {
    contentTheme = ContentTheme.WARNING;
    customLabelTooltip = 'Adam is not sure about this match';
  }

  if (score && score < THRESHOLD) {
    contentTheme = ContentTheme.AIWarning;
    customLabelTooltip = 'Adam has matched this product, but the product might not be the correct product';
  }

  if (matchedByID) {
    contentTheme = ContentTheme.AISafe;
    customLabelTooltip = 'This product was matched by ID. It is almost certain that this is the correct product.';
  }

  return { theme: contentTheme, tooltip: customLabelTooltip };
};

const getAdditionalInfo = (product: Product): {
  label: string;
  value: string;
}[] => {
  const additionalInfo: {
    label: string;
    value: string;
  }[] = [];

  if (!product) return additionalInfo;

  if (product.baseUnit?.symbol) {
    additionalInfo.push({
      label: 'base unit',
      value: product.baseUnit.symbol,
    });
  }
  if (product.salesUnits && product.salesUnits.length > 0) {
    additionalInfo.push({
      label: 'sales units',
      value: product.salesUnits.map((unit) => unit.symbol).join(', '),
    });
  }
  if (product.provenanceCode) {
    additionalInfo.push({
      label: 'provenance code',
      value: product.provenanceCode,
    });
  }
  Object.entries(product.additionalNotes || {}).forEach(([key, note]) => {
    additionalInfo.push({
      label: key,
      value: note.note,
    });
  });

  return additionalInfo;
};

const wasMatchedByID = (product: ProductWithQuantity) => {
  if (!product || !product.autoMatchedIdOrSku) return false;

  const autoMatchedId = product.autoMatchedIdOrSku;
  const productId = product?.product?.idOrSku;

  return productId === autoMatchedId;
};

export {
  getUnits,
  hasValidUnit,
  convertQuantity,
  getContentThemeAndTooltip,
  getAdditionalInfo,
  wasMatchedByID,
};
