import { useCallback, useRef } from 'react';
import { Loader, LoadingOverlay } from '@mantine/core';
import { observer } from 'mobx-react-lite';

import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { Chat } from '../../../features/chat/models/Chat';
import { ChatPreview } from './ChatPreview';
import { UserRole } from '../../../features/user/models/User';
import { globalChats } from '../../../state/globalChats';
import { useChatsContext } from '../../../contexts/useChatsContext';
import { ScrollAreaWrapper } from '../../wrapper/ScrollAreaWrapper';
import { ROUTES } from '../../../config/routes';
import { Button } from '../../ui/Button';
import { decodeEntities } from '../../../features/message/utils/message';

interface Props {
  selectedChatId?: string;
  navigateToChat: (chatId: string) => void;
}

const ChatList = observer(({ selectedChatId, navigateToChat }: Props) => {
  const navigate = useNavigate();

  const {
    chats, loadChats, isLoading, isInitialLoading, updateDataFilter,
  } = useChatsContext();

  const containerRef = useRef<HTMLDivElement>(null);

  const isEndReached = globalChats.count === chats.length;

  const onChatPreviewClick = useCallback(
    (id: string) => {
      const chat = chats.find((c: Chat) => c.id === id);
      if (chat) {
        navigateToChat(chat.id);
      }
    },
    [chats, navigateToChat],
  );

  // Filter
  const onFilterChange = useCallback(
    (filter: 'private' | 'adam') => () => {
      updateDataFilter(filter)
        .then(() => navigate(ROUTES.CHATS));
    },
    [updateDataFilter, navigate],
  );

  return (
    <div className="flex h-full w-full flex-col items-start border-r border-solid bg-white p-sm">
      <div className="mb-2 flex h-5 w-full lg:h-9">
        <button
          type="submit"
          className={`flex-1 rounded-l-md border border-r-0 border-gray-200 text-xs text-gray-500 shadow-sm lg:rounded-l-xl lg:text-sm ${globalChats.dataFilter === 'private' ? 'bg-gray-100' : 'bg-white'}`}
          onClick={onFilterChange('private')}
        >
          Private
        </button>
        <button
          type="submit"
          className={`flex-1 rounded-r-md border border-gray-200 text-xs text-gray-500 shadow-sm lg:rounded-r-xl lg:text-sm ${globalChats.dataFilter === 'adam' ? 'bg-gray-100' : 'bg-white'}`}
          onClick={onFilterChange('adam')}
        >
          Adam
        </button>
      </div>

      {
        globalChats.dataFilter === 'adam' && (
          <div className="w-full">
            <div className="flex w-full justify-end">
              <Button
                icon={<PencilSquareIcon className="w-5 h-5" />}
                onClick={() => navigate(ROUTES.NEW_ADAM_CHAT)}
                theme="transparent"
                variant="small"
              />
            </div>
          </div>
        )
      }

      <div
        className="relative w-full flex-1 overflow-hidden"
        ref={containerRef}
      >
        {isInitialLoading && isLoading ? (
          <LoadingOverlay
            visible
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
        ) : (
          <ScrollAreaWrapper className="h-full" onScrolledEnd={loadChats}>
            {chats.map((chat) => (
              <ChatPreview
                key={chat.id}
                id={chat.id}
                title={
                  chat.user?.roles.includes(UserRole.AiAssistant)
                    ? decodeEntities(chat.title)
                    : chat.business.name
                }
                status={chat.lastMessageRefType || chat.lastMessage}
                selectedChatId={selectedChatId}
                lastMessageTime={chat.lastMessageCreatedAt}
                isAdamChat={chat.user?.roles.includes(UserRole.AiAssistant)}
                onClick={onChatPreviewClick}
              />
            ))}
            <div className="relative flex min-h-5 w-full items-center justify-center">
              {isLoading && isEndReached && <Loader size="xs" color="blue" />}
            </div>
            {!isLoading && chats?.length === 0 && (
              <div className="flex w-full items-center justify-center">
                <p className="text-sm text-gray-500">No chats found</p>
              </div>
            )}
          </ScrollAreaWrapper>
        )}
      </div>
    </div>
  );
});

export default ChatList;
