import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Loader } from '@mantine/core';

import { UserInitialsAvatar } from 'components/ui';
import { User } from 'features/user/models/User';
import { getUserContact, getUserName } from 'features/user/utils/user';
import { Inbox } from 'models/Inbox';

interface Props {
  users: User[];
  setUsers: (users: User[]) => void;
  isUsersLoading: boolean;
  loadUsers: (params: { ids: string[] }) => void;
  didEndReached: boolean;

  editingInbox: Inbox;
  setEditingInbox: (inbox: Inbox) => void;
}

const ParticipantList = ({
  users, setUsers, isUsersLoading, loadUsers, didEndReached, editingInbox, setEditingInbox,
}: Props) => (
  <div className="space-y-2">
    {users.map((user: User) => (
      <div
        key={user.id}
        className="flex items-center justify-between p-3 bg-gray-50 rounded-lg group"
      >
        <div className="flex gap-3 items-center">
          <UserInitialsAvatar user={user} />
          <div>
            <p className="font-medium">{getUserName(user)}</p>
            <p className="text-sm text-gray-500">{getUserContact(user)}</p>
          </div>
        </div>
        <ActionIcon
          variant="transparent"
          className="opacity-0 group-hover:opacity-100 transition-opacity"
          onClick={() => {
            setEditingInbox({
              ...editingInbox,
              participantUserIds: editingInbox.participantUserIds.filter((userId) => userId !== user.id),
            });
            setUsers(users.filter((u) => u.id !== user.id));
          }}
        >
          <FontAwesomeIcon icon={faTrashCan} className="text-red-500" />
        </ActionIcon>
      </div>
    ))}

    {
        isUsersLoading && (
          <div className="flex justify-center items-center">
            <Loader size="sm" />
          </div>
        )
      }

    {
        !didEndReached && !isUsersLoading && (
          <button
            type="button"
            className="flex justify-center items-center py-1 w-full"
            onClick={() => {
              loadUsers({
                ids: editingInbox.participantUserIds,
              });
            }}
          >
            Load more
          </button>
        )
      }
  </div>
);

export default ParticipantList;
