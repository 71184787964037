import { useState } from 'react';
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';

export const useTransformerUtils = () => {
  const [wheelSmoothStep, setWheelSmoothStep] = useState(0.003);

  const onWheelStart = (ref: ReactZoomPanPinchRef, event: WheelEvent) => {
    if (!event.ctrlKey) {
      setWheelSmoothStep(0.003);
    } else {
      setWheelSmoothStep(0.02);
    }
  };

  return {
    wheelSmoothStep,
    onWheelStart,
  };
};
