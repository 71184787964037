import {
  useState, useCallback, useRef,
} from 'react';

import { httpGetV1 } from 'utils/xhr';
import { User } from 'features/user/models/User';
import { doesExist } from 'utils/comparison';

type FetchUsersParams = {
  query?: string;
  ids?: string[];
  limit?: number;
  cursor?: string;
};

const useFetchUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [didEndReached, setDidEndReached] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const cursor = useRef(null);

  const loadUsers = useCallback(
    async ({
      searchValue,
      ids,
      reset,
    }: {
      searchValue?: string;
      ids?: string[];
      reset?: boolean;
    }): Promise<User[]> => {
      if (doesExist(searchValue) || reset) {
        cursor.current = null;
        setDidEndReached(false);
        setUsers([]);
      }

      if (didEndReached) {
        return [];
      }

      setIsLoading(true);

      const params: FetchUsersParams = {
        cursor: cursor.current,
      };

      if (doesExist(searchValue)) {
        params.query = searchValue;
      }

      if (doesExist(ids)) {
        params.ids = ids;
      }

      return httpGetV1('/businesses/me/users', {
        params,
        classType: User,
      })
        .then((response) => {
          if (reset) {
            setUsers(response.data.result as User[]);
          } else {
            setUsers((prev) => [...prev, ...(response.data.result as User[])]);
          }
          cursor.current = response.data.cursor;

          if (!response.data.cursor) {
            setDidEndReached(true);
          }

          return response.data.result as User[];
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [didEndReached],
  );

  return {
    users,
    setUsers,
    isLoading,
    loadUsers,
    didEndReached,
  };
};

export { useFetchUsers };
