import { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { sortExtractedProducts } from 'features/order/utils/order';
import { Group } from 'features/instruction/constants/prompt';

import { ProductDetailsHeader, ProductExpandableCard } from './components';
import ErrorsTooltip from './components/ErrorsTooltip';

const ProductsDetails = () => {
  const {
    order, productFields, sortExtractedProductsBy, getErrors, getWarnings, resetGroupErrorsAndWarnings,
  } = useProcessOrderContext();

  const [isSortLoading, setIsSortLoading] = useState(false);
  const [sortedProducts, setSortedProducts] = useState(order?.products || []);

  const productNameField = productFields.find((field) => field.path.endsWith('name')) || null;
  const productQuantityField = productFields.find((field) => field.path.endsWith('quantity')) || null;
  const productUnitField = productFields.find((field) => field.path.endsWith('unit')) || null;

  const errors = getErrors(order?.id);
  const warnings = getWarnings(order?.id);

  const errorsKeys = useMemo(() => Object.keys(errors[Group.Product] || {}), [errors]);
  const productErrors = Object.values(errors[Group.Product] || {}).filter((error) => error !== '');
  const warningsKeys = useMemo(() => Object.keys(warnings[Group.Product] || {}), [warnings]);

  useEffect(() => {
    setIsSortLoading(true);
    setSortedProducts(sortExtractedProducts(order?.products, sortExtractedProductsBy, errorsKeys, warningsKeys) ?? []);
    setIsSortLoading(false);
  }, [order?.products, sortExtractedProductsBy, errorsKeys, warningsKeys]);

  useEffect(() => {
    // TODO(chihirokuya): fix this dirty hack
    if (!order?.products || order?.products?.length === 0) {
      resetGroupErrorsAndWarnings(order?.id, Group.Product);
    }
  }, [order?.products, resetGroupErrorsAndWarnings, order?.id]);

  if (productFields.length === 0) {
    return (
      <div>
        <div className="flex gap-2 items-center mb-1">
          <p className="font-semibold">Product Details</p>
        </div>
        <div className="text-sm text-gray-500 pt-20 text-center">
          Choose a customer to see product details
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex gap-2 items-center mb-1">
        <p className="font-semibold">Product Details</p>
        {productErrors.length > 0 && (
        <ErrorsTooltip errors={productErrors}>
          <div className="flex items-center justify-center cursor-pointer rounded-full text-red-500 font-bold text-sm">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
            {productErrors.length}
          </div>
        </ErrorsTooltip>
        )}
      </div>

      <ProductDetailsHeader
        productLength={sortedProducts.length}
      />

      {
        sortedProducts.length === 0 && (
          <div className="text-sm text-gray-500 pt-20 text-center">
            No products added yet. Please add at least one product.
          </div>
        )
      }

      <div className="space-y-2">
        {
          isSortLoading ? (
            <div className="text-sm text-gray-500 pt-20 text-center">
              Sorting products...
            </div>
          ) : (
            sortedProducts.map((product) => (
              <ProductExpandableCard
                key={product.uiId}
                positionId={product.positionId}
                customerId={order?.customer?.id}
                product={product}
                productNameField={productNameField}
                productQuantityField={productQuantityField}
                productUnitField={productUnitField}
              />
            ))
          )
        }
      </div>
    </div>
  );
};

export default ProductsDetails;
