import { useCallback, useMemo, useState } from 'react';
import { LoadingOverlay } from '@mantine/core';

import { genericErrorFeedback } from 'utils/errors';
import { httpPostV1 } from 'utils/xhr';
import { Subject } from 'features/user/models/User';
import { useFetchOrganizations } from '../../../../hooks/fetch/useFetchOrganizations';
import { FlashCard } from '../../../ui/FlashCard';
import { Select, SelectOption } from '../../../ui/Select';
import { globalAlertData } from '../../../../state/globalAlertData';
import { Button } from '../../../ui/Button';

interface Props {
  subject?: Subject;
  onComplete: (subject: Subject) => void;
}

const _Body = ({ subject, onComplete }: Props) => {
  const {
    organizations,
    isLoading: isOrgsLoading,
    loadOrganizations,
    setSearchQuery,
  } = useFetchOrganizations({});

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<SelectOption>(
    () => null,
  );

  const orgsOptions = useMemo(
    () => organizations.map((_organization) => ({
      label: `${_organization.name}`,
      value: _organization.id,
    })),
    [organizations],
  );

  const onOrganizationSelectChange = useCallback(
    (selected: { value: string; label: string }) => {
      setSelectedOrganization(selected);
    },
    [setSelectedOrganization],
  );

  const onOrganizationsSearchChange = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery],
  );

  const onOrganizationsSelectOptionsScrolledEnd = useCallback(() => {
    loadOrganizations({});
  }, [loadOrganizations]);

  const onConfirmAssignButtonClick = useCallback(() => {
    if (subject && selectedOrganization) {
      setIsLoading(true);
      httpPostV1(`/businesses/me/subjects/${subject.id}/assign`, {
        organizationId: selectedOrganization.value,
      })
        .then(({ data }) => {
          onComplete(data.subject);
          globalAlertData.create('Contact assigned successfully');
        })
        .catch(genericErrorFeedback('An error has occured while assigning this contact to a customer'))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [subject, onComplete, selectedOrganization]);

  return (
    <div className="space-y-5 px-1">
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ type: 'dots' }}
        overlayProps={{ blur: 2 }}
      />
      <FlashCard
        title="Migration notice"
        theme="warning"
        description="Once you assign this contact to a customer, its messages, orders and other references will be migrated to the selected customer permanently."
      />

      <Select
        label="Select a customer"
        selectedOption={selectedOrganization}
        options={orgsOptions}
        isLoading={isOrgsLoading}
        isExternalSearchEnabled
        onSelectionChange={onOrganizationSelectChange}
        onSearchPerformed={onOrganizationsSearchChange}
        onScrolledEnd={onOrganizationsSelectOptionsScrolledEnd}
      />

      {/* Set bottom padding to give space for the select */}
      <div className="flex justify-end border-t pb-[400px] pt-3">
        <Button
          title="Confirm"
          onClick={onConfirmAssignButtonClick}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export { _Body };
