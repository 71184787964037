import { twMerge } from 'tailwind-merge';

import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';
import { Attachment, Message } from 'features/message/models/Message';
import { OrderBody } from 'components/chat/Chat/ChatMessage/MessageBody/OrderBody';

interface Props {
  message: Message;
  docs: Attachment[];
  imageLength: number;
  handleAttachmentClick: (index: number) => void;
}

const FocusModeText = ({
  message,
  docs,
  imageLength,
  handleAttachmentClick,
}: Props) => (
  <>
    <div className={twMerge(
      'divide-y-4 text-content-smd text-lg lg:text-content-md 4xl:text-base',
      imageLength > 0 && 'flex-1',
    )}
    >
      <OrderBody message={message} />
    </div>

    {
      imageLength > 0 && (
        // A preview of the image
        <div className="space-y-3 overflow-x-auto min-h-fit">
          <div className="text-gray-800 font-semibold text-md">
            Attachments
          </div>

          <div className="flex gap-2">
            {
              docs.filter((doc) => doc.imagifiedPages?.length > 0).map((doc, index) => (
                <div key={doc.name}>
                  <div className="w-[200px] h-[120px] overflow-hidden rounded-lg relative group mb-3 4xl:mb-6">
                    <img src={doc.imagifiedPages[0].url} alt="Document Preview" className="w-full h-full object-cover" />

                    <div className="absolute bottom-0 left-0 bg-black w-full text-white px-3 flex items-center h-[30px] 4xl:h-[43px] text-sm 4xl:text-base">
                      <OverflowEllipsisParagraph maxLines={1}>
                        {doc.name}
                      </OverflowEllipsisParagraph>
                    </div>

                    <div
                      className="group-hover:block hidden absolute top-0 left-0 w-full h-[calc(100%-30px)] 4xl:h-[calc(100%-43px)] bg-black/50"
                      role="button"
                      onClick={() => handleAttachmentClick(index)}
                      onKeyDown={null}
                      tabIndex={0}
                    >
                      <div className="flex items-center justify-center h-full">
                        <div className="text-white text-sm">
                          Click to view
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      )
    }
  </>
);

export default FocusModeText;
