import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { observer } from 'mobx-react-lite';

import { globalOrderDrafts } from 'state/globalOrderDrafts';
import { Page } from 'components/layout/Page/Page';
import { useFetchOrderDrafts } from 'features/order/api/useFetchOrderDrafts';
import { ListNavigator } from 'components/common/ListNavigator';
import { useOrderDraftNavigation } from 'features/order/hooks/useOrderDraftNavigation';
import { OrderProvider } from 'features/order/contexts/useOrderContext';
import { PlayRecordingProvider } from 'contexts/usePlayRecordingIndex';
import { TeamMembersProvider } from 'contexts/useTeamMembersContext';
import {
  MessageProviderType,
  MessagesProvider,
} from 'contexts/useMessagesContext';
import { BusinessSettingsProvider } from 'contexts/useBusinessSettingsContext';
import { SchemasProvider } from 'contexts/useSchemasContext';

import { ProcessOrderDrafts } from 'features/order/components/process-order-draft';
import { ProcessOrderProvider } from 'features/order/contexts/useProcessOrderContext';
import { isZeroId } from 'utils/objectId';
import { Order } from '../models/Order';

interface Props {
  navigateToHome: () => void;
}

const ProcessOrderDraftsPage = observer(({ navigateToHome }: Props) => {
  // Hacky solution to force reset image index on index change or order processed
  const [resetTrigger, setResetTrigger] = useState(0);

  const {
    orderDrafts,
    setOrderDrafts,
    isLoading: isOrderDraftsLoading,
    loadOrderDrafts,
    reloadOrderDrafts,
  } = useFetchOrderDrafts({});

  const {
    currentIndex,
    setCurrentIndex,
    selectNextOrderDraft,
    selectPrevOrderDraft,
    onOrderDraftProcessed: onOrderDraftProcessed_,
  } = useOrderDraftNavigation({
    orderDrafts,
    setOrderDrafts,
    loadOrderDrafts,
    isOrderDraftsLoading,
  });

  const order = useMemo(() => {
    if (currentIndex !== -1) {
      return orderDrafts[currentIndex];
    }
    return null;
  }, [orderDrafts, currentIndex]);

  const messageReferenceId = useMemo(() => {
    if (order?.groupId && !isZeroId(order.groupId)) {
      return order.groupId;
    }

    return order?.id;
  }, [order?.groupId, order?.id]);

  const assignGlobalCurrentOrder = useCallback((assigneeId: string) => {
    setOrderDrafts((prev) => prev.map((order_, index) => (
      index === currentIndex ? { ...order_, assigneeId } : order_
    )));
  }, [currentIndex, setOrderDrafts]);

  const updateGlobalCurrentOrder = useCallback((newOrder: Order) => {
    setOrderDrafts((prev) => prev.map((order_, index) => (
      index === currentIndex ? newOrder : order_
    )));
  }, [currentIndex, setOrderDrafts]);

  // if not draft is left, go to home
  useEffect(() => {
    if (!isOrderDraftsLoading && globalOrderDrafts.count === 0) {
      navigateToHome();
    }

    if (globalOrderDrafts.isLoaded && globalOrderDrafts.count === 0) {
      navigateToHome();
    }
  }, [isOrderDraftsLoading, navigateToHome]);

  useEffect(() => {
    if (!globalOrderDrafts.isLoaded && globalOrderDrafts.count === 0) {
      globalOrderDrafts.loadOrderDraftsCount();
    }
  }, []);

  const onOrderDraftRetried = async (retriedOrderId: string) => {
    const latestOrderDrafts = await reloadOrderDrafts();
    const updatedIndex = latestOrderDrafts.findIndex((draft) => draft.id === retriedOrderId);
    if (updatedIndex !== -1) {
      setCurrentIndex(updatedIndex);
    }
  };

  const onOrderDraftProcessed = useCallback((orderId: string) => {
    setResetTrigger((prev) => prev + 1);
    onOrderDraftProcessed_(orderId);
  }, [onOrderDraftProcessed_]);

  return (
    <Page isLoading={false} contentWithBorder>
      <ListNavigator
        listLength={globalOrderDrafts.count || orderDrafts.length}
        currentIndex={currentIndex}
        onPrev={() => {
          const wasDecremented = selectPrevOrderDraft();
          if (wasDecremented) {
            setResetTrigger((prev) => prev + 1);
          }
        }}
        onNext={() => {
          selectNextOrderDraft();
          setResetTrigger((prev) => prev + 1);
        }}
      />
      <PlayRecordingProvider>
        <MessagesProvider
          providerType={MessageProviderType.BY_REFERENCE_ID}
          referenceId={messageReferenceId}
        >
          <BusinessSettingsProvider>
            <OrderProvider>
              <SchemasProvider>
                <ProcessOrderProvider
                  resetTrigger={resetTrigger}
                  onOrderDraftProcessed={onOrderDraftProcessed}
                  updateGlobalCurrentOrder={updateGlobalCurrentOrder}
                >
                  <TeamMembersProvider>
                    <ProcessOrderDrafts
                      order={order}
                      isOrderLoading={isOrderDraftsLoading}
                      onOrderRetried={onOrderDraftRetried}
                      assignGlobalCurrentOrder={assignGlobalCurrentOrder}
                    />
                  </TeamMembersProvider>
                </ProcessOrderProvider>
              </SchemasProvider>
            </OrderProvider>
          </BusinessSettingsProvider>
        </MessagesProvider>
      </PlayRecordingProvider>
    </Page>
  );
});

export default ProcessOrderDraftsPage;
