import {
  useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { LoadingOverlay } from '@mantine/core';

import { formatPrice } from 'utils/formatting';
import { getUnits } from 'features/product/utils/product';
import { Product } from '../models/Product';
import { ScrollAreaWrapper } from '../../../components/wrapper/ScrollAreaWrapper';

interface Props {
  products: Product[];
  openDialog: (idx: number) => void;
  isLoading: boolean;
  onScrolledToEnd: () => void;
}

const ProductsTable = ({
  products,
  openDialog,
  isLoading,
  onScrolledToEnd,
}: Props) => {
  const lastRowRef = useRef<HTMLTableRowElement | null>(null);

  const checkbox = useRef<HTMLInputElement>();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useLayoutEffect(() => {
    if (selectedProducts != null && products != null) {
      const isIndeterminate = selectedProducts.length > 0
        && selectedProducts.length < products.length;
      setChecked(selectedProducts.length === products.length);
      setIndeterminate(isIndeterminate);
      if (checkbox && checkbox.current) {
        checkbox.current.indeterminate = isIndeterminate;
      }
    }
  }, [selectedProducts, products]);

  const onDetailsButtonClick = useCallback(
    (idx: number) => {
      openDialog(idx);
    },
    [openDialog],
  );

  const toggleAll = useCallback(() => {
    setSelectedProducts(checked || indeterminate ? [] : products);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }, [checked, indeterminate, products]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onScrolledToEnd();
        }
      },
      { threshold: 0.5 },
    );

    const currentLastRow = lastRowRef.current;
    if (currentLastRow) {
      observer.observe(currentLastRow);
    }

    return () => {
      if (currentLastRow) {
        observer.unobserve(currentLastRow);
      }
    };
  }, [onScrolledToEnd, products]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden rounded-md border border-solid">
      <ScrollAreaWrapper
        className="flex-1"
        offsetScrollbar={false}
      >
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="sticky top-0 z-10 bg-primary-50 text-sm font-semibold text-neutral-600 drop-shadow">
            <tr>
              <th scope="col" className="relative px-lg py-smd">
                <input
                  type="checkbox"
                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-500"
                  ref={checkbox}
                  checked={checked}
                  onChange={toggleAll}
                />
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                ID or SKU
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Name
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Category
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-right"
              >
                Price
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Unit(s)
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-right"
              >
                Min Order Qty
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-center"
              >
                Details
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {products.map((product, idx) => (
              <tr
                ref={idx === products.length - 1 ? lastRowRef : null}
                key={product.id}
                className={`${selectedProducts.includes(product) && 'bg-gray-50'} hover:bg-gray-50`}
              >
                <td className="relative px-7 sm:w-12 sm:px-xl">
                  {selectedProducts.includes(product) && (
                    <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-500" />
                  )}
                  <input
                    type="checkbox"
                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-500"
                    value={product.category}
                    checked={selectedProducts.includes(product)}
                    onChange={(e) => setSelectedProducts(
                      e.target.checked
                        ? [...selectedProducts, product]
                        : selectedProducts.filter((p) => p !== product),
                    )}
                    onClick={(e) => e.stopPropagation()}
                  />
                </td>
                <td className="px-lg py-lg text-sm text-gray-500">
                  {product.idOrSku}
                </td>
                <td className="px-lg py-lg text-sm text-gray-500">
                  {product.name}
                </td>
                <td className="px-lg py-lg text-sm text-gray-500">
                  {product.category}
                </td>
                <td className="relative top-[0.5px] whitespace-nowrap px-lg py-lg text-right !font-mono text-sm text-gray-500">
                  {formatPrice(
                    product?.price?.amount || 0,
                    product?.price?.currency,
                  )}
                </td>
                <td className="px-lg py-lg text-sm text-gray-500">
                  {getUnits(product)
                    .map((u) => u.toLowerCase())
                    .join(',')}
                </td>
                <td className="relative top-[0.5px] px-lg py-lg text-right !font-mono text-sm text-gray-500">
                  {product.minOrderQty}
                </td>
                <td className="flex items-center justify-center px-lg py-lg">
                  <button
                    type="button"
                    className="text-sm font-semibold text-primary-500"
                    onClick={() => onDetailsButtonClick(idx)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          className={`relative flex h-10 w-full items-center justify-center ${!isLoading && 'hidden'}`}
        >
          <LoadingOverlay
            visible={isLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
        </div>
      </ScrollAreaWrapper>
    </div>
  );
};

export default ProductsTable;
