import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useOrderContext } from 'features/order/contexts/useOrderContext';
import { twMerge } from 'tailwind-merge';
import { Order } from 'features/order/models/Order';
import { globalUser } from 'state/globalUser';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';

const Tabs = () => {
  const {
    groupOrders, selectedIndex, setSelectedIndex, addOrder, removeOrderById, addDeletedOrderId,
  } = useOrderContext();
  const { onTabChange } = useProcessOrderContext();

  const handleTabClick = (index: number) => {
    onTabChange(index);
  };

  const handleDeleteButtonClick = (index: number) => {
    removeOrderById(groupOrders[index].id);
    addDeletedOrderId(groupOrders[index].id);
    if (selectedIndex === index) {
      setSelectedIndex(Math.max(0, selectedIndex - 1));
    }
  };

  const onAddButtonClick = () => {
    const newOrder = new Order();
    newOrder.id = `new-${groupOrders.length}`;
    newOrder.assigneeId = globalUser.id;
    newOrder.isCreated = true;
    newOrder.didChangeMade = true;
    addOrder(newOrder);
    onTabChange(groupOrders.length);
  };

  return (
    <div className="flex gap-2">
      {
        groupOrders.map((order, index) => (
          <div
            key={`${order.id}-${index.toString()}`}
            className={twMerge(
              'rounded-l-full rounded-r-full min-w-fit px-4 py-2 group flex items-center cursor-pointer',
              index === selectedIndex ? 'bg-black text-white' : 'border-gray-300 border',
            )}
            role="button"
            tabIndex={0}
            onClick={() => handleTabClick(index)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleTabClick(index);
              }
            }}
          >
            {`Order-${index + 1}`}

            {
              groupOrders.length > 1 && (
                <button
                  type="button"
                  className="hidden ml-2 group-hover:block"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteButtonClick(index);
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              )
            }
          </div>
        ))
      }
      <button
        type="button"
        className="border border-gray-300 px-4 py-2 aspect-square rounded-full"
        aria-label="Add new item"
        onClick={onAddButtonClick}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  );
};

export default Tabs;
