import { useEffect, useRef } from 'react';
import { InputLabel } from '@mantine/core';

import { useFetchUsers } from 'features/user/apis/useFetchUsers';
import { TeammemberSelectButton } from 'features/user/components/teammember-select-button';
import { Inbox } from 'models/Inbox';
import ParticipantList from './ParticipantList';

interface Props {
  editingInbox: Inbox;
  setEditingInbox: (inbox: Inbox) => void;
}

const ParticipantsSection = ({ editingInbox, setEditingInbox }: Props) => {
  const {
    users, setUsers, isLoading: isUsersLoading, loadUsers, didEndReached,
  } = useFetchUsers();

  const firstFetch = useRef(true);

  useEffect(() => {
    if (firstFetch.current) {
      loadUsers({
        ids: editingInbox.participantUserIds,
      });
      firstFetch.current = false;
    }
  }, [editingInbox.participantUserIds, loadUsers]);

  return (
    <div>
      <InputLabel required>
        Participants
      </InputLabel>
      <TeammemberSelectButton
        onSelect={(user) => {
          if (editingInbox?.participantUserIds.includes(user.id)) return;

          setEditingInbox({
            ...editingInbox,
            participantUserIds: [user.id, ...editingInbox.participantUserIds],
          });
          setUsers([user, ...users]);
        }}
        toExcludeIds={editingInbox?.participantUserIds}
        position="bottom-end"
        width="80%"
      />
      <ParticipantList
        users={users}
        setUsers={setUsers}
        isUsersLoading={isUsersLoading}
        loadUsers={loadUsers}
        didEndReached={didEndReached}
        editingInbox={editingInbox}
        setEditingInbox={setEditingInbox}
      />
    </div>
  );
};

export default ParticipantsSection;
