import { globalInboxes } from './globalInboxes';
import { globalLROs } from './globalLROs';
import { globalMessageIntents } from './globalMessageIntents';
import { globalNotifications } from './globalNotifications';
import { globalOrderDrafts } from './globalOrderDrafts';
import { globalThreads } from './globalThreads';

export const refreshGlobalStates = () => {
  globalOrderDrafts.refresh();
  globalLROs.refresh();
  globalMessageIntents.refresh();
  globalThreads.refresh();
  globalNotifications.refresh();
  globalInboxes.refresh();
};
