import {
  useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { LoadingOverlay } from '@mantine/core';

import { Business } from 'models/Business';
import { ScrollAreaWrapper } from 'components/wrapper/ScrollAreaWrapper';

interface Props {
  customers: Business[];
  openDialog: (idx: number) => void;
  isLoading: boolean;
  onScrolledToEnd: () => void;
}

const CustomersTable = ({
  customers,
  openDialog,
  isLoading,
  onScrolledToEnd,
}: Props) => {
  const lastRowRef = useRef<HTMLTableRowElement | null>(null);
  const checkbox = useRef<HTMLInputElement>();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  useLayoutEffect(() => {
    if (selectedCustomers != null && customers != null) {
      const isIndeterminate = selectedCustomers.length > 0
        && selectedCustomers.length < customers.length;
      setChecked(selectedCustomers.length === customers.length);
      setIndeterminate(isIndeterminate);
      if (checkbox && checkbox.current) {
        checkbox.current.indeterminate = isIndeterminate;
      }
    }
  }, [selectedCustomers, customers]);

  const onDetailsButtonClick = useCallback(
    (idx: number) => {
      openDialog(idx);
    },
    [openDialog],
  );

  const toggleAll = useCallback(() => {
    setSelectedCustomers(checked || indeterminate ? [] : customers);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }, [checked, indeterminate, customers]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onScrolledToEnd();
        }
      },
      { threshold: 0.5 },
    );

    const currentLastRow = lastRowRef.current;
    if (currentLastRow) {
      observer.observe(currentLastRow);
    }

    return () => {
      if (currentLastRow) {
        observer.unobserve(currentLastRow);
      }
    };
  }, [onScrolledToEnd, customers]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden rounded-md border border-solid">
      <ScrollAreaWrapper
        className="flex-1"
        offsetScrollbar={false}
      >
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="sticky top-0 z-10 bg-primary-50 text-sm font-semibold text-neutral-600 drop-shadow">
            <tr>
              <th scope="col" className="relative px-lg py-smd">
                <input
                  type="checkbox"
                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-500"
                  ref={checkbox}
                  checked={checked}
                  onChange={toggleAll}
                />
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Name
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Phones
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Email
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Address
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-center"
              >
                Details
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {customers.map((customer, idx) => (
              <tr
                ref={idx === customers.length - 1 ? lastRowRef : null}
                key={customer.id}
                tabIndex={0}
                onKeyDown={() => {}}
                className={`${selectedCustomers.includes(customer) && 'bg-gray-50'} hover:bg-gray-50`}
              >
                <td className="relative px-7 sm:w-12 sm:px-xl">
                  {selectedCustomers.includes(customer) && (
                    <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-500" />
                  )}
                  <input
                    type="checkbox"
                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-500"
                    value={customer.id}
                    checked={selectedCustomers.includes(customer)}
                    onChange={(e) => setSelectedCustomers(
                      e.target.checked
                        ? [...selectedCustomers, customer]
                        : selectedCustomers.filter((p) => p !== customer),
                    )}
                    onClick={(e) => e.stopPropagation()}
                  />
                </td>
                <td className="px-lg py-lg text-sm text-gray-500">
                  {customer.name}
                </td>
                <td className="max-w-[15ch] text-ellipsis px-lg py-lg text-sm text-gray-500">
                  {customer.phones?.map((p) => p.number).join(', ') || '-'}
                </td>
                <td className="px-lg py-lg text-sm text-gray-500">
                  {customer.email || '-'}
                </td>
                <td className="px-lg py-lg text-sm text-gray-500">
                  {customer.address || '-'}
                </td>
                <td className="flex items-center justify-center px-lg py-lg">
                  <button
                    type="button"
                    className="text-sm font-semibold text-primary-400"
                    onClick={() => onDetailsButtonClick(idx)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          className={`relative flex h-10 w-full items-center justify-center ${!isLoading && 'hidden'}`}
        >
          <LoadingOverlay
            visible={isLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
        </div>
      </ScrollAreaWrapper>
    </div>
  );
};

export default CustomersTable;
