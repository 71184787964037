import { string, setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'This is a required field',
  },
});

const username = () => string().matches(
  // eslint-disable-next-line
    /^[a-zA-Z0-9\._]{2,24}$/,
  'Invalid username',
);

const password = () => string().matches(
  // eslint-disable-next-line
    /^(?:(?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))(?!.*(.)\1{2,})[A-Za-z0-9!~<>,;:_=?*+#."&§%°()\|\[\]\-\$\^\@\/]{8,24}$/,
  'Password must be at least 8 characters long and include at least one symbol (e.g., !@#$%^&*), one number, one uppercase letter, and one lowercase letter.',
);

const phone = () => string().matches(
  /^(\+41\d{9}|\+43\d{10}|\+49\d{10,14})$/,
  'Phone number is not valid (must be a valid DACH number in E.164 format)',
);

export {
  username,
  password,
  phone,
};
