import { useEffect, useState } from 'react';
import { MantineProvider, MantineTheme, DEFAULT_THEME } from '@mantine/core';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';

import { initGlobalLROs } from 'state/globalLROs';
import { initGlobalInboxes } from 'state/globalInboxes';
import { AppGlobalProviders } from './AppGlobalProviders';
import { LoadingIndicator } from './components/LoadingIndicator';
import { Routing } from './routing/Routing';
import { globalAlertData, initGlobalAlertData } from './state/globalAlertData';
import { initGlobalUser } from './state/globalUser';
import { initDateTimeHelper } from './utils/dateTime';
import { initGlobalChats } from './state/globalChats';
import { initGlobalEffects } from './state/globalEffects';
import { initGlobalOrderDrafts } from './state/globalOrderDrafts';
import { initGlobalSseSources } from './state/globalSseSources';
import { initGlobalMessageIntents } from './state/globalMessageIntents';
import { initGlobalThreads } from './state/globalThreads';
import { initGlobalNotifications } from './state/globalNotifications';

import '@mantine/carousel/styles.css';
import '@mantine/charts/styles.css';

const theme: MantineTheme = {
  ...DEFAULT_THEME,
  breakpoints: {
    ...DEFAULT_THEME.breakpoints,
    '4xl': '2000px',
  },
  primaryColor: 'blue',
  colors: {
    ...DEFAULT_THEME.colors,
    blue: [
      '#e8f3ff',
      '#b8d9ff',
      '#96c6ff',
      '#66adff',
      '#499dff',
      '#1b84ff',
      '#1978e8',
      '#135eb5',
      '#0f498c',
      '#0b376b',
    ],
  },
};

const App = () => {
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    // Indipendent helpers and logics init
    initDateTimeHelper();

    // Persisted mobx states and other async inits
    const asyncInits = [initGlobalUser(), initGlobalChats()];

    Promise.all(asyncInits).then(() => {
      // Not persisted mobx states and other sync inits
      initGlobalOrderDrafts();
      initGlobalAlertData();
      initGlobalSseSources();
      initGlobalLROs();
      initGlobalMessageIntents();
      initGlobalThreads();
      initGlobalNotifications();
      initGlobalInboxes();

      initGlobalEffects();
      setAppIsReady(true);
    });

    // Init Sentry
    Sentry.init({
      environment: ENVIRONMENT,
      dsn: 'https://6eebf3e818d26259bb43478267c302d7@o4508245135065088.ingest.de.sentry.io/4508245137752144',
      integrations: [
        Sentry.feedbackIntegration({
          autoInject: false,
          colorScheme: 'system',
          triggerLabel: 'Issue or Feature Request?',
          formTitle: 'Give Feedback',
          messagePlaceholder: 'What is the issue you are facing or feature you would like to see on Hoshii?',
          submitButtonLabel: 'Submit',
          isNameRequired: true,
          isEmailRequired: true,
        }),
        Sentry.replayIntegration({
          maskAllText: SENTRY_REPLAYS_MASK_ALL_TEXT,
          blockAllMedia: SENTRY_REPLAYS_BLOCK_ALL_MEDIA,
        }),
      ],
      // Session replay
      replaysSessionSampleRate: SENTRY_REPLAYS_SAMPLE_RATE,
      replaysOnErrorSampleRate: SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE, // Sample rate when errors occur
    });
  }, []);

  if (!appIsReady) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <LoadingIndicator isLoading />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <script src="https://hoshii-dev.statuspage.io/embed/script.js" />
      </Helmet>

      <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
        <AppGlobalProviders alertData={globalAlertData}>
          <MantineProvider theme={theme}>
            <Routing />
          </MantineProvider>
        </AppGlobalProviders>
      </GoogleOAuthProvider>
    </>
  );
};

export { App };
