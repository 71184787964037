import { useState, useCallback, useEffect } from 'react';

import { httpGetV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';
import { isZeroId } from 'utils/objectId';
import { WorkflowRun } from '../../models/Workflow';

interface Props {
  id: string;
  preventFetch?: boolean;
}

const useFetchWorkflowRun = ({
  id,
  preventFetch = true,
}: Props) => {
  const [workflowRun, setWorkflowRun] = useState<WorkflowRun | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const resetParams = () => {
    setWorkflowRun(null);
  };

  const loadWorkflowRun = useCallback(
    async (giveErrorFeedback: boolean = false) => {
      if (!id) return;

      setIsLoading(true);
      httpGetV1(`/workflows/runs/${id}`)
        .then((response) => {
          const responseData: WorkflowRun = response.data;
          setWorkflowRun(responseData);
        })
        .catch((err) => {
          if (giveErrorFeedback) genericErrorFeedback('Error getting workflow run')(err);
        })
        .finally(() => setIsLoading(false));
    },
    [id],
  );

  useEffect(() => {
    if (!id || id === '' || isZeroId(id)) return () => {};

    if (preventFetch) return () => {};

    resetParams();

    const controller = new AbortController();

    loadWorkflowRun();

    return () => {
      controller.abort(); // Abort the fetch when orderId changes or component unmounts
    };
  }, [loadWorkflowRun, id, preventFetch]);

  return { workflowRun, isLoading, loadWorkflowRun };
};

export { useFetchWorkflowRun };
