import { Page } from 'components/layout/Page/Page';
import { ThreadListTable } from '../components/thread-list';
import { InboxList } from '../components/inbox-list';

const InboxPage = () => (
  <Page contentWithPadding>
    <div className="flex-1 overflow-hidden flex gap-4">
      <div className="h-full w-[15%] 4xl:w-[10%] bg-white py-4 px-2 rounded-md 4xl:py-5 4xl:px-4 4xl:rounded-lg border border-solid">
        <InboxList />
      </div>
      <div className="flex-1 bg-white flex flex-col overflow-hidden gap-3">
        {/* <MessageListTable /> */}
        <ThreadListTable />
      </div>
    </div>
  </Page>
);

export default InboxPage;
