import AudioPlayer from 'react-h5-audio-player';
import Dayjs from 'dayjs';
import He from 'he';
import ParseHtml, { attributesToProps } from 'html-react-parser';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { PauseIcon, PlayIcon } from '@heroicons/react/24/outline';
import { Modal, Tooltip } from 'flowbite-react';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import {
  ChevronDownIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid';

import { twMerge } from 'tailwind-merge';
import { ChatMoveMessageModal } from './modal/ChatMoveMessageModal';
import { Message, MessageContextUtils } from '../features/message/models/Message';
import { MessageSourceIcon } from './MessageSourceIcon';
import { ThreeDots } from './ThreeDots';
import {
  fillColour,
  formatMessageSource,
  getFuncName,
  getTagTitle,
  spanClassName,
} from '../utils/enums';
import { httpGetBlobV1 } from '../utils/xhr';
import { splitAtIndex } from '../utils/strings';

import 'react-h5-audio-player/lib/styles.css';
import { LoadingIndicator } from './LoadingIndicator';
import { decodeEntities } from '../features/message/utils/message';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const styles = {
  wrapper: {
    maxWidth: 600,
    minWidth: 200,
    width: 'auto',
    display: 'block',
  },
  message: {
    width: 'fit-content',
    maxWidth: 600,
    minWidth: 200,
    overflow: 'auto',
  },
  messagePrimary: {
    backgroundColor: 'rgb(241, 245, 249)',
  },
  messageWhite: {
    backgroundColor: 'white',
    border: 'solid 1px #dcdddd',
  },
  sent: {
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
  },
  received: {
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
  },
  text: {
    fontWeight: '400',
  },
  title: {
    fontWeight: '600',
  },
};

interface Props {
  message?: Message;
  direction?: 'SENT' | 'RECEIVED';
  theme?: 'dark' | 'light';
  isTextLoading?: boolean;
  isExpandable?: boolean;
  tagTitle?: string;
  isTagTitleLoading?: boolean;
  hideDateTime?: boolean;
  setToScrollKeyword?: React.Dispatch<React.SetStateAction<string>>;
  onMessageMoved: () => void;
  onKeywordMouseOver: (keyword: string) => void;
  onKeywordMouseLeave: () => void;
  onOrderDetailsButtonClick?: () => void;
}

const ChatMessage = ({
  message,
  direction,
  theme,
  isTextLoading,
  tagTitle,
  isTagTitleLoading,
  isExpandable,
  hideDateTime,
  setToScrollKeyword,
  onMessageMoved,
  onKeywordMouseOver,
  onKeywordMouseLeave,
  onOrderDetailsButtonClick,
}: Props) => {
  const [isLoadingAudio, setIsLoadingAudio] = useState(true);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [recordingSrc, setRecordingUrl] = useState<string>();
  const [isMoveMessageModalVisible, setIsMoveMessageModalVisible] = useState(false);
  const [isHtmlPreviewModalVisible, setIsHtmlPreviewModalVisible] = useState(false);
  const [lastPlayedIndex, setLastPlayedIndex] = useState(-1);
  const audioPlayerRef = useRef<{ audio: { current: HTMLMediaElement } }>();

  const [isMessageExpanded, setIsMessageExpanded] = useState(!isExpandable);

  const messageStyle = useMemo(
    () => (theme === 'dark' ? styles.messagePrimary : styles.messageWhite),
    [theme],
  );

  const directionStyle = useMemo(
    () => (direction === 'SENT' ? styles.sent : styles.received),
    [direction],
  );

  const messageDateTime = useMemo(() => {
    if (message?.createdAt) {
      const dt = Dayjs(message?.createdAt);
      const now = Dayjs();
      if (dt.isSame(now, 'day')) {
        return dt.format('LT');
      }
      return dt.format('llll');
    }
    return null;
  }, [message?.createdAt]);

  const markedText = useMemo(() => {
    if (message?.message) {
      const decodedString = He.decode(message.message);
      let decodedStringIndex = 0;
      let remainigString = decodedString;
      const tokens: string[] = [];

      let keywords: { offset: number; word: string; originalWord: string }[] = [];
      if (message.context?.workflowOrder?.audioKeywords) {
        keywords = message.context?.workflowOrder?.audioKeywords || [];
      } else if (message.context?.workflowOrder?.keywords) {
        keywords = message.context?.workflowOrder?.keywords || [];
      }

      keywords.forEach((recordingKeyword, index) => {
        const adaptedOffset = recordingKeyword.offset - decodedStringIndex;
        const [nonKeywordPart, keywordPart] = splitAtIndex(
          remainigString,
          adaptedOffset,
        );
        tokens.push(nonKeywordPart);
        const [keyword, restOfMessage] = splitAtIndex(
          keywordPart,
          recordingKeyword.word.length,
        );
        tokens.push(
          `&nbsp<mark keyword_index=${index})}>`
            + `&nbsp${keyword}&nbsp<span>${recordingKeyword.originalWord}</span></mark>&nbsp`,
        );
        const processedString = `${nonKeywordPart}${keyword}`;
        remainigString = restOfMessage;
        decodedStringIndex += processedString.length;
      });
      tokens.push(remainigString);
      return tokens.join('');
    }
    return '';
  }, [
    message.context?.workflowOrder?.keywords,
    message.context?.workflowOrder?.audioKeywords,
    message.message,
  ]);

  const loadRecording = useCallback(() => {
    if (MessageContextUtils.audioAttachments(message.context).length > 0 && isMessageExpanded) {
      setIsLoadingAudio(true);
      const tokens = MessageContextUtils.audioAttachments(message.context)[0]?.url.split('/v1/');
      const recordingRelativeUrl = tokens[1];
      httpGetBlobV1(recordingRelativeUrl)
        .then((response) => {
          setRecordingUrl(URL.createObjectURL(response.data));
          // TODO: Find a way to URL.revokeObjectURL(url) when page is left
        })
        .finally(() => setIsLoadingAudio(false));
    }
  }, [message.context, isMessageExpanded]);

  const onMessageMarkMouseOver = useCallback(
    (keyword: string) => {
      onKeywordMouseOver(keyword);
    },
    [onKeywordMouseOver],
  );

  const onMessageMarkMouseLeave = useCallback(() => {
    onKeywordMouseLeave();
  }, [onKeywordMouseLeave]);

  const onMessageClick = useCallback(
    (index: number, keyword: string) => {
      if (setToScrollKeyword) {
        setToScrollKeyword(keyword);
      }

      if (audioPlayerRef.current) {
        const start = (message.context?.workflowOrder?.audioKeywords[index].start as number)
          - 0.5;
        const end = (message.context?.workflowOrder?.audioKeywords[index].end as number)
          + 0.5;
        audioPlayerRef.current.audio.current.currentTime = start;
        audioPlayerRef.current.audio.current.play();
        setIsAudioPlaying(true);
        setLastPlayedIndex(index);

        setTimeout(
          () => {
            if (audioPlayerRef.current.audio.current) {
              audioPlayerRef.current.audio.current.pause();
              setIsAudioPlaying(false);
            }
          },
          (end - start) * 1000,
        );
      }
    },
    [message.context?.workflowOrder?.audioKeywords, setToScrollKeyword],
  );

  const onPlayButtonClick = useCallback(() => {
    if (audioPlayerRef.current?.audio.current.paused) {
      setIsAudioPlaying(true);
      audioPlayerRef.current?.audio.current.play();
    } else {
      setIsAudioPlaying(false);
      audioPlayerRef.current?.audio.current.pause();
    }
  }, []);

  const onMoveMessageModalClose = useCallback(() => {
    setIsMoveMessageModalVisible(false);
  }, []);

  const onShowHtmlPreviewButtonClick = useCallback(() => {
    setIsHtmlPreviewModalVisible(true);
  }, []);

  const onHtmlPreviewModalClose = useCallback(() => {
    setIsHtmlPreviewModalVisible(false);
  }, []);

  useEffect(() => {
    if (!recordingSrc) {
      loadRecording();
    }
  }, [loadRecording, recordingSrc]);

  // shortcut key
  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'P' && event.ctrlKey && event.shiftKey) {
        event.preventDefault();
        if (audioPlayerRef.current.audio.current.paused) {
          audioPlayerRef.current.audio.current.play();
          setIsAudioPlaying(true);
        } else {
          audioPlayerRef.current.audio.current.pause();
          setIsAudioPlaying(false);
        }
        return;
      }

      const target = event.target as HTMLElement;
      const isInputFocused = target.tagName === 'INPUT'
        || target.tagName === 'TEXTAREA'
        || target.isContentEditable;
      const isCombinedKey = event.ctrlKey || event.metaKey || event.shiftKey || event.altKey;

      if (!audioPlayerRef.current || isInputFocused) return;

      switch (event.key) {
        case ' ':
          if (!isCombinedKey) {
            if (audioPlayerRef.current.audio.current.paused) {
              audioPlayerRef.current.audio.current.play();
              setIsAudioPlaying(true);
            } else {
              audioPlayerRef.current.audio.current.pause();
              setIsAudioPlaying(false);
            }
          }
          break;
        case 's':
          if (!isCombinedKey) {
            audioPlayerRef.current.audio.current.pause();
            audioPlayerRef.current.audio.current.currentTime = 0;
            setIsAudioPlaying(false);
            setLastPlayedIndex(-1);
          }
          break;
        case 'ArrowRight':
          if (event.shiftKey) {
            const index = Math.min(
              lastPlayedIndex + 1,
              (message.context?.workflowOrder?.audioKeywords.length || 0) - 1,
            );
            onMessageClick(
              index,
              message.context?.workflowOrder?.audioKeywords[index].word,
            );
            break;
          }

          if (!isCombinedKey) {
            audioPlayerRef.current.audio.current.currentTime += 5;
          }
          break;
        case 'ArrowLeft':
          if (event.shiftKey) {
            const index = Math.max(lastPlayedIndex - 1, 0);
            onMessageClick(
              index,
              message.context?.workflowOrder?.audioKeywords[index].word,
            );
            break;
          }

          if (!isCombinedKey) {
            audioPlayerRef.current.audio.current.currentTime -= 5;
          }
          break;
        default:
          break;
      }
    },
    [lastPlayedIndex, onMessageClick, message.context?.workflowOrder?.audioKeywords],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [handleKeydown]);

  // TODO: rework
  const tag = useMemo(() => {
    if (tagTitle && tagTitle.length > 0) {
      return (
        <div className="absolute -top-[30px] right-0">
          {isTagTitleLoading ? (
            <span className="inline-flex h-1.5 w-[20px] items-center gap-x-1.5 rounded-sm bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700" />
          ) : (
            <span className={spanClassName(tagTitle)}>
              <svg
                className={twMerge(
                  'h-1.5 w-1.5 fill-blue-500 fill-green-500 fill-red-500',
                  fillColour(tagTitle),
                )}
                viewBox="0 0 6 6"
                aria-hidden="true"
              >
                <circle cx={3} cy={3} r={3} />
              </svg>
              {getTagTitle(tagTitle)}
            </span>
          )}
        </div>
      );
    }

    return null;
  }, [tagTitle, isTagTitleLoading]);

  const ellipsisButton = useMemo(() => {
    if (tagTitle && tagTitle.length > 0) {
      return (
        <div className="absolute right-0 top-0">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton
                className="w-full px-md pt-sm font-semibold"
                // onContextMenu={(e) => {
                //   e.preventDefault();
                //   if (e.nativeEvent.button === 0) {
                //     console.log('Left click');
                //   } else if (e.nativeEvent.button === 2) {
                //     console.log('Right click');
                //   }
                // }}
              >
                <EllipsisHorizontalIcon className="w-[20px]" />
              </MenuButton>
            </div>

            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems
                anchor="bottom start"
                className="z-[50] w-[20ch] origin-top-right rounded-xl border bg-white text-sm/6 shadow-md [--anchor-gap:var(--spacing-1)]"
              >
                <div className="py-1">
                  <MenuItem>
                    {({ focus }) => (
                      <button
                        type="button"
                        className={classNames(
                          focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block w-full px-4 py-2 text-start text-sm',
                        )}
                        onClick={onOrderDetailsButtonClick}
                      >
                        {getFuncName(tagTitle)}
                      </button>
                    )}
                  </MenuItem>
                  {message?.context?.html ? (
                    <MenuItem>
                      {({ focus }) => (
                        <button
                          type="button"
                          className={classNames(
                            focus
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block w-full px-4 py-2 text-start text-sm',
                          )}
                          onClick={onShowHtmlPreviewButtonClick}
                        >
                          Original email content
                        </button>
                      )}
                    </MenuItem>
                  ) : null}
                </div>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      );
    }
    return null;
  }, [
    message?.context?.html,
    onOrderDetailsButtonClick,
    onShowHtmlPreviewButtonClick,
    tagTitle,
  ]);

  if (!isMessageExpanded) {
    return (
      <div className="group relative flex gap-smd">
        <div style={{ ...styles.wrapper, ...directionStyle }}>
          <div
            style={{ ...styles.message, ...messageStyle }}
            className="mb-sm rounded-lg p-lg"
          >
            <div className="relative cursor-pointer">
              <div className="relative h-[90px] overflow-hidden">
                {message?.message}
                <div className="absolute bottom-0 left-0 z-50 h-[80%] w-full bg-gradient-to-b from-transparent to-white" />
              </div>

              {/* Expand icon */}
              <div className="absolute -bottom-[5px] left-[50%] z-[100] hidden group-hover:block">
                <button
                  type="button"
                  onClick={() => setIsMessageExpanded(true)}
                  className="w-full rounded-full border bg-white p-1"
                >
                  <ChevronDownIcon className="aspect-square w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {ellipsisButton}
        {tag}
      </div>
    );
  }

  return (
    <div className="relative flex gap-smd">
      <div style={{ ...styles.wrapper, ...directionStyle }}>
        <div
          style={{ ...styles.message, ...messageStyle }}
          className="mb-sm rounded-lg p-lg"
        >
          {isTextLoading ? <ThreeDots /> : null}

          {message?.context?.subject ? (
            <div className="mb-4 border-b border-solid pb-4 font-semibold">
              {decodeEntities(message?.context?.subject)}
            </div>
          ) : null}

          {message?.message ? (
            // eslint-disable-next-line react/no-danger
            <div style={styles.text} className="whitespace-pre-wrap">
              {ParseHtml(markedText, {
                // TODO: Optimize and fix typings in parsehtml.replace for marked keywords
                // eslint-disable-next-line react/no-unstable-nested-components
                replace: (domNode) => {
                  // @ts-ignore
                  if (domNode.name === 'mark') {
                    // @ts-ignore
                    const keyword = domNode.children[0].data;
                    // @ts-ignore
                    const originalKeyword = domNode.children[1].children[0].data;
                    // @ts-ignore
                    const props = attributesToProps(domNode.attribs);
                    return (
                      // @ts-ignore
                      <mark
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role="button"
                        // eslint-disable-next-line react/prop-types
                        onMouseOver={() => onMessageMarkMouseOver(originalKeyword)}
                        onClick={() => onMessageClick(
                          // eslint-disable-next-line react/prop-types
                          parseFloat(props.keyword_index as string),
                          originalKeyword,
                        )}
                        onMouseLeave={onMessageMarkMouseLeave}
                        onKeyDown={() => {}}
                        onFocus={() => {}}
                      >
                        {keyword}
                      </mark>
                    );
                  }
                  return domNode;
                },
              })}
            </div>
          ) : null}
        </div>

        {MessageContextUtils.audioAttachments(message.context).length > 0 ? (
          <>
            <div style={styles.title} className="mb-xs ml-xs text-primary">
              Call recording
            </div>
            <div
              style={{
                ...styles.message,
                ...messageStyle,
                maxWidth: 600,
                width: '100%',
              }}
              className="mb-sm flex w-full gap-lg rounded-lg p-lg"
            >
              {isLoadingAudio ? (
                <LoadingIndicator isLoading />
              ) : (
                <>
                  <button type="button" onClick={onPlayButtonClick}>
                    {isAudioPlaying ? (
                      <PauseIcon height={20} width={20} />
                    ) : (
                      <PlayIcon height={20} width={20} />
                    )}
                  </button>
                  <AudioPlayer
                    // @ts-ignore
                    ref={audioPlayerRef}
                    src={recordingSrc}
                    onPlayError={console.error}
                    autoPlayAfterSrcChange={false}
                    customControlsSection={[null]}
                  />
                </>
              )}
            </div>
          </>
        ) : null}

        {messageDateTime && !hideDateTime ? (
          <div className="flex items-center gap-md">
            <div className="ml-xs text-label-caption text-blue-gray-200">{`${messageDateTime}`}</div>
            <Tooltip
              content={`Source: ${formatMessageSource(message.source)}`}
              // eslint-disable-next-line react/style-prop-object
              style="light"
            >
              <MessageSourceIcon source={message.source} />
            </Tooltip>
          </div>
        ) : null}
      </div>

      {message?.context?.html ? (
        <Modal
          show={isHtmlPreviewModalVisible}
          onClose={onHtmlPreviewModalClose}
          size="7xl"
          position="top-center"
          dismissible
        >
          <Modal.Header>
            <div className="flex items-center gap-smd">
              Original email content
            </div>
          </Modal.Header>
          <Modal.Body className="p-smd">
            <div className="relative overflow-hidden rounded-lg">
              {ParseHtml(message?.context?.html)}
            </div>
          </Modal.Body>
        </Modal>
      ) : null}

      {isMoveMessageModalVisible && (
        <ChatMoveMessageModal
          messageId={message?.id}
          isOpen={isMoveMessageModalVisible}
          onClose={onMoveMessageModalClose}
          onMessageMoved={onMessageMoved}
        />
      )}

      {ellipsisButton}
      {tag}
    </div>
  );
};

ChatMessage.defaultProps = {
  message: null,
  theme: 'primary',
  direction: 'RECEIVED',
  isTextLoading: false,
  tagTitle: null,
  isExpandable: false,
  hideDateTime: false,
};

export { ChatMessage };
