import { GroupedFields } from 'features/instruction/types/schema';
import { AudioKeyword, TextKeyword } from 'models/Workflow';
import { Group } from 'features/instruction/constants/prompt';
import { FieldSpec, Schema } from '../models/Schema';
import { getFieldByPath } from './schema';

export const groupFields = (fields: FieldSpec[], schema: Schema): GroupedFields[] => {
  if (!fields || !schema) return [];

  const pathFieldsMap = fields?.reduce(
    (acc: Record<string, FieldSpec[]>, field) => {
      const parentPath = field.lastCardableParentPath;

      if (!acc[parentPath]) {
        acc[parentPath] = [];
      }
      acc[parentPath].push(field);
      return acc;
    },
    {},
  );

  function buildGroupedFields(
    currentPath: string,
    pathFieldsMap_: Record<string, FieldSpec[]>,
  ): GroupedFields {
    const subGroups: GroupedFields[] = [];

    if (currentPath.includes('.')) {
      // Find all children paths
      Object.keys(pathFieldsMap_)
        .forEach((path) => {
          if (path.includes('.') && path !== currentPath && path.startsWith(currentPath)) {
            const subGroup = buildGroupedFields(path, pathFieldsMap_);
            subGroups.push(subGroup);
          }
        });
    }

    return {
      title: currentPath?.includes('.') ? getFieldByPath(schema?.fields, currentPath)?.name || currentPath : 'Standard fields',
      fields: pathFieldsMap_[currentPath] || [],
      subGroups,
    };
  }

  // Initialize the root groups based on the pathFieldsMap
  const groupedFields_ = Object.keys(pathFieldsMap)
    .sort((a, b) => a.localeCompare(b))
    .filter((path) => !Object.keys(pathFieldsMap).some((p) => p.includes('.') && path.startsWith(p) && path !== p))
    .map((rootPath) => buildGroupedFields(rootPath, pathFieldsMap));

  return groupedFields_;
};

export const getGroupKeyByPath = (path: string, groupedFields: GroupedFields[]) => (
  groupedFields.find((group) => group.fields.some((field) => field.path === path))?.title
);

export const pathToGroup = (path: string): Group => {
  if (path?.includes('products')) return Group.Product;

  if (path?.includes('customer')) return Group.Customer;

  return Group.StandardFields;
};

export const getProductFields = (fields: FieldSpec[]) => (
  fields?.filter((field) => pathToGroup(field.path) === Group.Product) || []
);

export const getCustomerFields = (fields: FieldSpec[]) => (
  fields?.filter((field) => pathToGroup(field.path) === Group.Customer) || []
);

export const getStandardFields = (fields: FieldSpec[]) => (
  fields?.filter((field) => pathToGroup(field.path) === Group.StandardFields) || []
);

export const groupKeywordsByPath = (
  (keywords: (AudioKeyword | TextKeyword)[]): Record<Group, (AudioKeyword | TextKeyword)[]> => (
    keywords.reduce((acc, keyword) => {
      const group = pathToGroup(keyword.path);
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(keyword);
      return acc;
    }, {} as Record<Group, (AudioKeyword | TextKeyword)[]>)
  )
);
