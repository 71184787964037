import { useCallback } from 'react';
import { useFetchOrders } from './useFetchOrders';

const useFetchOrdersByGroupId = () => {
  const {
    orders,
    setParams: setOrdersByGroupIdParams,
    isLoading,
    loadOrders,
  } = useFetchOrders({ initialFetch: false, autoLoad: false });

  const loadOrdersByGroupId = useCallback(async (groupId: string) => {
    setOrdersByGroupIdParams({ group_id: groupId });
    return loadOrders();
  }, [loadOrders, setOrdersByGroupIdParams]);

  return {
    orders,
    isLoading,
    loadOrdersByGroupId,
  };
};

export { useFetchOrdersByGroupId };
