import {
  ChevronDownIcon,
  ChevronUpIcon,
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';

import { Chat } from '../../../../../../features/chat/models/Chat';

interface Props {
  chat: Chat;
  isExpanded: boolean;
  onCustomerSectionOpenClicked: (key: string) => void;
}

const ProfileSection = ({
  chat,
  isExpanded,
  onCustomerSectionOpenClicked,
}: Props) => (
  <div className="border-b border-blue-gray-50 py-lg">
    <div className="flex justify-between">
      <p className="text-title-sm font-medium">Profile</p>
      <button
        type="button"
        onClick={() => {
          onCustomerSectionOpenClicked('profile');
        }}
      >
        {typeof isExpanded !== 'undefined' && isExpanded ? (
          <ChevronDownIcon className="aspect-square w-5" />
        ) : (
          <ChevronUpIcon className="aspect-square w-5" />
        )}
      </button>
    </div>

    <div
      className={`pt-sm text-description ${typeof isExpanded !== 'undefined' && isExpanded && 'hidden'}`}
    >
      {chat.business.emails ? (
        (chat.business.emails || []).map((email) => (
          <div key={`email-${email}`} className="flex space-x-3">
            <EnvelopeIcon className="aspect-square w-4" />
            <p className="text-label-sm">{email}</p>
          </div>
        ))
      ) : (
        <div className="flex space-x-3">
          <EnvelopeIcon className="aspect-square w-4" />
          <p className="text-label-sm">{chat.business.email || '-'}</p>
        </div>
      )}
      {chat.business.phones ? (
        chat.business.phones.map((phone) => (
          <div key={phone.number} className="flex space-x-3">
            <PhoneIcon className="aspect-square w-4" />
            <p className="text-label-sm">{phone.number}</p>
          </div>
        ))
      ) : (
        <div className="flex space-x-3">
          <PhoneIcon className="aspect-square w-4" />
          <p className="text-label-sm">-</p>
        </div>
      )}
      <div className="flex space-x-3">
        <MapPinIcon className="aspect-square w-4" />
        <p className="text-label-sm">{chat.business.address || '-'}</p>
      </div>
    </div>
  </div>
);

export { ProfileSection };
