import {
  action, makeObservable, observable, runInAction,
} from 'mobx';

import { httpGetV1 } from 'utils/xhr';
import { Inbox } from 'models/Inbox';

class Inboxes {
  inboxes: Inbox[] = [];

  selectedInboxId?: string | null = null;

  isLoading?: boolean = false;

  constructor() {
    makeObservable(this, {
      inboxes: observable,
      isLoading: observable,
      selectedInboxId: observable,
      loadInboxes: action,
    });

    this.loadInboxes();
  }

  refresh = () => {
    this.selectedInboxId = null;
    this.loadInboxes();
  };

  setSelectedInboxId = (id: string) => {
    if (this.inboxes.find((inbox) => inbox.id === id)) {
      this.selectedInboxId = id;
    }
  };

  updateInbox = (inbox: Inbox) => {
    this.inboxes = this.inboxes.map((inbox_) => {
      if (inbox_.id === inbox.id) {
        return { ...inbox_, ...inbox };
      }
      return inbox_;
    });
  };

  loadInboxes = async () => {
    runInAction(() => {
      this.isLoading = true;
    });

    const dataResponse = await httpGetV1('/inboxes', {
      params: {
        limit: 20,
      },
      classType: Inbox,
    });

    runInAction(() => {
      this.inboxes = dataResponse.data || [];
      this.isLoading = false;

      if (this.selectedInboxId === null && this.inboxes.length > 0) {
        // If no inbox is selected, select the first inbox
        this.selectedInboxId = this.inboxes[0].id;
      }
    });
  };
}

export { Inboxes };
