import { useCallback, useMemo, useState } from 'react';

export const useImgUtils = () => {
  const [imgLoadingStatus, setImgLoadingStatus] = useState<'loading' | 'loaded' | 'error'>('loading');

  const [imgDims, setImgDims] = useState({
    width: 0,
    height: 0,
  });
  const [naturalImgDims, setNaturalImgDims] = useState({
    width: 0,
    height: 0,
  });

  const scaleX = useMemo(() => imgDims.width / naturalImgDims.width,
    [imgDims, naturalImgDims],
  );
  const scaleY = useMemo(() => imgDims.height / naturalImgDims.height,
    [imgDims, naturalImgDims],
  );

  const handleImageLoad = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement>) => {
      const img = event.currentTarget;
      setImgDims({
        width: img.width,
        height: img.height,
      });
      setNaturalImgDims({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
      setImgLoadingStatus('loaded');
    },
    [],
  );

  return {
    imageDimensions: imgDims,
    naturalImageDimensions: naturalImgDims,
    scaleX,
    scaleY,
    handleImageLoad,
    imgLoadingStatus,
    setImgLoadingStatus,
  };
};
