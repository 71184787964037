import { observer } from 'mobx-react-lite';
import { globalNotifications } from 'state/globalNotifications';

import NotificationListItem from './NotificationListItem';

const NotificationList = observer(() => (
  <>
    {globalNotifications.notifications.map((event, index) => (
      <NotificationListItem
        key={event.id}
        event={event}
        isLast={index === globalNotifications.notifications.length - 1}
      />
    ))}
  </>
));

export default NotificationList;
