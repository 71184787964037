import { ContentTheme } from 'components/ui/Select';
import { ProductWithQuantity } from 'features/order/models/Order';
import { getAdditionalInfo, getContentThemeAndTooltip } from 'features/product/utils/product';
import { Product } from 'features/product/models/Product';

export const formatProductSelectedOption = (productWithQuantity: ProductWithQuantity, wasMatchedByID_: boolean) => {
  if (!productWithQuantity.product && productWithQuantity?.autoMatched) {
    return {
      contentTheme: ContentTheme.WARNING,
      label: 'No similar products were found for the extracted product name. Click here to search.',
      value: '',
      tooltip: 'No similar products were found for the extracted product name. Click here to search.',
    };
  }

  if (!productWithQuantity.product && !productWithQuantity?.autoMatched) {
    return {
      label: '',
      value: '',
    };
  }

  let label = productWithQuantity?.product?.name;
  const shortName = productWithQuantity?.product?.shortName;
  if (shortName) {
    label += ` (${shortName})`;
  }

  const { theme: contentTheme, tooltip: customLabelTooltip } = getContentThemeAndTooltip(
    wasMatchedByID_,
    productWithQuantity?.score,
    productWithQuantity?.llmScore,
  );

  const additionalInfo = getAdditionalInfo(productWithQuantity.product);

  return {
    visibleId: productWithQuantity.product?.idOrSku,
    label,
    value: productWithQuantity.product?.id,
    flag: productWithQuantity.product?.disabled ? 'Disabled' : null,
    meta: productWithQuantity.product?.orderCount?.toString() || null,
    contentTheme,
    customLabelTooltip,
    additionalInfo: (
      <div>
        {additionalInfo.map((info) => (
          <p key={info.label}>
            {info.label}
            :
            {' '}
            {info.value}
          </p>
        ))}
      </div>
    ),
  };
};

export const formatProductOption = (product: Product, selectedID: string, wasMatchedByID_: boolean) => {
  let matchedByID = false;
  if (product?.id === selectedID) {
    matchedByID = wasMatchedByID_;
  }

  const {
    theme: contentTheme,
    tooltip: customLabelTooltip,
  } = getContentThemeAndTooltip(matchedByID, product?.score);

  const additionalInfo = getAdditionalInfo(product);

  return {
    visibleId: product.idOrSku,
    label: product.name,
    value: product.id,
    flag: product.disabled ? 'Disabled' : null,
    meta: product.orderCount?.toString() || null,
    metaDescription: `Orders number of this product: ${product.orderCount?.toString() || null}`,
    contentTheme,
    customLabelTooltip,
    descriptions: [{ label: 'order count', value: product.orderCount?.toString() || null }, ...additionalInfo],
  };
};
