import { useCallback, useMemo } from 'react';

import { Tooltip } from 'components/ui';
import { Button } from 'components/ui/Button';
import { useOrderContext } from 'features/order/contexts/useOrderContext';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { isUnitConvertible } from 'features/order/utils/order';
import { getUnits } from 'features/product/utils/product';
import { Order } from 'features/order/models/Order';
import { useLocation } from 'react-router-dom';

interface Props {
  isNewOrderDraft: boolean;

  onConfirmButtonClick: () => void;
  updateOrderDrafts: (
    orderDrafts: Order[],
    deletedOrderIds: string[],
    messageId?: string,
    createAlertData?: boolean,
    giveErrorFeedback?: boolean,
  ) => Promise<Order[]>;
  onDeclineButtonClick: () => void;
}

const getSaveButtonDisabledMsg = (
  didChangeMade: boolean,
  isValidCustomer: boolean,
  isNewOrderDraft: boolean,
  existsAtLeastOneProduct: boolean,
  isValidQuantity: boolean,
  isValidUnit: boolean,
) => {
  if (!didChangeMade) {
    return 'No changes have been made yet';
  }

  if (!isValidCustomer) {
    return 'Select a customer';
  }

  // Check if there is at least one product
  if (!existsAtLeastOneProduct) {
    return 'Add at least one product';
  }

  if (!isValidQuantity) {
    return 'Add a valid quantity to all products';
  }

  if (!isValidUnit) {
    return 'Add a valid unit to all products';
  }

  return '';
};

const getConfirmButtonDisabledMsg = (
  didChangeMade: boolean,
  existsAtLeastOneProduct: boolean,
  filteredErrors: string[],
) => {
  if (didChangeMade) {
    return 'Save changes before confirming.';
  }

  if (!existsAtLeastOneProduct) {
    return 'Add at least one product';
  }

  if (filteredErrors.length > 0) {
    return 'Fix all errors before confirming.';
  }

  return '';
};

const Buttons = ({
  isNewOrderDraft,
  onConfirmButtonClick,
  updateOrderDrafts,
  onDeclineButtonClick,
}: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const messageId = searchParams.get('message_id');

  const {
    groupOrders: groupOrders_, deletedOrderIds, setDeletedOrderIds, selectedIndex,
  } = useOrderContext();
  const {
    order, filteredErrors, onOrderDraftsSaved,
  } = useProcessOrderContext();

  const groupOrders = useMemo(() => groupOrders_.map(
    (o, i) => (i === selectedIndex ? order : o)), [groupOrders_, order, selectedIndex]);

  const didChangeMade = groupOrders.some((o) => o.didChangeMade) || deletedOrderIds.length > 0;
  const existsAtLeastOneProduct = !groupOrders.some(
    (o) => !o?.products
          || o.products.length === 0
          || o.products.some((p) => !p?.product?.name),
  );
  const isValidCustomer = !groupOrders.some((o) => !o?.customer?.customer);
  const isValidQuantity = !groupOrders.some((o) => o?.products?.some((p) => !p?.quantity));
  const isValidUnit = !groupOrders.some((o) => o?.products?.some(
    (p) => !getUnits(p?.product).includes(p?.unit?.symbol) // Check if unit is included in product units
          && !isUnitConvertible(p?.unit?.symbol), // Check if unit is convertible, if so, it's valid
  ));

  const saveButtonDisabledMsg = getSaveButtonDisabledMsg(
    didChangeMade,
    isValidCustomer,
    isNewOrderDraft,
    existsAtLeastOneProduct,
    isValidQuantity,
    isValidUnit,
  );

  const confirmButtonDisabledMsg = getConfirmButtonDisabledMsg(
    didChangeMade,
    existsAtLeastOneProduct,
    filteredErrors,
  );

  const onSaveButtonClick = useCallback(() => {
    // Save action should save all the orders that has some changes
    updateOrderDrafts(groupOrders, deletedOrderIds, messageId, true, true).then(
      (orders) => {
        onOrderDraftsSaved(orders);
        setDeletedOrderIds([]);
      },
    );
  }, [updateOrderDrafts, groupOrders, deletedOrderIds, messageId, onOrderDraftsSaved, setDeletedOrderIds]);

  return (
    <div className="py-3 px-3 flex justify-between items-center">
      <Button
        title="Decline"
        theme="red"
        disabled={!order?.id}
        onClick={onDeclineButtonClick}
      />
      <div className="text-[0.6rem] px-4 h-full flex items-center italic">
        <p className="text-gray-400">
          This is an alpha version of Adam. Please double check the
          output and report bugs to
          {' '}
          <a href="mailto:support@hoshii.ai">
            support@hoshii.ai
          </a>
          .
        </p>
      </div>
      <div className="flex gap-2">
        <Tooltip
          label={saveButtonDisabledMsg}
          disabled={!saveButtonDisabledMsg}
        >
          <Button
            title="Save"
            disabled={!!saveButtonDisabledMsg}
            onClick={onSaveButtonClick}
          />
        </Tooltip>
        <Tooltip
          label={confirmButtonDisabledMsg}
          disabled={!confirmButtonDisabledMsg}
        >
          <Button
            title="Confirm"
            theme="primary"
            disabled={!!confirmButtonDisabledMsg}
            onClick={onConfirmButtonClick}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Buttons;
