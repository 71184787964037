import { observer } from 'mobx-react-lite';

import { LRO_STATUSES } from 'state/classes/LROs';
import { globalLROs } from 'state/globalLROs';
import LroListItem from './LroListItem';

const LroList = observer(() => (
  <>
    {
        globalLROs.lros.filter((lro) => lro.status === LRO_STATUSES.PENDING).map((lro, index) => (
          <LroListItem lro={lro} isLast={index === globalLROs.lros.length - 1} />
        ))
    }
  </>
));

export default LroList;
