import { MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon } from '@heroicons/react/24/outline';

import { IconButtonWithTooltip, Tooltip } from 'components/ui';
import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { DefaultButtonsProps } from './types';

const DefaultButtons = ({
  zoomIn, zoomOut, imageTitle, docUrl,
}: DefaultButtonsProps) => (
  <div
    className="flex space-x-1 rounded-s-full rounded-e-full border px-2 py-1 w-fit"
  >
    <div
      className="flex justify-center items-center rounded-s-full rounded-e-full px-2 hover:px-0 max-w-80 bg-gray-100 hover:bg-gray-200/50 group relative"
    >
      <OverflowEllipsisParagraph maxLines={1} isTooltipEnabled>
        {imageTitle}
      </OverflowEllipsisParagraph>

      <div className="absolute top-0 left-0 w-full rounded-s-full rounded-e-full h-full bg-gray-100 bg-opacity-95 group-hover:flex hidden">
        <Tooltip label={imageTitle} bg="black" styles={{ tooltip: { color: 'white' } }}>
          <button
            type="button"
            onClick={() => {
              window.open(docUrl, '_blank');
            }}
            className="w-full h-full flex gap-2 items-center justify-center"
          >
            <FontAwesomeIcon icon={faDownload} className="w-5 h-5" />
            <p>Download</p>
          </button>

        </Tooltip>
      </div>
    </div>
    <div className="flex flex-1 justify-center items-center">
      <IconButtonWithTooltip
        icon={<MagnifyingGlassPlusIcon className="aspect-square w-5" />}
        label="Zoom In"
        className="rounded-full aspect-square w-8 h-8 text-gray-700"
        onClick={() => zoomIn(0.5)}
      />

      <IconButtonWithTooltip
        icon={<MagnifyingGlassMinusIcon className="aspect-square w-5" />}
        label="Zoom Out"
        className="rounded-full aspect-square w-8 h-8 text-gray-700"
        onClick={() => zoomOut(0.5)}
      />
    </div>
  </div>
);

export default DefaultButtons;
