import { useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { observer } from 'mobx-react-lite';
import { formatEventTime, isZeroTime } from 'utils/dateTime';
import { globalNotifications } from 'state/globalNotifications';
import { Event } from 'models/Event';
import { useDebouncedCallback } from '@mantine/hooks';
import { genericErrorFeedback } from 'utils/errors';
import { decodeEvent } from 'features/notification/utils/notification';
import { Link } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  event: Event;
  isLast: boolean;
}

const NotificationListItem = observer(({ event, isLast }: Props) => {
  const decodedEvent = decodeEvent(event);

  const isEventUnseen = isZeroTime(event.consumedAt);

  const observerRef = useRef<HTMLDivElement | null>(null);

  const debouncedMarkEventsAsRead = useDebouncedCallback((eventIds: string[]) => {
    try {
      globalNotifications.markAsRead(eventIds);
    } catch (error) {
      genericErrorFeedback('Failed to mark notifications as read')(error);
    }
  }, 1000);

  useEffect(() => {
    const interactionObserver = new IntersectionObserver(
      (entries) => {
        // If the last notification is visible, load more notifications
        if (entries[0].isIntersecting && entries[0].target.getAttribute('data-is-last') === 'true') {
          globalNotifications.loadNotifications();
        }

        if (!event) return;

        if (isZeroTime(event.consumedAt)) {
          debouncedMarkEventsAsRead([event.id]);
        }
      },
      { threshold: 0.5 },
    );

    const currentObserverRef = observerRef.current;
    if (currentObserverRef) {
      interactionObserver.observe(currentObserverRef);
    }

    return () => {
      if (currentObserverRef) {
        interactionObserver.unobserve(currentObserverRef);
      }
    };
  }, [isLast, event, debouncedMarkEventsAsRead]);

  return (
    <div
      ref={observerRef}
      data-is-last={isLast}
      className={twMerge(
        'flex cursor-pointer justify-between rounded-sm py-3 hover:bg-gray-50 transition-colors duration-200',
        isZeroTime(event.consumedAt) && 'bg-primary-50',
      )}
    >
      <div className="flex-1 flex gap-4">
        <div className="flex items-start">
          <FontAwesomeIcon
            icon={decodedEvent?.icon}
            className={twMerge(
              'w-5 h-5',
              decodedEvent?.isError ? 'text-red-500' : 'text-gray-500',
            )}
          />
        </div>
        <div className="flex-1 overflow-hidden">
          <p className={twMerge(
            'font-medium text-sm leading-[16px]',
            decodedEvent?.isError && 'text-red-500',
          )}
          >
            {decodedEvent?.title}
          </p>
          <p className="text-xs font-light text-neutral-300/80 leading-5">
            {formatEventTime(new Date(event.createdAt))}
          </p>

          {decodedEvent?.orderId && (
            <Link to={
                (decodedEvent?.orderProcessedAlreadyOnce && !decodedEvent?.isError)
                  ? ROUTES.ORDER_BY_ID(decodedEvent.orderId)
                  : ROUTES.PROCESS_ORDER_DRAFT_BY_ID(decodedEvent.orderId)
              }
            >
              <p
                className={twMerge(
                  'text-sm font-semibold px-2 py-1 rounded-sm w-fit mt-2',
                  isEventUnseen ? 'bg-primary-500 text-white' : 'bg-gray-200 text-gray-500 hover:bg-gray-300',
                )}
              >
                {decodedEvent?.orderProcessedAlreadyOnce ? 'View Order' : 'Process'}
              </p>
            </Link>
          )}

          {decodedEvent?.threadId && (
            <Link to={ROUTES.THREAD_BY_ID(decodedEvent.threadId)}>
              <p className="text-sm font-semibold px-2 py-1 rounded-sm w-fit mt-2 bg-gray-200 text-gray-500 hover:bg-gray-300">
                View
              </p>
            </Link>
          )}

          {/* {decodedEvent?.messageId && (
          <Link to={ROUTES.MESSAGE_BY_ID(decodedEvent.messageId)}>
            <p className={twMerge(
              'text-sm font-semibold px-2 py-1 rounded-sm w-fit mt-2',
              isEventUnseen ? 'bg-primary-500 text-white' : 'bg-gray-200 text-gray-500 hover:bg-gray-300',
            )}
            >
              View
            </p>
          </Link>
          )} */}
        </div>
      </div>

      <div className="flex items-start justify-center pr-2">
        {isEventUnseen && (
          <span className="aspect-square w-2 rounded-full bg-primary-500" />
        )}
      </div>
    </div>
  );
});

export default NotificationListItem;
