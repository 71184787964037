/* eslint-disable react/jsx-closing-tag-location */
import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { HStack } from 'components/ui/HStack';
import { Page } from 'components/layout/Page/Page';

import { OrderDrafts } from 'state/classes/OrderDrafts';
import { MessageIntents } from 'state/classes/MessageIntents';

import { Analytics } from '../components';
import IntentCard from '../components/IntentCard/IntentCard';

interface Props {
  orderDrafts: OrderDrafts;
  messageIntents: MessageIntents;
  navigateToOrderDraftsProcessing: () => void;
}

const HomePage = observer(
  ({
    orderDrafts,
    navigateToOrderDraftsProcessing,
  }: Props) => {
    const isLoading = useMemo(
      () => orderDrafts.isLoading,
      [orderDrafts.isLoading],
    );

    const onProcessOrdersButtonClick = useCallback(() => {
      navigateToOrderDraftsProcessing();
    }, [navigateToOrderDraftsProcessing]);

    return (
      <Page isLoading={isLoading}>
        <div className="relative flex-1 overflow-y-auto px-[10vw] pb-4">
          <div className="flex justify-between px-lg pt-10">
            <div className="flex flex-col justify-center gap-2">
              <h1 className="text-xl font-semibold leading-none text-gray-900">
                Dashboard
              </h1>
              <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
                Your business at a glance
              </div>
            </div>
          </div>
          <HStack>
            <div className="h-full w-full px-lg">
              <div className="grid grid-cols-12 gap-4 pt-5">
                <IntentCard
                  cardTitle="Unprocessed orders"
                  cardSubtitle="Track and manage your orders"
                  intentProps={[
                    {
                      cardTitle: (
                        <p className="font-medium">
                          <span className="text-base font-semibold">
                            {orderDrafts.count}
                          </span>
                          {' '}
                          Order drafts
                        </p>
                      ),
                      cardSubtitles: [
                        `${orderDrafts.count} Emails`,
                        '0 Whatsapp',
                        '0 Phone calls',
                        '0 SMS',
                      ],
                      cardData: [
                        { name: 'Unresolved', value: 200, color: 'gray.2' },
                        { name: 'Resolved', value: 400, color: 'blue.5' },
                      ],
                      buttons: [
                        {
                          title: 'Process',
                          theme: 'primary',
                          onClick: onProcessOrdersButtonClick,
                        },
                      ],
                    },
                  ]}
                  className="col-span-4 4xl:col-span-3"
                />
              </div>

              <Analytics />
            </div>
          </HStack>
        </div>
      </Page>
    );
  },
);

export default HomePage;
