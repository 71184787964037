import {
  useState, useCallback, useRef, useEffect,
} from 'react';

import { httpGetV1 } from 'utils/xhr';
import { Prompt } from '../../features/instruction/models/Prompt';

interface FetchPromptsProps {
  preventInitialFetch?: boolean;
}

const useFetchPrompts = ({
  preventInitialFetch = false,
}: FetchPromptsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(!preventInitialFetch);
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const promptsPaginationCursor = useRef<string | null>(null);
  const currentAbortController = useRef<AbortController | null>(null);

  const endReachedRef = useRef<boolean>(false);

  const resetParams = () => {
    setPrompts([]);
    promptsPaginationCursor.current = null;
    endReachedRef.current = false;
  };

  const loadPrompts = useCallback(() => {
    if (endReachedRef.current) return;

    if (currentAbortController.current) {
      currentAbortController.current.abort();
    }

    const controller = new AbortController();
    currentAbortController.current = controller;

    setIsLoading(true);
    httpGetV1('/llm/pipelines/prompts', {
      params: {
        cursor: promptsPaginationCursor.current,
      },
      signal: controller.signal, // Pass the signal to the fetch request
    })
      .then((response) => {
        const fetchedPrompts = response.data.result || [];

        setPrompts((ps) => [...ps, ...fetchedPrompts]);
        promptsPaginationCursor.current = response.data.cursor;

        if (!response.data.cursor || fetchedPrompts.length === 0) {
          endReachedRef.current = true;
        }
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          console.error('Failed to fetch customers:', error);
        }
      })
      .finally(() => {
        if (!controller.signal.aborted) {
          setIsLoading(false);
        }
        currentAbortController.current = null;
      });
  }, []);

  useEffect(() => {
    if (preventInitialFetch || endReachedRef.current) return () => {};

    resetParams();
    loadPrompts();

    return () => {
      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }
    };
  }, [loadPrompts, preventInitialFetch]);

  return {
    prompts,
    setPrompts,
    isLoading,
    loadPrompts,
  };
};

export { useFetchPrompts };
