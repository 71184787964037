import {
  action, makeObservable, observable, runInAction,
} from 'mobx';

import { debounce } from 'lodash';
import { httpGetV1 } from 'utils/xhr';
import { globalUser } from 'state/globalUser';
import { genericErrorFeedback } from 'utils/errors';
import { GetDocumentsQueryType } from '../../models/GetDocumentsQueryType';
import { globalSseSources } from '../globalSseSources';

class OrderDrafts {
  count: number = 0;

  isLoading: boolean = false;

  isLoaded: boolean = false;

  constructor() {
    makeObservable(this, {
      count: observable,
      isLoading: observable,
      loadOrderDraftsCount: action,
      _onNewOrderSseReceived: action,
    });

    this.loadOrderDraftsCount()
      .catch(genericErrorFeedback('Error loading order drafts'));
  }

  refresh = () => {
    this.loadOrderDraftsCount();
  };

  loadOrderDraftsCount = async () => {
    if (!globalUser?.id) {
      return;
    }

    runInAction(() => {
      this.isLoading = true;
    });
    const response = await httpGetV1('/orders/drafts', {
      params: {
        assignee_id: globalUser.id,
        include_unassigned: true,
        query_type: GetDocumentsQueryType.count,
      },
    });

    runInAction(() => {
      this.count = response.data.count;
      this.isLoading = false;
      this.isLoaded = true;
    });
  };

  _debouncedLoadOrderDraftsCount = debounce(this.loadOrderDraftsCount, 2000);

  // Decrements the count of unprocessed (i.e., neither confirmed nor declined) orders by 1
  markOrderAsProcessed = () => {
    runInAction(() => {
      this.count = Math.max(this.count - 1, 0);
    });
  };

  // eslint-disable-next-line class-methods-use-this
  removeSseSourcesHandler = () => {
    globalSseSources.removeSourcesHandler('orders_drafts');
  };

  _onNewOrderSseReceived = () => {
    this._debouncedLoadOrderDraftsCount();
  };

  addSseSourcesHandler = () => {
    globalSseSources.addSourcesHandler(
      'orders_drafts',
      ['new_order'],
      this._onNewOrderSseReceived,
    );
  };
}

export { OrderDrafts };
