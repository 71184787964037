import { useState, useCallback } from 'react';
import { Inbox } from 'models/Inbox';
import { globalAlertData } from 'state/globalAlertData';
import { httpPatchV1 } from 'utils/xhr';
import { genericErrorFeedback } from 'utils/errors';

export const useUpdateInbox = () => {
  const [isLoading, setIsLoading] = useState(false);

  const updateInbox = useCallback(
    async (
      inbox: Inbox,
      createAlertData: boolean = true,
      giveErrorFeedback: boolean = true,
    ): Promise<Inbox> => {
      if (!inbox) return Promise.reject(new Error('Inbox is required'));

      if (!inbox.name) {
        return Promise.reject(new Error('Name is required'));
      }

      if (!inbox.participantUserIds) {
        return Promise.reject(new Error('Participants are required'));
      }

      if (!inbox.boundEmailChannels) {
        return Promise.reject(new Error(''));
      }

      setIsLoading(true);

      return httpPatchV1(`/inboxes/${inbox.id}`, {
        ...inbox,
      })
        .then((response) => {
          if (createAlertData) {
            globalAlertData.create('Inbox updated successfully');
          }
          return response.data;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Update inbox failed')(error);
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    isLoading,
    updateInbox,
  };
};
